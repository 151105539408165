import React, { useEffect, useState } from "react";
// import Sidebar from "../../components/Sidebar";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function UpdateAdmin() {
  const [SubmitLoader, setSubmitLoader] = useState(false);
  const navigate = useNavigate();
  let token = localStorage.getItem("token");
  let header = { "x-access-token": `${token}` };
  let options = { headers: header };
  const location = useLocation();
  const { item } = location.state;
  const [EditInput, setEditInput] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setEditInput({ ...EditInput, [name]: value });
  };
  const SubmitAdminData = () => {
    setSubmitLoader(true);
    console.log("EditInput", EditInput);
    axios
      .post(`${process.env.REACT_APP_API_URL}admins/edit-admin`, EditInput, options)
      .then((res) => {
        console.log("res====>", res);
        setSubmitLoader(false);
        toast.success(res.data.message);
        navigate(`/admin`);
        // const data = res.data.data;
        //  navigate(`/client`);
      })
      .catch((err) => {
        setSubmitLoader(false);
        navigate(`/admin`);
        console.log(err.response.success);
      });
  };
  useEffect(() => {
    getSubAdminbyId();
  }, []);
  const data = {
    userId: item.uid,
  };
  const getSubAdminbyId = () => {
    axios
      .post(`${process.env.REACT_APP_API_URL}user-profile-byId`, data, options)
      .then((res) => {
        console.log("res====>", res.data.data);
        setEditInput(res.data.data);
      })
      .catch((err) => {
        console.log(err.response.success);
      });
  };

  return (
    <>
      {/* <Sidebar /> */}
      <div className="content-wrapper">
        <section className="content-header">
          <div className="container-fluid">
            <div className="row mb-2">
              <div className="col-sm-6">
                <h1>Modify Admin</h1>
              </div>
              <div className="col-sm-6">
                <ol className="breadcrumb float-sm-right">
                  <li className="breadcrumb-item">
                    <Link to="/dashboard" href="#">
                      Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/admin">Modify Admin</Link>
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/admin">Back</Link>
                  </li>
                </ol>
              </div>
            </div>
          </div>
        </section>

        <section className="content">
          <form>
            <input
              type="hidden"
              name="superagent"
              value="1"
              className="readonly"
            />
            <div className="row">
              <div className="col-md-6">
                <div className="card card-primary">
                  <div className="card-header">
                    <h3 className="card-title">General</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      ></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group">
                      <label htmlFor="code">Code</label>
                      <input
                        type="text"
                        name="code"
                        value={EditInput.uid}
                        readOnly
                        className="readonly form-control"
                      />
                      <input
                        type="hidden"
                        name="user_type"
                        value="Admin"
                        readOnly=""
                        className="readonly"
                      />
                      <input
                        type="hidden"
                        name="type"
                        value="2"
                        readOnly=""
                        className="readonly"
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        className="form-control"
                        placeholder="Name"
                        min="2"
                        required
                        name="name"
                        onChange={handleChange}
                        value={EditInput.name}
                      />
                    </div>

                    <div className="form-group">
                      <label htmlFor="mobile">Contact No</label>
                      <input
                        type="Number"
                        className="form-control mt-3 "
                        name="contact_no"
                        onChange={handleChange}
                        value={EditInput.contact_no}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="card card-secondary">
                  <div className="card-header">
                    <h3 className="card-title">Share and Commission</h3>

                    <div className="card-tools">
                      <button
                        type="button"
                        className="btn btn-tool"
                        data-card-widget="collapse"
                        data-toggle="tooltip"
                        title="Collapse"
                      ></button>
                    </div>
                  </div>
                  <div className="card-body">
                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="share">Admin Share</label>

                        <input
                          type="number"
                          name="share"
                          value={EditInput.share}
                          className="form-control"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="mshare">Admin Share</label>
                        <input
                          type="number"
                          name=""
                          value="100"
                          className="form-control"
                          readOnly
                        />
                      </div>
                    </div>

                    <div className="form-group row">
                      <div className="form-group col-md-6">
                        <label htmlFor="share">Admin Commission</label>
                        <input
                          type="number"
                          value={EditInput.commission}
                          name="commission"
                          className="form-control"
                          onChange={handleChange}
                        />
                      </div>

                      <div className="form-group col-md-6">
                        <label htmlFor="mshare">Admin Commission </label>
                        <input
                          type="number"
                          value="20"
                          disabled
                          name="sess_comm"
                          max="3"
                          className=" focus-red form-control"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <Link to="/admin" className="btn btn-secondary">
                  Cancel
                </Link>
                <button
                  type="submit"
                  className="btn btn-success float-right"
                  id="btn"
                  onClick={SubmitAdminData}
                  disabled={SubmitLoader}
                >
                  Update Admin
                  {SubmitLoader && (
                    <span
                      class="spinner-grow spinner-grow-sm"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  )}
                </button>
              </div>
            </div>
          </form>

          <br />
        </section>
      </div>
    </>
  );
}
