/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import PageHeader from "../Components/PageHeader";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
// fontawesome
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowAltCircleRight,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import useGetDashboard from "../hooks/useGetDashboard";
import UserModal from "../Components/Dashboard/userModal";
import GameModal from "../Components/Dashboard/gameModal";
import CashModal from "../Components/Dashboard/cashModal";
import LedgerModal from "../Components/Dashboard/ledgerModal";
import useGetAccountStatus from "../hooks/useGetAccountStatus";

export default function Dashboard() {
  const navigate = useNavigate();
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");

  const [userCount, setUserCount] = useState(0);
  const [userType, setUserType] = useState("");
  const [inPlayGameCount, setInPlayGameCount] = useState(0);
  const [completeGameCount, setCompleteGameCount] = useState(0);
  const [cashCount, setCashCount] = useState(0);

  const [userData, setUserData] = useState([]);

  const { mutate: getDashboard } = useGetDashboard();
  const { mutate: accountStatus } = useGetAccountStatus();

  useEffect(() => {
    const launchModalBtn = document.createElement("button");
    launchModalBtn.setAttribute("data-bs-toggle", "modal");
    launchModalBtn.setAttribute("data-bs-target", "#staticBackdropPopup");

    document.body.appendChild(launchModalBtn);
    launchModalBtn.click();

    document.body.removeChild(launchModalBtn);

    const payload = {
      acc_type: accountType,
      user_id: uuid,
    };

    getDashboard(payload, {
      onSuccess: (response) => {
        setInPlayGameCount(response.data.game_count_not_complete.count);
        setCompleteGameCount(response.data.game_count_complete.count);
        setCashCount(response.data.lesser_count.count);

        setUserData(response.data.user[0]);

        if (accountType === "admin") {
          setUserCount(response.data.user[0].superAdmin.count);
          setUserType("Super Admin");
        } else if (accountType === "super_admin") {
          setUserCount(response.data.user[0].sub_admin.count);
          setUserType("Sub Admin");
        } else if (accountType === "sub_admin") {
          setUserCount(response.data.user[0].master.count);
          setUserType("master");
        } else if (accountType === "master") {
          setUserCount(response.data.user[0].super_agent.count);
          setUserType("Super Agent");
        } else if (accountType === "super_agent") {
          setUserCount(response.data.user[0].agent.count);
          setUserType("Agent");
        } else if (accountType === "agent") {
          setUserCount(response.data.user[0].user_account.count);
          setUserType("Client");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <div className="container-fluid pt-3">
          <div className="row">
            {accountType !== "power_user" ? (
              <div
                className="col-lg-3 col-6"
                style={{ paddingLeft: "15px", paddingRight: 0 }}
              >
                <div
                  className="small-box bg-primary pt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#userModal"
                >
                  <div className="inner">
                    <h3>{userCount ? userCount : 0}</h3>
                    <p>{userType ? userType : "Master Details"}</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-person-add" />
                  </div>
                  <a
                    // onClick={() => {
                    //   setOpenModal("userModal");
                    // }}
                    style={{ cursor: "pointer" }}
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  </a>
                </div>
              </div>
            ) : null}

            <div
              className="col-lg-3 col-6"
              style={{ paddingLeft: "15px", paddingRight: 0 }}
            >
              <div
                className="small-box bg-green pt-3"
                data-bs-toggle="modal"
                data-bs-target="#gameModal"
              >
                <div className="inner">
                  <h3>{inPlayGameCount ? inPlayGameCount : 0}</h3>
                  <p>Inplay Games</p>
                </div>
                <div className="icon">
                  <i className="ion ion-stats-bars" />
                </div>
                <a
                  // onClick={() => {
                  //   setOpenModal("gameModal");
                  // }}
                  style={{ cursor: "pointer" }}
                  className="small-box-footer"
                >
                  More info <i className="fas fa-arrow-circle-right" />
                  <FontAwesomeIcon icon={faArrowAltCircleRight} />
                </a>
              </div>
            </div>

            {accountType !== "power_user" ? (
              <div
                className="col-lg-3 col-6"
                style={{ paddingLeft: "15px", paddingRight: 0 }}
              >
                <div
                  className="small-box bg-yellow pt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#cashModal"
                >
                  <div className="inner">
                    <h3>{cashCount ? cashCount : 0}</h3>
                    <p>Cash Transactions</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-bag" />
                  </div>
                  <a
                    // onClick={() => {
                    //   setOpenModal("cashModal");
                    // }}
                    style={{ cursor: "pointer" }}
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  </a>
                </div>
              </div>
            ) : null}

            {accountType !== "power_user" ? (
              <div
                className="col-lg-3 col-6"
                style={{ paddingLeft: "15px", paddingRight: "15px" }}
              >
                <div
                  className="small-box bg-red pt-3"
                  data-bs-toggle="modal"
                  data-bs-target="#ledgerModal"
                >
                  <div className="inner">
                    <h3>{cashCount ? cashCount : 0}</h3>
                    <p>Ledger Details</p>
                  </div>
                  <div className="icon">
                    <i className="ion ion-pie-graph" />
                  </div>
                  <a
                    // onClick={() => {
                    //   setOpenModal("ledgerModal");
                    // }}
                    style={{ cursor: "pointer" }}
                    className="small-box-footer"
                  >
                    More info <i className="fas fa-arrow-circle-right" />
                    <FontAwesomeIcon icon={faArrowAltCircleRight} />
                  </a>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <UserModal userData={userData} accountType={accountType} />

        <GameModal
          inPlayGameCount={inPlayGameCount}
          completeGameCount={completeGameCount}
        />

        <CashModal cashCount={cashCount} accountType={accountType} />

        <LedgerModal cashCount={cashCount} accountType={accountType} />
      </div>

      <div
        className="modal fade"
        id="staticBackdropPopup"
        // data-bs-backdrop="static"
        data-bs-keyboard="false"
        tabIndex="-1"
        aria-labelledby="staticBackdropPopupLabel"
        aria-hidden="true"
        style={{ paddingTop: "24px" }}
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div
              className="modal-header text-white"
              style={{ backgroundColor: "#6610f2" }}
            >
              <h1 className="modal-title" id="staticBackdropLabel">
                Notification
              </h1>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
                style={{ background: "none", opacity: 1 }}
              >
                <FontAwesomeIcon
                  icon={faXmark}
                  color="white"
                  size="xl"
                  style={{ marginTop: "-4px" }}
                />
              </button>
            </div>
            <div
              className="modal-body"
              style={{ fontSize: "16px", lineHeight: 1.5, fontWeight: 500 }}
            >
              1. LIMIT UPDATE KARNE PER ENTRY BHI AUTOMATIC UPDATE HO JAYEGI,
              KRIPYA VO FUNCTION SAHI SE SAMAJH LE
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
