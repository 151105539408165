import { useMutation } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";
import { encryptData } from "../../../utils/encryptData";

const BASE_URL = process.env.REACT_APP_API_URL;

async function mutationFn(payload) {
  const axios = createProtectedAxios();
  const endpoint = `${BASE_URL}/game/revert_cassino_bet_trash`;

  let encryptedPayload = encryptData(payload);
  let payloadData = { data: encryptedPayload };

  return axios.post(endpoint, payloadData).then(({ data }) => data);
}

export default function useRevertCassinoBet() {
  const { mutate, isLoading, data } = useMutation(
    ["revert-cassino-bet-trash"],
    (payload) => mutationFn(payload),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
