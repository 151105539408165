import React from "react";

const UpdateDecisionModal = ({
  setDeclare,
  handleUpdateResult,
  saveLoader,
  setSaveLoader,
}) => {
  return (
    <div
      className="modal fade"
      id="updateDecisionModal"
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="updateDecisionModalLabel"
      aria-hidden="true"
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-header">
            <h1 className="modal-title fs-5" id="updateDecisionModalLabel">
              Update Result
            </h1>
            <button
              type="button"
              className="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="form-group">
                <label className="form-label" htmlFor="declare">
                  Result
                </label>
                <input
                  type="number"
                  className="form-control"
                  min={0}
                  id="declare"
                  placeholder="Enter the result"
                  name="declare"
                  onChange={(e) => {
                    e.preventDefault();
                    setDeclare(e.target.value);
                  }}
                />
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
            <button
              type="button"
              className="btn btn-primary"
              data-bs-dismiss="modal"
              onClick={(e) => {
                e.preventDefault();
                if (!saveLoader) {
                  setSaveLoader(true);
                  handleUpdateResult();
                }
              }}
              disabled={saveLoader}
            >
              Save
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpdateDecisionModal;
