/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import "./adminsidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAddressCard,
  faPlay,
  faChevronRight,
  faDatabase,
  faSignInAlt,
  faClipboardList,
  faThList,
  faIndianRupeeSign,
  faChartLine,
  faSignal,
  faChartColumn,
  faWallet,
  faFutbol,
  faTableTennisPaddleBall,
} from "@fortawesome/free-solid-svg-icons";
import { faCircleStop } from "@fortawesome/free-regular-svg-icons";

export default function Subadmin({ toggleSidebar }) {
  const [isOpen, setIsOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const toggleCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };
  return (
    <>
      <li className="nav-header" style={{ textTransform: "uppercase" }}>
        Master Details
      </li>
      <li className="nav-item">
        <Link to="collection-list" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faIndianRupeeSign}
          />
          <p style={{ color: "#343a40" }}>Collection Master</p>
        </Link>
      </li>

      <li className="nav-item">
        <Link to="masters" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faAddressCard}
          />
          <p style={{ color: "#343a40" }}>Master</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="superagents" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faAddressCard}
          />
          <p style={{ color: "#343a40" }}>Super Agent</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="agent" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faAddressCard}
          />
          <p style={{ color: "#343a40" }}>Agent</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="client" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faAddressCard}
          />
          <p style={{ color: "#343a40" }}>Client</p>
        </Link>
      </li>
      <li className="nav-header" style={{ color: "#343a40" }}>
        Games
      </li>
      <li className="nav-item">
        <Link to="inplay" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faPlay}
          />
          <p style={{ color: "#343a40" }}>In Play</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="complete-games" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faCircleStop}
          />
          <p style={{ color: "#343a40" }}>Complete Games</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="complete-games" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faFutbol}
          />
          <p style={{ color: "#343a40" }}>Football</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="complete-games" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faTableTennisPaddleBall}
          />
          <p style={{ color: "#343a40" }}>Tennis</p>
        </Link>
      </li>
      <li className="nav-header" style={{ color: "#343a40" }}>
        Casino
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          onClick={toggleMenu}
          role="button"
          aria-expanded={isMenuOpen ? "true" : "false"}
          aria-controls="collapseExample"
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChartLine}
          />
          <p style={{ color: "#343a40" }}>Live Casino Position</p>
        </a>

        <ul
          className={`nav flex-column collapse ps-4 ${
            isMenuOpen ? "show" : ""
          }`}
          id="collapseExample"
        >
          <li className="nav-item">
            <Link
              to="virtual-teen-patti"
              className="nav-link text-sm"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faSignal}
              />
              <p style={{ color: "#343a40" }}>VirtualTeenPosition</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="/live-teen-patti"
              className="nav-link text-sm"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faChartColumn}
              />
              <p style={{ color: "#343a40" }}>LiveTeen20Position</p>
            </Link>
          </li>
        </ul>
      </li>

      <li className="nav-item">
        <Link
          to="/casino-details"
          className="nav-link text-sm"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faWallet}
          />
          <p style={{ color: "#343a40" }}>Casino Details</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="/casino-bet-report"
          className="nav-link text-sm"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChartLine}
          />
          <p style={{ color: "#343a40" }}>Int. Casino Details</p>
        </Link>
      </li>

      {/* <li className="nav-item">
        <a className="nav-link">
          <i className="nav-icon fas fa-tachometer-alt" />
          <p>
            Casino
            <i className="right fas fa-angle-left" />
          </p>
        </aclassName=>
        <ul className="nav nav-treeview">
          <li className="nav-item">
            <Link to="teenpatti_t20" className="nav-link" onClick={toggleSidebar}>
              <i className="far fa-circle nav-icon" />
              <p>TEENPATTI T20</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="dragon_tiger" className="nav-link" onClick={toggleSidebar}>
              <i className="far fa-circle nav-icon" />
              <p>Dragon Tiger</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link to="lucky7_a" className="nav-link" onClick={toggleSidebar}>
              <i className="far fa-circle nav-icon" />
              <p>Lucky7 A</p>
            </Link>
          </li>
        </ul>
      </li> */}

      <li className="nav-header" style={{ color: "#343a40" }}>
        Client Comission
      </li>
      <li className="nav-item">
        <Link
          to="client-total-comission"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>Client Total Comission (C)</p>
        </Link>
      </li>

      <li className="nav-header" style={{ color: "#343a40" }}>
        Cash Transaction
      </li>
      <li className="nav-item">
        <Link to="transactions" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>Debit/Credit Entry (C)</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="agent-transactions"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>Debit/Credit Entry (A)</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="superagent-transactions"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>Debit/Credit Entry (SA)</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="master-transactions"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>Debit/Credit Entry (M)</p>
        </Link>
      </li>

      <li className="nav-header" style={{ color: "#343a40" }}>
        Ledger
      </li>

      <li className="nav-item">
        <Link to="my-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>My Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link
          to="client-plus-minus"
          className="nav-link"
          onClick={toggleSidebar}
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>Client Plus/Minus</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="client-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>All Client Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="agent-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>All Agent Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="super-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon className="nav-icon" icon={faChevronRight} />
          <p style={{ color: "#343a40" }}>All Super Agent Ledger</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="master-ledger" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faChevronRight}
          />
          <p style={{ color: "#343a40" }}>All Master Ledger</p>
        </Link>
      </li>

      <li className="nav-header" style={{ color: "#343a40" }}>
        Reports
      </li>

      <li className="nav-item">
        <a
          className="nav-link"
          onClick={handleToggle}
          role="button"
          aria-expanded={isOpen}
          aria-controls="collapseExample2"
        >
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faDatabase}
          />
          <p style={{ color: "#343a40" }}>Data Report</p>
        </a>

        <ul
          className={`nav flex-column collapse ps-4 ${isOpen ? "show" : ""}`}
          id="collapseExample2"
        >
          <li className="nav-item">
            <Link
              to="master-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faThList}
              />
              <p style={{ color: "#343a40" }}>Master Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="super-agent-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faThList}
              />
              <p style={{ color: "#343a40" }}>Super Agent Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="agent-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faThList}
              />
              <p style={{ color: "#343a40" }}>Agent Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="client-data-report"
              className="nav-link text-sm"
              onClick={toggleSidebar}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faThList}
              />
              <p style={{ color: "#343a40" }}>Client Report</p>
            </Link>
          </li>
        </ul>
      </li>

      <li className="nav-item">
        <a className="nav-link" onClick={toggleCollapse} role="button">
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faSignInAlt}
          />
          <p style={{ color: "#343a40" }}>Login Report</p>
        </a>

        <ul
          className={`nav flex-column collapse ps-4 ${
            isCollapsed ? "" : "show"
          }`}
          id="collapseExample"
        >
          <li className="nav-item">
            <Link
              to="master-login-report"
              className="nav-link text-sm"
              onClick={() => {
                toggleSidebar();
                toggleCollapse();
              }}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faClipboardList}
              />
              <p style={{ color: "#343a40" }}>Master Login Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="super-agent-login-report"
              className="nav-link text-sm"
              onClick={() => {
                toggleSidebar();
                toggleCollapse();
              }}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faClipboardList}
              />
              <p style={{ color: "#343a40" }}>Super Agent Login Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="agent-login-report"
              className="nav-link text-sm"
              onClick={() => {
                toggleSidebar();
                toggleCollapse();
              }}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faClipboardList}
              />
              <p style={{ color: "#343a40" }}>Agent Login Report</p>
            </Link>
          </li>
          <li className="nav-item">
            <Link
              to="client-login-report"
              className="nav-link text-sm"
              onClick={() => {
                toggleSidebar();
                toggleCollapse();
              }}
            >
              <FontAwesomeIcon
                className="nav-icon"
                style={{ color: "#343a40" }}
                icon={faClipboardList}
              />
              <p style={{ color: "#343a40" }}>Client Login Report</p>
            </Link>
          </li>
        </ul>
      </li>
    </>
  );
}
