import axios from 'axios';

function getToken() {
  const token = localStorage.getItem('token');

  if (!token) {
    throw new Error('Token not found');
  }

  return token;
}

function onRequest(config) {
  const token = getToken();
  if (token) {
    config.headers["Authorization"] = `Bearer ${token}`;
    config.headers["Content-Type"] = "application/json";
    config.headers["Accept"] = "application/json";
  }
  return config;
}

export function createProtectedAxios() {
  const protectedAxios = axios.create();
  protectedAxios.interceptors.request.use(onRequest);

  return protectedAxios;
}