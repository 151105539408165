import { useQuery } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";

const BASE_URL = process.env.REACT_APP_API_URL;

async function fetcher() {
  const axios = createProtectedAxios();

  const endpoint = `${BASE_URL}/game/cassino_ledger_details`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetCassinoLedgerDetails() {
  const { data, isLoading } = useQuery(
    ["get-cassino-ledger-details"],
    () => fetcher(),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { data, isLoading };
}
