import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export default function CasinoAddButton() {
  const navigate = useNavigate();

  const [matchName, setMatchName] = useState("");

  const [matchDate, setMatchDate] = useState("");
  const [matchTime, setMatchTime] = useState("");
  const [matchAPI, setMatchAPI] = useState("");

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Add Game</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/casino-in-play">Casino In-Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Add New Game</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <form onSubmit={""}>
          <div className="row mb-4">
            {/* Share and Commission Form */}
            <div className="col-md-6 w-100">
              <div className="accordion" id="accordionExample2">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      id="create-button-1"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      General
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="form-group mb-2 col-md-12">
                          <label className="form-label">Match Name</label>
                          <input
                            type="text"
                            id="matchName"
                            name="matchName"
                            className="form-control"
                            placeholder="Enter Match Name"
                            required
                            onChange={(e) => {
                              setMatchName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-6">
                          <div className="form-group">
                            <label className="form-label">
                              Match Start Date
                            </label>
                            <input
                              type="date"
                              id="matchDate"
                              name="matchDate"
                              className="form-control"
                              required
                              onChange={(e) => {
                                setMatchDate(e.target.value);
                              }}
                            />
                          </div>
                        </div>
                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Match Start Time</label>
                          <input
                            type="time"
                            id="matchTime"
                            name="matchTime"
                            className="form-control"
                            required
                            onChange={(e) => {
                              setMatchTime(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-md-6">
                          <label className="form-label">Match API</label>
                          <select
                            className="form-select"
                            id="matchAPI"
                            name="matchAPI"
                            required
                            onChange={(e) => {
                              setMatchAPI(e.target.value);
                            }}
                          >
                            <option value="">Select API</option>
                            <option value="1">API 1</option>
                            <option value="2">API 2</option>
                            <option value="0">DEMO GAME</option>
                          </select>
                        </div>

                        <button type="submit" className="btn btn-primary">
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
