/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import "./CreateNewClientMaster.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useCreateClientAccount from "../../hooks/useCreateClientAccount";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useGetWalletBalance from "../../hooks/useGetWalletBalance";

export default function CreateNewClientMaster() {
  const navigate = useNavigate();
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const userPassword = localStorage.getItem("password");

  const location = useLocation();
  let parentData = [];
  if (location.state) {
    parentData = location.state;
  } else {
    parentData = JSON.parse(localStorage.getItem("DATA"));
  }
  // const { mutate: createAccount } = useCreateAccount();
  const { mutate: createClientAccount } = useCreateClientAccount();
  const { mutate: accountStatus } = useGetAccountStatus();
  const { mutate: updateLimit, isLoading: isUpdatingLimit } = useUpdateLimit();
  const { mutate: getWalletBalance } = useGetWalletBalance();
  const [clientName, setClientName] = useState("");
  const [clientNumber, setClientNumber] = useState("");
  const [clientPassword, setClientPassword] = useState("");
  const [clientReference, setClientReference] = useState("");
  const [clientLimit, setClientLimit] = useState(0);
  const [clientMobileShare, setClientMobileShare] = useState(0);
  const [clientCommission, setClientCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [clientMatchCommission, setClientMatchCommission] = useState(0);
  const [clientSessionCommission, setClientSessionCommission] = useState(0);
  const [clientCassinoCommission, setClientCassinoCommission] = useState(0);
  const [parentLimit, setParentLimit] = useState(0);
  const [clientCassinoStatus, setClientCassinoStatus] = useState(false);

  const [isUserCreating, setIsUserCreating] = useState(false);

  const getParentLimit = () => {
    const payload = {
      user_id: uuid,
      acc_type: "agent",
    };

    getWalletBalance(payload, {
      onSuccess: (response) => {
        setParentLimit(response.data.data.limit);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const generateNewPassword = () => {
    setClientPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    setIsUserCreating(true);

    const payload = {
      acc_type: "user",
      name: clientName,
      reference: clientReference,
      password: String(clientPassword),
      parent: parentData.id,
      parentCode: parentData.code,
      mobile: clientNumber,
      limit: clientLimit,
      mobile_share: clientMobileShare,
      commission: clientCommission,
      match_commission: clientMatchCommission,
      session_commission: clientSessionCommission,
      cassino_commission: clientCassinoCommission,
      cassino_status: clientCassinoStatus,
    };

    toast.promise(
      new Promise((resolve, reject) => {
        createClientAccount(payload, {
          onSuccess: (data) => {
            console.log(data);
            const clientLimitPayload = {
              user_id: data.data.data.id,
              acc_type: "user",
              limit: clientLimit,
              coins: clientLimit,
              types: "credit",
              description: "Client Limit Update",
            };
            const parentLimitPayload = {
              user_id: parentData.id,
              acc_type: "agent",
              limit:
                accountType === "agent"
                  ? parentLimit - clientLimit
                  : parentData.limit - clientLimit,
              coins: clientLimit,
              types: "debit",
              description: "Agent Limit Update",
            };
            updateLimit(parentLimitPayload, {
              onSuccess: () => {
                updateLimit(clientLimitPayload, {
                  onSuccess: () => {
                    resolve(); // This will trigger the success toast
                    setIsUserCreating(false);
                    navigate("/client");
                  },
                  onError: (error) => {
                    console.error(error);
                    reject(error); // This will trigger the error toast
                    setIsUserCreating(false);
                    navigate("/client");
                  },
                });
              },
              onError: (error) => {
                console.error(error);
                reject(error); // This will trigger the error toast
                setIsUserCreating(false);
                navigate("/client");
              },
            });
          },
          onError: (error) => {
            console.error(error);
            reject(error); // This will trigger the error toast
            setIsUserCreating(false);
            navigate("/client");
          },
        });
      }),
      {
        loading: "Creating client...",
        success: <b>Client created successfully!</b>,
        error: <b>Something went wrong! Please try again later.</b>,
      }
    );
  }

  const [isOpen, setIsOpen] = useState(true);
  const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleFirstAccordion = () => {
    setIsFirstAccordionOpen(!isFirstAccordionOpen);
  };

  useEffect(() => {
    setClientPassword(passwordGenerator());
  }, []);

  useEffect(() => {
    if (accountType === "agent") {
      getParentLimit();
    }

    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Create New Client </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/client">Client</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Create Client</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          isOpen ? "" : "collapsed"
                        }`}
                        id="create-button-1"
                        type="button"
                        onClick={toggleAccordion}
                        aria-expanded={isOpen}
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className={`accordion-collapse collapse ${
                        isOpen ? "show" : ""
                      }`}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="clientCode">
                            Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="clientCode"
                            name="clientCode"
                            value="Auto generated"
                            disabled
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="clientName"
                            name="clientName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              setClientName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label" htmlFor="clientNumber">
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="clientNumber"
                            name="clientNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={0}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setClientNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="clientReference"
                            name="clientReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            onChange={(e) => {
                              setClientReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="clientPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="clientPassword"
                              name="clientPassword"
                              className="form-control"
                              required
                              value={clientPassword}
                              onChange={(e) => {
                                setClientPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample2">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          isFirstAccordionOpen ? "" : "collapsed"
                        }`}
                        id="create-button-2"
                        type="button"
                        onClick={toggleFirstAccordion}
                        aria-expanded={isFirstAccordionOpen}
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={`accordion-collapse collapse ${
                        isFirstAccordionOpen ? "show" : ""
                      }`}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">Client Limit</label>
                            <input
                              type="number"
                              id="clientLimit"
                              name="clientLimit"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={
                                accountType === "agent"
                                  ? parentLimit
                                  : parentData.limit
                              }
                              required
                              onChange={(e) => {
                                setClientLimit(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={
                                accountType === "agent"
                                  ? parentLimit
                                  : parentData.limit
                              }
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.share}
                              disabled
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select
                              className="form-select"
                              id="clientCassinoStatus"
                              name="clientCassinoStatus"
                              required
                              value={clientCassinoStatus}
                              onChange={(e) => {
                                if (e.target.value === "true") {
                                  setClientCassinoStatus(true);
                                } else {
                                  setClientCassinoStatus(false);
                                }
                              }}
                            >
                              <option value={false}>OFF</option>
                              <option
                                value={true}
                                disabled={parentData?.cassino_status === 0}
                              >
                                ON
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="clientCommission"
                              name="clientCommission"
                              required
                              onChange={(e) => {
                                const value = e.target.value;
                                setClientCommission(value);

                                // Automatically set match, session, and cassino commissions to 0 if 'No Com' is selected
                                if (value === "commission_6e444f0e316c") {
                                  setClientMatchCommission(0);
                                  setClientSessionCommission(0);
                                  setClientCassinoCommission(0);
                                }
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Match Commission
                            </label>
                            <input
                              type="number"
                              id="clientMatchCommission"
                              name="clientMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.match_commission}
                              value={clientMatchCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setClientMatchCommission(e.target.value)
                              }
                              disabled={
                                clientCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Session Commission
                            </label>
                            <input
                              type="number"
                              id="clientSessionCommission"
                              name="clientSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.session_commission}
                              value={clientSessionCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setClientSessionCommission(e.target.value)
                              }
                              disabled={
                                clientCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Client Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="clientCassinoCommission"
                              name="clientCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.cassino_commission}
                              value={clientCassinoCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setClientCassinoCommission(e.target.value)
                              }
                              disabled={
                                clientCommission === "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/client");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isUserCreating}
                >
                  Create New Client
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
