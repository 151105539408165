import React, { Component } from "react";
import PageHeader from "../Components/PageHeader";
import Card from "../Components/Card";

export default class SessionBetDetails extends Component {
  render() {
    return (
      <>
        <div className="content-wrapper">
          <PageHeader title="Session Bet Details" />
          <section className="content">
            <div className="container-fluid">
              <div className="row">
                <Card>
                <div className="col-12 d-flex">
                      <div className="form-group col-lg-3">
                        <label>Client</label>
                        <select
                          class="form-select"
                          aria-label="Default select example"
                        >
                          <option selected>Select</option>
                        </select>
                      </div>
                      <div className="form-group col-lg-3">
                        <div className="form-group">
                          <label>Date From</label>
                          <input
                            type="date"
                            name="from"
                            className="form-control"
                          />
                        </div>
                      </div>
                      <div class="form-group col-lg-3">
                        <label for="">To Time</label>
                        <input
                          type="time"
                          class="form-control"
                          formControlName="from_date"
                        />
                      </div>
                      <div class="form-group col-lg-3">
                        <label for="">From Time</label>
                        <input
                          type="time"
                          class="form-control"
                          formControlName="to_date"
                        />
                      </div>

                      {/* /.card */}
                    </div>
                    <div class="form-group col-lg-3">
                      <button type="button" class="btn btn-danger ">
                        Delete Bets
                      </button>
                    </div>
                  </Card>

                  <div className="card-body">
                    <Card>
                      <div className="table-responsive">
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>Client</th>
                                  <th>
                                  <select class="form-select" aria-label="Default select example">
                      <option selected>Select</option>
                      
                    </select>
                                  </th>
                                  <th>0</th>
                                  <th>Declare @ null</th>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Session</td>
                                  <td>
                                  <select class="form-select" aria-label="Default select example">
                      <option selected>Select</option>
                      
                    </select>
                                  </td>
                                  <td>
                                  <button type="button" class="btn btn-primary">View</button>
                                  </td>
                                  <td>0</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>

                  
                  
                  <div className="card-body">
                    <Card>
                      <div className="table-responsive">
                        <div className="row">
                          <div className="col-md-12">
                            <table className="table table-striped table-bordered">
                              <thead>
                                <tr>
                                  <th>Session</th>
                                  <th>Code</th>
                                  <th>User</th>
                                  <th>Rate</th>
                                  <th>Run</th>
                                  <th>Mode</th>
                                  <th>Amount</th>
                                  <th>Dec</th>
                                  <th>Created By</th>
                                  <th>Action</th>
                                  
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>629</td>
                                  <td>(C3921) TANNU</td>
                                  <td>40 BALLS RUN MO</td>
                                  <td>1.00 </td>
                                  <td>2000 </td>
                                  <td>73</td>
                                  <td>YES</td>
                                  <td>2000</td>
                                  <td>2000</td>
                                  <td></td>
                             
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </Card>
                  </div>
                  {/* /.card-body */}
                
                {/* /.card */}
              </div>
              {/* /.col */}
            </div>
            {/* /.row */}
          </section>
        </div>
      </>
    );
  }
}
