import { BrowserRouter } from "react-router-dom";
import "./App.css";
// import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AppRoutes from "./Routes/AppRoutes";
import toast, { Toaster } from "react-hot-toast";

function App() {
  return (
    <>
      {/* <ToastContainer /> */}
      <Toaster position="top-right" reverseOrder={false} />
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
