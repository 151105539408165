import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min";

const GameModal = ({ inPlayGameCount, completeGameCount }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    const modalElement = document.getElementById("gameModal");
    const modal = Modal.getInstance(modalElement);
    if (modal) {
      modal.hide();
    }
    navigate(path);
  };

  return (
    <div
      className="modal fade"
      id="gameModal"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="gameModalLabel"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            {inPlayGameCount && completeGameCount ? (
              <div className="row">
                {inPlayGameCount > 0 ? (
                  <div className="col-6">
                    <div
                      className="small-box bg-green"
                      onClick={() => handleNavigation("/inplay")}
                      data-bs-dismiss="modal"
                    >
                      <div className="inner">
                        <h3>{inPlayGameCount ? inPlayGameCount : 0}</h3>
                        <p>Inplay Games</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-stats-bars" />
                      </div>
                      <Link className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ) : null}

                {completeGameCount > 0 ? (
                  <div className="col-6">
                    <div className="small-box bg-yellow">
                      <div className="inner">
                        <h3>{completeGameCount ? completeGameCount : 0}</h3>
                        <p>Completed Games</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-stats-bars" />
                      </div>
                      <Link
                        onClick={() => handleNavigation("/complete-games")}
                        className="small-box-footer"
                        data-bs-dismiss="modal"
                      >
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameModal;
