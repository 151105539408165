import React, { Component } from "react";
import PageHeader from "../Components/PageHeader";
import { useLocation } from "react-router";
import { useState, useEffect } from "react";
import axios from "axios";
import Moment from "react-moment";
import Card from "../Components/Card";

export default function MatchBetDetails() {
  const location = useLocation();
  const { game_id, match_datetime, first_team_name, second_team_name } =
    location.state;
  console.log(location.state);

  const [betdetailData, setBetDetail] = useState([]);
  let token = localStorage.getItem("token");
  let header = { "x-access-token": `${token}` };
  let options = { headers: header };

  async function getListBetList() {
    await axios
      .get(`${process.env.REACT_APP_API_URL}all-bet-details`, options)
      .then(async (resp) => {
        if (resp.data) {
          let respData = resp.data.data;
          let filterData = respData.filter((e) => e.matchId == game_id);
          console.log(respData);
          setBetDetail(filterData);
        }
      })
      .catch((err) => {});
  }

  useEffect(() => {
    getListBetList();
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <PageHeader title="Match Bet Details" />
        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <Card>
                <div className="col-12 d-flex">
                  <div className="form-group col-lg-3">
                    <label>Client</label>
                    <select
                      class="form-select"
                      aria-label="Default select example"
                    >
                      <option selected>Select</option>
                    </select>
                  </div>
                  <div className="form-group col-lg-3">
                    <div className="form-group">
                      <label>Date From</label>
                      <input type="date" name="from" className="form-control" />
                    </div>
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="">To Time</label>
                    <input
                      type="time"
                      class="form-control"
                      formControlName="from_date"
                    />
                  </div>
                  <div class="form-group col-lg-3">
                    <label for="">From Time</label>
                    <input
                      type="time"
                      class="form-control"
                      formControlName="to_date"
                    />
                  </div>

                  {/* /.card */}
                </div>
                <div class="form-group col-lg-3">
                  <button type="button" class="btn btn-danger ">
                    Delete Bets
                  </button>
                </div>
              </Card>
              {/* /.col */}
            </div>
            {/* /.row */}
          </div>

          <Card>
            {/* /.card-header */}
            <div className="table-responsive">
              <div className="card-body">
                <div>
                  <div className="row">
                    <div className="col-md-12">
                      <table className="table table-striped table-bordered w-50">
                        <thead>
                          <tr>
                            <th>match1</th>
                            <th>match2</th>
                          </tr>
                        </thead>
                        <tbody id="match_bets">
                          {/* <tr className>
                                  <td>1</td>
                                  <td>(C4170) Irfan</td>
                                  <td>0.30</td>
                                  <td>MANCHESTER ORIGINALS</td>
                                  <td>LAGAI</td>
                                  <td>100</td>
                                  <td>16-08-2022 23:22:11</td>
                                  <td>30</td>
                                  <td>-100</td>
                                  <td>
                                    2409:4070:4e0a:474b:c509:11cb:ebc1:143d
                                  </td>
                                </tr> */}

                          <tr>
                            <td>00</td>
                            <td>00</td>
                          </tr>
                          <tr>
                            <td>Client</td>
                            <td>
                              <select
                                class="form-select"
                                aria-label="Default select example"
                              >
                                <option selected>Select</option>
                              </select>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <table className="table table-striped table-bordered">
                        <thead>
                          <tr>
                            <th>Client</th>
                            <th>Code</th>
                            <th>Rate</th>
                            <th>Team</th>
                            <th>Mode</th>
                            <th>Amount</th>
                            <th>Date &amp; Time</th>
                            <th id>{location.state.first_team_name}</th>
                            <th id>{location.state.second_team_name}</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody id="match_bets">
                          {/* <tr className>
                                  <td>1</td>
                                  <td>(C4170) Irfan</td>
                                  <td>0.30</td>
                                  <td>MANCHESTER ORIGINALS</td>
                                  <td>LAGAI</td>
                                  <td>100</td>
                                  <td>16-08-2022 23:22:11</td>
                                  <td>30</td>
                                  <td>-100</td>
                                  <td>
                                    2409:4070:4e0a:474b:c509:11cb:ebc1:143d
                                  </td>
                                </tr> */}
                          {betdetailData.map((item, index) => {
                            return (
                              <>
                                <tr>
                                  <td>{index + 1}</td>
                                  <td></td>
                                  <td>{item.odds}</td>
                                  <td>{item.teamName}</td>
                                  <td>
                                    {item.isKhai == true ? "Khai" : "Lagai"}{" "}
                                  </td>
                                  <td>{item.stack}</td>
                                  <td>
                                    <Moment
                                      format="DD/MM/YYYY - hh:mm:ss"
                                      add={{ hours: 12 }}
                                    >
                                      {item.createdAt}
                                    </Moment>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              </>
                            );
                          })}
                        </tbody>
                        <tfoot>
                          {/* <tr>
                                  <th className="text-center" colSpan={6} />
                                  <th className="text-center">Total Amount</th>
                                  <th className={1}>
                                    <span style={{ color: "#007bff" }}>
                                      21032
                                    </span>
                                  </th>
                                  <th className={2}>
                                    <span style={{ color: "red" }}>-2400</span>
                                  </th>
                                </tr> */}
                        </tfoot>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* /.card-body */}
          </Card>
          {/* /.container-fluid */}
        </section>
      </div>
    </>
  );
}
