import { useQuery } from "react-query";
import { createProtectedAxios } from "../lib/auth";

const BASE_URL = process.env.REACT_APP_API_URL;

async function fetcher() {
  const axios = createProtectedAxios();

  const acc_type = "admin";

  const endpoint = `${BASE_URL}/users/${acc_type}`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetAdminAccount() {
  const { data, isLoading } = useQuery(["get-admin-account"], () => fetcher(), {
    onError: (error) => {
      console.error(error);
    },
  });

  return { data, isLoading };
}
