import React from "react";

export default function MasterCardCassino({ data }) {
  return data.map((superAdmin, index) =>
    superAdmin.sub_admins.map((sub_admin, index) =>
      sub_admin.masters.map((master, index) => (
        <div className="card" key={index}>
          <div
            className="card-header bg-warning py-2 text-white"
            style={{ height: "44px" }}
          >
            MASTER NAME : {master.master}
          </div>

          <div className="card-body pt-4 pb-0">
            {master.super_agents.map((super_agent, index) => (
              <div className="card" key={index}>
                <div
                  className="card-header bg-purple py-2 text-white"
                  style={{ height: "44px" }}
                >
                  SUPERAGENT NAME : {super_agent.super_agent}
                </div>
                <div className="card-body bg-gray-light pt-4 pb-0">
                  {super_agent.agents.map((agent, index) => (
                    <div className="card" key={index}>
                      <div
                        className="card-header bg-primary py-2 text-white mb-3"
                        style={{
                          lineHeight: 1.5,
                          height: "44px",
                        }}
                      >
                        AGENT NAME : {agent.agent}
                      </div>
                      <div className="card-body">
                        <div className="table">
                          <table
                            className="table table-bordered table-striped mb-0 longTable"
                            style={{
                              lineHeight: 1.5,
                            }}
                          >
                            <thead>
                              <tr>
                                <th colSpan="6">Agent</th>
                                <th colSpan="4">Super Agent</th>
                                <th colSpan="4">Master</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <th>Client</th>
                                <th>Match Amt</th>
                                <th>Cassino Com</th>
                                <th>Net Amt</th>
                                <th>Shr Amt</th>
                                <th>Final</th>

                                <th>Cassino Com</th>
                                <th>Net Amt</th>
                                <th>Shr Amt</th>
                                <th>Final</th>

                                <th>Cassino Com</th>
                                <th>Net Amt</th>
                                <th>Shr Amt</th>
                                <th>Final</th>
                              </tr>

                              {agent.users.map((user, index) => (
                                <tr key={index}>
                                  <td>{user.user || "Total"}</td>
                                  <td>{user.match_amt}</td>
                                  <td>{user.agent_cassino_com}</td>
                                  <td>{user.agent_net_amt}</td>
                                  <td>{user.agent_shr_amt}</td>
                                  <td>{user.agent_final_amt}</td>

                                  <td>{user.super_agent_cassino_com}</td>
                                  <td>{user.super_agent_net_amt}</td>
                                  <td>{user.super_agent_shr_amt}</td>
                                  <td>{user.super_agent_final_amt}</td>

                                  <td>{user.master_cassino_com}</td>
                                  <td>{user.master_net_amt}</td>
                                  <td>{user.master_shr_amt}</td>
                                  <td>{user.master_final_amt}</td>
                                </tr>
                              ))}

                              <tr className="fw-bold">
                                <td>Total</td>
                                <td>
                                  {agent.agent_total.match_amt.toFixed(2)}
                                </td>
                                <td>
                                  {agent.agent_total.agent_cassino_com.toFixed(
                                    2
                                  )}
                                </td>
                                <td>
                                  {agent.agent_total.agent_net_amt.toFixed(2)}
                                </td>
                                <td>
                                  {agent.agent_total.agent_shr_amt.toFixed(2)}
                                </td>
                                <td>
                                  {agent.agent_total.agent_final_amt.toFixed(2)}
                                </td>

                                <td>
                                  {agent.agent_total.super_agent_cassino_com.toFixed(
                                    2
                                  )}
                                </td>
                                <td>
                                  {agent.agent_total.super_agent_net_amt.toFixed(
                                    2
                                  )}
                                </td>
                                <td>
                                  {agent.agent_total.super_agent_shr_amt.toFixed(
                                    2
                                  )}
                                </td>
                                <td>
                                  {agent.agent_total.super_agent_final_amt.toFixed(
                                    2
                                  )}
                                </td>

                                <td>
                                  {agent.agent_total.master_cassino_com.toFixed(
                                    2
                                  )}
                                </td>
                                <td>
                                  {agent.agent_total.master_net_amt.toFixed(2)}
                                </td>
                                <td>
                                  {agent.agent_total.master_shr_amt.toFixed(2)}
                                </td>
                                <td>
                                  {agent.agent_total.master_final_amt.toFixed(
                                    2
                                  )}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  ))}

                  <div className="card-body bg-gray-light pt-1">
                    <table className="table table-bordered bg-white">
                      <tbody>
                        <tr
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          <td>Super Agent Grand Total</td>
                          <td>
                            {super_agent.super_agent_total.match_amt.toFixed(2)}
                          </td>
                          <td>
                            {super_agent.super_agent_total.agent_cassino_com.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.agent_net_amt.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.agent_shr_amt.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.agent_final_amt.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.super_agent_cassino_com.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.super_agent_net_amt.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.super_agent_shr_amt.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.super_agent_final_amt.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.master_cassino_com.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.master_net_amt.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.master_shr_amt.toFixed(
                              2
                            )}
                          </td>
                          <td>
                            {super_agent.super_agent_total.master_final_amt.toFixed(
                              2
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="card-body pt-1">
            <table className="table table-bordered bg-white">
              <tbody>
                <tr
                  style={{
                    fontWeight: "bold",
                  }}
                >
                  <td>Master Grand Total</td>
                  <td>{master.master_total.match_amt.toFixed(2)}</td>
                  <td>{master.master_total.agent_cassino_com.toFixed(2)}</td>
                  <td>{master.master_total.agent_net_amt.toFixed(2)}</td>
                  <td>{master.master_total.agent_shr_amt.toFixed(2)}</td>
                  <td>{master.master_total.agent_final_amt.toFixed(2)}</td>
                  <td>
                    {master.master_total.super_agent_cassino_com.toFixed(2)}
                  </td>
                  <td>{master.master_total.super_agent_net_amt.toFixed(2)}</td>
                  <td>{master.master_total.super_agent_shr_amt.toFixed(2)}</td>
                  <td>
                    {master.master_total.super_agent_final_amt.toFixed(2)}
                  </td>
                  <td>{master.master_total.master_cassino_com.toFixed(2)}</td>
                  <td>{master.master_total.master_net_amt.toFixed(2)}</td>
                  <td>{master.master_total.master_shr_amt.toFixed(2)}</td>
                  <td>{master.master_total.master_final_amt.toFixed(2)}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      ))
    )
  );
}
