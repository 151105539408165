import { useQuery } from "react-query";
import { createProtectedAxios } from "../../lib/auth";

const BASE_URL = process.env.REACT_APP_API_URL;

async function fetcher() {
  const axios = createProtectedAxios();

  const type = "get_user_only_sesison";

  const endpoint = `${BASE_URL}/users/${type}`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetBetDetails() {
  const { data, isLoading, isError, error } = useQuery(
    ["get-bet-details"],
    () => fetcher(),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { data, isLoading, isError, error };
}
