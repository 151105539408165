import React from "react";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import SuperSidebar from "../Components/SiderbarAllowFeatures/SuperAgent";
import axios from "axios";
import { toast } from "react-toastify";
export default function InternalRepeatSession() {
  const [data, setData] = React.useState([]);
  let token = localStorage.getItem("token");
  let header = { "x-access-token": `${token}` };
  let options = { headers: header };
  const location = useLocation();
  const { item } = location.state;
  const [sessionData, setSessionData] = React.useState([]);
  const [inPlayData, setInPlayData] = React.useState([]);
  // useEffect(() => {
  //   getList();
  //   getInPlayList();
  // }, []);

  // async function getList() {
  //   await axios
  //     .post("/owner/sessions-list", { matchId: gameId }, options)
  //     .then(async (resp) => {
  //       setSessionData(resp.data.data);
  //     });
  // }

  // async function getSession() {
  //   await axios
  //     .post("/game/activeMatchDetails", { gameId: gameId }, options)
  //     .then(async (resp) => {
  //       if (resp.data) {
  //         let respData = resp.data.data;
  //         var filterSesssion = [];
  //         respData["f"].map((_session, index) => {
  //           filterSesssion.push(_session);
  //         });
  //         setSession(filterSesssion);
  //         getList();
  //       }
  //     })
  //     .catch((err) => { });
  // }

  // async function setSession(sessionData) {
  //   console.log("-----call-----", sessionData);
  //   let body = {
  //     sessionData,
  //     gameId,
  //   };
  //   await axios
  //     .post("/owner/setSessionList", body, options)
  //     .then(async (res) => {
  //       console.log(res);
  //     })
  //     .catch((e) => console.log(e));
  // }

  // function getInPlayList() {
  //   axios.get("/owner/inplay-sessions", options).then(async (resp) => {
  //     setInPlayData(resp.data.sessions);
  //   });
  // }

  // async function addInPlay(e, item) {
  //   try {
  //     await axios
  //       .post("/owner/add-sessions-inplay", item, options)
  //       .then((res) => {
  //         toast.success(res.data.message);
  //         getInPlayList();
  //         getList();
  //       });
  //   } catch (error) {
  //     toast.error(error.data.message);
  //   }
  // }
  // async function RemoveFromInPlay(e, item) {
  //   try {
  //     await axios
  //       .post("/owner/remove-sessions-inplay", item, options)
  //       .then((res) => {
  //         toast.success(res.data.message);
  //         getInPlayList();
  //         getList();
  //       });
  //   } catch (error) {
  //     toast.error(error.data.message);
  //   }
  // }
  // const [sessionDataFields,setSessionDataFields] = useState({
  // })
  useEffect(() => {
    getList();
  }, []);
  function getList() {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}owner/compelte-session`,
        { matchid: item },
        options
      )
      .then(async (resp) => {
        console.log(resp.data.data);
        setData(resp.data.data);
      });
  }
  const handleChange = (e) => {
    const { name, value } = e.target;
    setData({ ...data, [name]: value });
  };
  const MainFun = async (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const Formvlaues = Object.fromEntries(data.entries());
    console.log("Formvlaues", Formvlaues);
    const response = await axios.post(
      `${process.env.REACT_APP_API_URL}owner/repeat-session-decision`,
      Formvlaues,
      options
    );
    if (response.data.success) {
      toast.success(response.data.message);
      e.target.reset();
      getList();
    } else {
      toast.warning(response.data.message);
      console.log(response.data.message);
    }
  };
  return (
    <>
      <SuperSidebar />
      <div className="content-wrapper">
        <div className="container-fluid content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 ml-2">
                {" "}
                <i class="fa fa-home"></i> All Matches
              </h1>
            </div>

            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active">All Matches</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div class="d-grid  gap-2 mt-2 d-md-flex justify-content-around  align-items-center">
                  <span>
                    <i class="fa fa-globe"></i> Sport Master All Matches Back{" "}
                  </span>
                  <button class="btn btn-success me-md-2" type="button">
                    Go to Session Desscion
                  </button>
                  <button class="btn btn-info" type="button">
                    Match Book
                  </button>
                  <button
                    class="btn btn-primary"
                    type="button"
                    // onClick={getSession}
                  >
                    Get new session
                  </button>
                </div>
                <div className="card-body">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>S.No </th>
                        <th>Session Name </th>
                        <th>Dession Run /Submit</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ? (
                        data.map((e, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>{e.nat}</td>
                              <td>
                                <div className="row">
                                  <form onSubmit={(e) => MainFun(e)}>
                                    <input
                                      type="Number"
                                      name="decision_run"
                                      value={e.decision}
                                      // onChange={handleChange}
                                      // value={EditInput.decision_run}
                                      className="form-control input-lg"
                                      oninput="this.value = Math.abs(this.value)"
                                      min="0"
                                    />
                                    <input
                                      type="hidden"
                                      name="sid"
                                      value={e.sid}
                                      readOnly=""
                                      className="readonly"
                                    />
                                    <input
                                      type="hidden"
                                      name="matchid"
                                      value={e.matchId}
                                      readOnly=""
                                      className="readonly"
                                    />
                                    <button
                                      class="float-right btn btn-success  m-2"
                                      type="submit"
                                    >
                                      Update
                                    </button>
                                  </form>
                                </div>
                              </td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr className="text-center">
                          {" "}
                          <td colSpan={4}>No Data Found</td>{" "}
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
{
  /* <div className="card-body">
                    <table className="table table-bordered table-striped">
                      <thead>
                        <tr>
                          <th>S.No </th>
                          <th>Session Name </th>
                          <th>No</th>
                          <th>Yes</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionData &&
                          sessionData.map((e, i) => {
                            return (
                              <tr>
                                <td>{i + 1}</td>
                                <td>
                                  <Link to={`/ session_inplay /: ${ e.nat }`}>
                                    {e.nat}
                                  </Link>{" "}
                                </td>
                                <td>
                                  {e.l1}/{e.ls1}
                                </td>
                                <td>
                                  {e.b1}/{e.bs1}
                                </td>
                                <td>
                                  {inPlayData &&
                                    inPlayData.find(
                                      (x) =>
                                        x.sid == e.sid && x.matchId == e.matchId
                                    ) ? (
                                    <>
                                      <button
                                        onClick={(ele) => {
                                          RemoveFromInPlay(ele, e);
                                        }}
                                        className="btn btn-danger inplay badge bg-danger"
                                        value={e}
                                      >
                                        Remove From Inplay
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        onClick={(ele) => addInPlay(ele, e)}
                                        className="btn btn-succes inplay badge bg-success"
                                        value={e}
                                      >
                                        Add Inplay
                                      </button>
                                    </>
                                  )}
                                </td>
                              </tr>
                            );
                          })}
                      </tbody>
                    </table>
                  </div> */
}
