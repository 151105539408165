import React from "react";
import "../CompleteSubPages.css";

const AgentCard = ({ finalReport }) => {
  const sumKey = (arr, key) => {
    // Check if arr is a valid array
    if (!Array.isArray(arr)) {
      console.error("Expected an array, but got:", arr);
      return 0; // Return 0 if arr is not an array
    }

    return arr
      .reduce((a, b) => {
        const value = parseFloat(b[key]) || 0; // Ensure the value is a number, default to 0
        return a + value;
      }, 0)
      .toFixed(2); // Return the sum, fixed to 2 decimal places
  };

  return finalReport.map((agent, index) => (
    <div className="card" key={index}>
      <div
        className="card-header bg-primary py-2"
        style={{
          lineHeight: 1.5,
        }}
      >
        AGENT NAME : {agent.name}
      </div>
      <div className="card-body pt-4">
        <div className="table">
          <table
            className="table table-bordered table-striped mb-0 longTable"
            style={{
              lineHeight: 1.5,
            }}
          >
            <thead>
              <tr>
                <th colSpan="6">Client Plus/Minus</th>
                <th colSpan="7">Agent</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>Client</th>
                <th>M Amt</th>
                <th>S Amt</th>
                {/* <th>T Amt</th> */}
                <th>C Comm</th>
                <th>Net Amt</th>
                {/* <th>C Mob</th> */}
                <th>Final</th>
                <th>M Com</th>
                <th>S Com</th>
                <th>T Com</th>
                <th>Net Amt</th>
                <th>Shr Amt</th>
                <th>Mob App</th>
                <th>Final</th>
              </tr>

              {agent.client.map((match, index) => (
                <tr key={index}>
                  <td>{match.client}</td>
                  <td>{match.m_amt.toFixed(2)}</td>
                  <td>{match.s_amt.toFixed(2)}</td>
                  {/* <td>{match.t_amt.toFixed(2)}</td> */}
                  <td>{match.c_com.toFixed(2)}</td>
                  <td>{match.net_amt.toFixed(2)}</td>
                  {/* <td>{match.c_mob.toFixed(2)}</td>*/}
                  <td>{match.final.toFixed(2)}</td>
                  <td>{match.agent_m_com.toFixed(2)}</td>
                  <td>{match.agent_s_com.toFixed(2)}</td>
                  <td>{match.agent_t_com.toFixed(2)}</td>
                  <td>{match.agent_net_amt.toFixed(2)}</td>
                  <td>{match.agent_shr_amt.toFixed(2)}</td>
                  <td>{match.agent_mob_app.toFixed(2)}</td>
                  <td>{match.agent_final.toFixed(2)}</td>
                </tr>
              ))}

              <tr>
                <td>Total</td>
                <td>{sumKey(agent.client, "m_amt")}</td>
                <td>{sumKey(agent.client, "s_amt")}</td>
                {/* <td>{sumKey(agent.client, "t_amt")}</td> */}
                <td>{sumKey(agent.client, "c_com")}</td>
                <td>{sumKey(agent.client, "net_amt")}</td>
                {/* <td>{sumKey(agent.client, "c_mob")}</td>*/}
                <td>{sumKey(agent.client, "final")}</td>
                <td>{sumKey(agent.client, "agent_m_com")}</td>
                <td>{sumKey(agent.client, "agent_s_com")}</td>
                <td>{sumKey(agent.client, "agent_t_com")}</td>
                <td>{sumKey(agent.client, "agent_net_amt")}</td>
                <td>{sumKey(agent.client, "agent_shr_amt")}</td>
                <td>{sumKey(agent.client, "agent_mob_app")}</td>
                <td>{sumKey(agent.client, "agent_final")}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  ));
};

export default AgentCard;
