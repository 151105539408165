import React, { useState, useEffect } from "react";
import PageHeader from "../../Components/PageHeader";
import { useLocation } from "react-router-dom";
import useGetCassinoLedger from "../../hooks/Games/Casino/useGetCassinoLedger";
import AdminCardCassino from "./components/AdminCardCassino";
import SuperAdminCardCassino from "./components/SuperAdminCardCassino";
import SubAdminCardCassino from "./components/SubAdminCardCassino";
import MasterCardCassino from "./components/MasterCardCassino";
import SuperAgentCardCassino from "./components/SuperAgentCardCassino";
import AgentCardCassino from "./components/AgentCardCassino";

export default function CasinoPlusMinusTable() {
  const location = useLocation();
  const UrlSearchParams = new URLSearchParams(location.search);
  const cassinoDate = UrlSearchParams.get("date");
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userCode = localStorage.getItem("CODE");
  const userName = localStorage.getItem("NAME");

  const { mutate: getCassinoLedger } = useGetCassinoLedger();

  const [data, setData] = useState([]);
  const [adminTotal, setAdminTotal] = useState({
    match_amt: 0,
    agent_cassino_com: 0,
    agent_net_amt: 0,
    agent_shr_amt: 0,
    agent_final_amt: 0,
    super_agent_cassino_com: 0,
    super_agent_net_amt: 0,
    super_agent_shr_amt: 0,
    super_agent_final_amt: 0,
    master_cassino_com: 0,
    master_net_amt: 0,
    master_shr_amt: 0,
    master_final_amt: 0,
    sub_admin_cassino_com: 0,
    sub_admin_net_amt: 0,
    sub_admin_shr_amt: 0,
    sub_admin_final_amt: 0,
    super_admin_cassino_com: 0,
    super_admin_net_amt: 0,
    super_admin_shr_amt: 0,
    super_admin_final_amt: 0,
  });

  useEffect(() => {
    const payload = {
      cassino_date: cassinoDate,
      logged_acc_type: accountType,
      logged_user_id: uuid,
    };

    getCassinoLedger(payload, {
      onSuccess: (data) => {
        let report = data.data.data;

        let finalReport = [];
        let uniqueSuperAdmins = [];

        report.forEach((element) => {
          if (!uniqueSuperAdmins.includes(element.super_admin_id)) {
            uniqueSuperAdmins.push(element.super_admin_id);
            finalReport.push({
              super_admin_id: element.super_admin_id,
              super_admin: element.super_admin,
              sub_admins: [],
              super_admin_total: {
                match_amt: 0,
                agent_cassino_com: 0,
                agent_net_amt: 0,
                agent_shr_amt: 0,
                agent_final_amt: 0,
                super_agent_cassino_com: 0,
                super_agent_net_amt: 0,
                super_agent_shr_amt: 0,
                super_agent_final_amt: 0,
                master_cassino_com: 0,
                master_net_amt: 0,
                master_shr_amt: 0,
                master_final_amt: 0,
                sub_admin_cassino_com: 0,
                sub_admin_net_amt: 0,
                sub_admin_shr_amt: 0,
                sub_admin_final_amt: 0,
                super_admin_cassino_com: 0,
                super_admin_net_amt: 0,
                super_admin_shr_amt: 0,
                super_admin_final_amt: 0,
              },
            });
          }
        });

        report.forEach((element) => {
          finalReport.forEach((superAdmin) => {
            if (superAdmin.super_admin_id === element.super_admin_id) {
              const subAdminExists = superAdmin.sub_admins.some(
                (subAdmin) => subAdmin.sub_admin_id === element.sub_admin_id
              );

              if (!subAdminExists && element.sub_admin_id !== null) {
                superAdmin.sub_admins.push({
                  sub_admin_id: element.sub_admin_id,
                  sub_admin: element.sub_admin,
                  masters: [],
                  sub_admin_total: {
                    match_amt: 0,
                    agent_cassino_com: 0,
                    agent_net_amt: 0,
                    agent_shr_amt: 0,
                    agent_final_amt: 0,
                    super_agent_cassino_com: 0,
                    super_agent_net_amt: 0,
                    super_agent_shr_amt: 0,
                    super_agent_final_amt: 0,
                    master_cassino_com: 0,
                    master_net_amt: 0,
                    master_shr_amt: 0,
                    master_final_amt: 0,
                    sub_admin_cassino_com: 0,
                    sub_admin_net_amt: 0,
                    sub_admin_shr_amt: 0,
                    sub_admin_final_amt: 0,
                    super_admin_cassino_com: 0,
                    super_admin_net_amt: 0,
                    super_admin_shr_amt: 0,
                    super_admin_final_amt: 0,
                  },
                });
              }
            }
          });
        });

        report.forEach((element) => {
          finalReport.forEach((superAdmin) => {
            superAdmin.sub_admins.forEach((subAdmin) => {
              if (subAdmin.sub_admin_id === element.sub_admin_id) {
                const masterExists = subAdmin.masters.some(
                  (master) => master.master_id === element.master_id
                );

                if (!masterExists && element.master_id !== null) {
                  subAdmin.masters.push({
                    master_id: element.master_id,
                    master: element.master,
                    super_agents: [],
                    master_total: {
                      match_amt: 0,
                      agent_cassino_com: 0,
                      agent_net_amt: 0,
                      agent_shr_amt: 0,
                      agent_final_amt: 0,
                      super_agent_cassino_com: 0,
                      super_agent_net_amt: 0,
                      super_agent_shr_amt: 0,
                      super_agent_final_amt: 0,
                      master_cassino_com: 0,
                      master_net_amt: 0,
                      master_shr_amt: 0,
                      master_final_amt: 0,
                      sub_admin_cassino_com: 0,
                      sub_admin_net_amt: 0,
                      sub_admin_shr_amt: 0,
                      sub_admin_final_amt: 0,
                      super_admin_cassino_com: 0,
                      super_admin_net_amt: 0,
                      super_admin_shr_amt: 0,
                      super_admin_final_amt: 0,
                    },
                  });
                }
              }
            });
          });
        });

        report.forEach((element) => {
          finalReport.forEach((superAdmin) => {
            superAdmin.sub_admins.forEach((subAdmin) => {
              subAdmin.masters.forEach((master) => {
                if (master.master_id === element.master_id) {
                  const superAgentExists = master.super_agents.some(
                    (superAgent) =>
                      superAgent.super_agent_id === element.super_agent_id
                  );

                  if (!superAgentExists && element.super_agent_id !== null) {
                    master.super_agents.push({
                      super_agent_id: element.super_agent_id,
                      super_agent: element.super_agent,
                      agents: [],
                      super_agent_total: {
                        match_amt: 0,
                        agent_cassino_com: 0,
                        agent_net_amt: 0,
                        agent_shr_amt: 0,
                        agent_final_amt: 0,
                        super_agent_cassino_com: 0,
                        super_agent_net_amt: 0,
                        super_agent_shr_amt: 0,
                        super_agent_final_amt: 0,
                        master_cassino_com: 0,
                        master_net_amt: 0,
                        master_shr_amt: 0,
                        master_final_amt: 0,
                        sub_admin_cassino_com: 0,
                        sub_admin_net_amt: 0,
                        sub_admin_shr_amt: 0,
                        sub_admin_final_amt: 0,
                        super_admin_cassino_com: 0,
                        super_admin_net_amt: 0,
                        super_admin_shr_amt: 0,
                        super_admin_final_amt: 0,
                      },
                    });
                  }
                }
              });
            });
          });
        });

        report.forEach((element) => {
          finalReport.forEach((superAdmin) => {
            superAdmin.sub_admins.forEach((subAdmin) => {
              subAdmin.masters.forEach((master) => {
                master.super_agents.forEach((superAgent) => {
                  if (superAgent.super_agent_id === element.super_agent_id) {
                    const agentExists = superAgent.agents.some(
                      (agent) => agent.agent_id === element.agent_id
                    );

                    if (!agentExists && element.agent_id !== null) {
                      superAgent.agents.push({
                        agent_id: element.agent_id,
                        agent: element.agent,
                        users: [],
                        agent_total: {
                          match_amt: 0,
                          cassino_com: 0,
                          net_amt: 0,
                          final_amt: 0,
                          agent_cassino_com: 0,
                          agent_net_amt: 0,
                          agent_shr_amt: 0,
                          agent_final_amt: 0,
                          super_agent_cassino_com: 0,
                          super_agent_net_amt: 0,
                          super_agent_shr_amt: 0,
                          super_agent_final_amt: 0,
                          master_cassino_com: 0,
                          master_net_amt: 0,
                          master_shr_amt: 0,
                          master_final_amt: 0,
                          sub_admin_cassino_com: 0,
                          sub_admin_net_amt: 0,
                          sub_admin_shr_amt: 0,
                          sub_admin_final_amt: 0,
                          super_admin_cassino_com: 0,
                          super_admin_net_amt: 0,
                          super_admin_shr_amt: 0,
                          super_admin_final_amt: 0,
                        },
                      });
                    }
                  }
                });
              });
            });
          });
        });

        report.forEach((element) => {
          finalReport.forEach((superAdmin) => {
            superAdmin.sub_admins.forEach((subAdmin) => {
              subAdmin.masters.forEach((master) => {
                master.super_agents.forEach((superAgent) => {
                  superAgent.agents.forEach((agent) => {
                    if (agent.agent_id === element.agent_id) {
                      const userExists = agent.users.some(
                        (user) => user.user_id === element.user_id
                      );

                      if (!userExists && element.user_id !== null) {
                        agent.users.push(element);

                        agent.agent_total.match_amt += parseFloat(
                          element.match_amt
                        );
                        agent.agent_total.cassino_com += parseFloat(
                          element.cassino_com
                        );
                        agent.agent_total.net_amt += parseFloat(
                          element.net_amt
                        );
                        agent.agent_total.final_amt += parseFloat(
                          element.final_amt
                        );
                        agent.agent_total.agent_cassino_com += parseFloat(
                          element.agent_cassino_com
                        );
                        agent.agent_total.agent_net_amt += parseFloat(
                          element.agent_net_amt
                        );
                        agent.agent_total.agent_shr_amt += parseFloat(
                          element.agent_shr_amt
                        );
                        agent.agent_total.agent_final_amt += parseFloat(
                          element.agent_final_amt
                        );
                        agent.agent_total.super_agent_cassino_com += parseFloat(
                          element.super_agent_cassino_com
                        );
                        agent.agent_total.super_agent_net_amt += parseFloat(
                          element.super_agent_net_amt
                        );
                        agent.agent_total.super_agent_shr_amt += parseFloat(
                          element.super_agent_shr_amt
                        );
                        agent.agent_total.super_agent_final_amt += parseFloat(
                          element.super_agent_final_amt
                        );
                        agent.agent_total.master_cassino_com += parseFloat(
                          element.master_cassino_com
                        );
                        agent.agent_total.master_net_amt += parseFloat(
                          element.master_net_amt
                        );
                        agent.agent_total.master_shr_amt += parseFloat(
                          element.master_shr_amt
                        );
                        agent.agent_total.master_final_amt += parseFloat(
                          element.master_final_amt
                        );
                        agent.agent_total.sub_admin_cassino_com += parseFloat(
                          element.sub_admin_cassino_com
                        );
                        agent.agent_total.sub_admin_net_amt += parseFloat(
                          element.sub_admin_net_amt
                        );
                        agent.agent_total.sub_admin_shr_amt += parseFloat(
                          element.sub_admin_shr_amt
                        );
                        agent.agent_total.sub_admin_final_amt += parseFloat(
                          element.sub_admin_final_amt
                        );
                        agent.agent_total.super_admin_cassino_com += parseFloat(
                          element.super_admin_cassino_com
                        );
                        agent.agent_total.super_admin_net_amt += parseFloat(
                          element.super_admin_net_amt
                        );
                        agent.agent_total.super_admin_shr_amt += parseFloat(
                          element.super_admin_shr_amt
                        );
                        agent.agent_total.super_admin_final_amt += parseFloat(
                          element.super_admin_final_amt
                        );
                      }
                    }
                  });
                });
              });
            });
          });
        });

        finalReport.forEach((superAdmin) => {
          superAdmin.sub_admins.forEach((subAdmin) => {
            subAdmin.masters.forEach((master) => {
              master.super_agents.forEach((superAgent) => {
                superAgent.agents.forEach((agent) => {
                  superAgent.super_agent_total.match_amt += parseFloat(
                    agent.agent_total.match_amt
                  );
                  superAgent.super_agent_total.agent_cassino_com += parseFloat(
                    agent.agent_total.agent_cassino_com
                  );
                  superAgent.super_agent_total.agent_net_amt += parseFloat(
                    agent.agent_total.agent_net_amt
                  );
                  superAgent.super_agent_total.agent_shr_amt += parseFloat(
                    agent.agent_total.agent_shr_amt
                  );
                  //write all the others
                  superAgent.super_agent_total.agent_final_amt += parseFloat(
                    agent.agent_total.agent_final_amt
                  );
                  superAgent.super_agent_total.super_agent_cassino_com +=
                    parseFloat(agent.agent_total.super_agent_cassino_com);
                  superAgent.super_agent_total.super_agent_net_amt +=
                    parseFloat(agent.agent_total.super_agent_net_amt);
                  superAgent.super_agent_total.super_agent_shr_amt +=
                    parseFloat(agent.agent_total.super_agent_shr_amt);
                  superAgent.super_agent_total.super_agent_final_amt +=
                    parseFloat(agent.agent_total.super_agent_final_amt);
                  superAgent.super_agent_total.master_cassino_com += parseFloat(
                    agent.agent_total.master_cassino_com
                  );
                  superAgent.super_agent_total.master_net_amt += parseFloat(
                    agent.agent_total.master_net_amt
                  );
                  superAgent.super_agent_total.master_shr_amt += parseFloat(
                    agent.agent_total.master_shr_amt
                  );
                  superAgent.super_agent_total.master_final_amt += parseFloat(
                    agent.agent_total.master_final_amt
                  );
                  superAgent.super_agent_total.sub_admin_cassino_com +=
                    parseFloat(agent.agent_total.sub_admin_cassino_com);
                  superAgent.super_agent_total.sub_admin_net_amt += parseFloat(
                    agent.agent_total.sub_admin_net_amt
                  );
                  superAgent.super_agent_total.sub_admin_shr_amt += parseFloat(
                    agent.agent_total.sub_admin_shr_amt
                  );
                  superAgent.super_agent_total.sub_admin_final_amt +=
                    parseFloat(agent.agent_total.sub_admin_final_amt);
                  superAgent.super_agent_total.super_admin_cassino_com +=
                    parseFloat(agent.agent_total.super_admin_cassino_com);
                  superAgent.super_agent_total.super_admin_net_amt +=
                    parseFloat(agent.agent_total.super_admin_net_amt);
                  superAgent.super_agent_total.super_admin_shr_amt +=
                    parseFloat(agent.agent_total.super_admin_shr_amt);
                  superAgent.super_agent_total.super_admin_final_amt +=
                    parseFloat(agent.agent_total.super_admin_final_amt);
                });
              });
            });
          });
        });

        finalReport.forEach((superAdmin) => {
          superAdmin.sub_admins.forEach((subAdmin) => {
            subAdmin.masters.forEach((master) => {
              master.super_agents.forEach((superAgent) => {
                master.master_total.match_amt +=
                  superAgent.super_agent_total.match_amt;
                master.master_total.agent_cassino_com +=
                  superAgent.super_agent_total.agent_cassino_com;
                master.master_total.agent_net_amt +=
                  superAgent.super_agent_total.agent_net_amt;
                master.master_total.agent_shr_amt +=
                  superAgent.super_agent_total.agent_shr_amt;
                master.master_total.agent_final_amt +=
                  superAgent.super_agent_total.agent_final_amt;
                master.master_total.super_agent_cassino_com +=
                  superAgent.super_agent_total.super_agent_cassino_com;
                master.master_total.super_agent_net_amt +=
                  superAgent.super_agent_total.super_agent_net_amt;
                master.master_total.super_agent_shr_amt +=
                  superAgent.super_agent_total.super_agent_shr_amt;
                master.master_total.super_agent_final_amt +=
                  superAgent.super_agent_total.super_agent_final_amt;
                master.master_total.master_cassino_com +=
                  superAgent.super_agent_total.master_cassino_com;
                master.master_total.master_net_amt +=
                  superAgent.super_agent_total.master_net_amt;
                master.master_total.master_shr_amt +=
                  superAgent.super_agent_total.master_shr_amt;
                master.master_total.master_final_amt +=
                  superAgent.super_agent_total.master_final_amt;
                master.master_total.sub_admin_cassino_com +=
                  superAgent.super_agent_total.sub_admin_cassino_com;
                master.master_total.sub_admin_net_amt +=
                  superAgent.super_agent_total.sub_admin_net_amt;
                master.master_total.sub_admin_shr_amt +=
                  superAgent.super_agent_total.sub_admin_shr_amt;
                master.master_total.sub_admin_final_amt +=
                  superAgent.super_agent_total.sub_admin_final_amt;
                master.master_total.super_admin_cassino_com +=
                  superAgent.super_agent_total.super_admin_cassino_com;
                master.master_total.super_admin_net_amt +=
                  superAgent.super_agent_total.super_admin_net_amt;
                master.master_total.super_admin_shr_amt +=
                  superAgent.super_agent_total.super_admin_shr_amt;
                master.master_total.super_admin_final_amt +=
                  superAgent.super_agent_total.super_admin_final_amt;
              });
            });
          });
        });

        finalReport.forEach((superAdmin) => {
          superAdmin.sub_admins.forEach((subAdmin) => {
            subAdmin.masters.forEach((master) => {
              subAdmin.sub_admin_total.match_amt +=
                master.master_total.match_amt;
              subAdmin.sub_admin_total.agent_cassino_com +=
                master.master_total.agent_cassino_com;
              subAdmin.sub_admin_total.agent_net_amt +=
                master.master_total.agent_net_amt;
              subAdmin.sub_admin_total.agent_shr_amt +=
                master.master_total.agent_shr_amt;
              subAdmin.sub_admin_total.agent_final_amt +=
                master.master_total.agent_final_amt;
              subAdmin.sub_admin_total.super_agent_cassino_com +=
                master.master_total.super_agent_cassino_com;
              subAdmin.sub_admin_total.super_agent_net_amt +=
                master.master_total.super_agent_net_amt;
              subAdmin.sub_admin_total.super_agent_shr_amt +=
                master.master_total.super_agent_shr_amt;
              subAdmin.sub_admin_total.super_agent_final_amt +=
                master.master_total.super_agent_final_amt;
              subAdmin.sub_admin_total.master_cassino_com +=
                master.master_total.master_cassino_com;
              subAdmin.sub_admin_total.master_net_amt +=
                master.master_total.master_net_amt;
              subAdmin.sub_admin_total.master_shr_amt +=
                master.master_total.master_shr_amt;
              subAdmin.sub_admin_total.master_final_amt +=
                master.master_total.master_final_amt;
              subAdmin.sub_admin_total.sub_admin_cassino_com +=
                master.master_total.sub_admin_cassino_com;
              subAdmin.sub_admin_total.sub_admin_net_amt +=
                master.master_total.sub_admin_net_amt;
              subAdmin.sub_admin_total.sub_admin_shr_amt +=
                master.master_total.sub_admin_shr_amt;
              subAdmin.sub_admin_total.sub_admin_final_amt +=
                master.master_total.sub_admin_final_amt;
              subAdmin.sub_admin_total.super_admin_cassino_com +=
                master.master_total.super_admin_cassino_com;
              subAdmin.sub_admin_total.super_admin_net_amt +=
                master.master_total.super_admin_net_amt;
              subAdmin.sub_admin_total.super_admin_shr_amt +=
                master.master_total.super_admin_shr_amt;
              subAdmin.sub_admin_total.super_admin_final_amt +=
                master.master_total.super_admin_final_amt;
            });
          });
        });

        finalReport.forEach((superAdmin) => {
          superAdmin.sub_admins.forEach((subAdmin) => {
            superAdmin.super_admin_total.match_amt +=
              subAdmin.sub_admin_total.match_amt;
            superAdmin.super_admin_total.agent_cassino_com +=
              subAdmin.sub_admin_total.agent_cassino_com;
            superAdmin.super_admin_total.agent_net_amt +=
              subAdmin.sub_admin_total.agent_net_amt;
            superAdmin.super_admin_total.agent_shr_amt +=
              subAdmin.sub_admin_total.agent_shr_amt;
            superAdmin.super_admin_total.agent_final_amt +=
              subAdmin.sub_admin_total.agent_final_amt;
            superAdmin.super_admin_total.super_agent_cassino_com +=
              subAdmin.sub_admin_total.super_agent_cassino_com;
            superAdmin.super_admin_total.super_agent_net_amt +=
              subAdmin.sub_admin_total.super_agent_net_amt;
            superAdmin.super_admin_total.super_agent_shr_amt +=
              subAdmin.sub_admin_total.super_agent_shr_amt;
            superAdmin.super_admin_total.super_agent_final_amt +=
              subAdmin.sub_admin_total.super_agent_final_amt;
            superAdmin.super_admin_total.master_cassino_com +=
              subAdmin.sub_admin_total.master_cassino_com;
            superAdmin.super_admin_total.master_net_amt +=
              subAdmin.sub_admin_total.master_net_amt;
            superAdmin.super_admin_total.master_shr_amt +=
              subAdmin.sub_admin_total.master_shr_amt;
            superAdmin.super_admin_total.master_final_amt +=
              subAdmin.sub_admin_total.master_final_amt;
            superAdmin.super_admin_total.sub_admin_cassino_com +=
              subAdmin.sub_admin_total.sub_admin_cassino_com;
            superAdmin.super_admin_total.sub_admin_net_amt +=
              subAdmin.sub_admin_total.sub_admin_net_amt;
            superAdmin.super_admin_total.sub_admin_shr_amt +=
              subAdmin.sub_admin_total.sub_admin_shr_amt;
            superAdmin.super_admin_total.sub_admin_final_amt +=
              subAdmin.sub_admin_total.sub_admin_final_amt;
            superAdmin.super_admin_total.super_admin_cassino_com +=
              subAdmin.sub_admin_total.super_admin_cassino_com;
            superAdmin.super_admin_total.super_admin_net_amt +=
              subAdmin.sub_admin_total.super_admin_net_amt;
            superAdmin.super_admin_total.super_admin_shr_amt +=
              subAdmin.sub_admin_total.super_admin_shr_amt;
            superAdmin.super_admin_total.super_admin_final_amt +=
              subAdmin.sub_admin_total.super_admin_final_amt;
          });
        });

        console.log(finalReport);

        if (accountType === "admin") {
          let admin_total = {
            match_amt: 0,
            agent_cassino_com: 0,
            agent_net_amt: 0,
            agent_shr_amt: 0,
            agent_final_amt: 0,
            super_agent_cassino_com: 0,
            super_agent_net_amt: 0,
            super_agent_shr_amt: 0,
            super_agent_final_amt: 0,
            master_cassino_com: 0,
            master_net_amt: 0,
            master_shr_amt: 0,
            master_final_amt: 0,
            sub_admin_cassino_com: 0,
            sub_admin_net_amt: 0,
            sub_admin_shr_amt: 0,
            sub_admin_final_amt: 0,
            super_admin_cassino_com: 0,
            super_admin_net_amt: 0,
            super_admin_shr_amt: 0,
            super_admin_final_amt: 0,
          };

          finalReport.forEach((superAdmin) => {
            admin_total.match_amt += superAdmin.super_admin_total.match_amt;
            admin_total.agent_cassino_com +=
              superAdmin.super_admin_total.agent_cassino_com;
            admin_total.agent_net_amt +=
              superAdmin.super_admin_total.agent_net_amt;
            admin_total.agent_shr_amt +=
              superAdmin.super_admin_total.agent_shr_amt;
            admin_total.agent_final_amt +=
              superAdmin.super_admin_total.agent_final_amt;
            admin_total.super_agent_cassino_com +=
              superAdmin.super_admin_total.super_agent_cassino_com;
            admin_total.super_agent_net_amt +=
              superAdmin.super_admin_total.super_agent_net_amt;
            admin_total.super_agent_shr_amt +=
              superAdmin.super_admin_total.super_agent_shr_amt;
            admin_total.super_agent_final_amt +=
              superAdmin.super_admin_total.super_agent_final_amt;
            admin_total.master_cassino_com +=
              superAdmin.super_admin_total.master_cassino_com;
            admin_total.master_net_amt +=
              superAdmin.super_admin_total.master_net_amt;
            admin_total.master_shr_amt +=
              superAdmin.super_admin_total.master_shr_amt;
            admin_total.master_final_amt +=
              superAdmin.super_admin_total.master_final_amt;
            admin_total.sub_admin_cassino_com +=
              superAdmin.super_admin_total.sub_admin_cassino_com;
            admin_total.sub_admin_net_amt +=
              superAdmin.super_admin_total.sub_admin_net_amt;
            admin_total.sub_admin_shr_amt +=
              superAdmin.super_admin_total.sub_admin_shr_amt;
            admin_total.sub_admin_final_amt +=
              superAdmin.super_admin_total.sub_admin_final_amt;
            admin_total.super_admin_cassino_com +=
              superAdmin.super_admin_total.super_admin_cassino_com;
            admin_total.super_admin_net_amt +=
              superAdmin.super_admin_total.super_admin_net_amt;
            admin_total.super_admin_shr_amt +=
              superAdmin.super_admin_total.super_admin_shr_amt;
            admin_total.super_admin_final_amt +=
              superAdmin.super_admin_total.super_admin_final_amt;
          });

          setAdminTotal(admin_total);
        }
        setData(finalReport);
      },
      onError: (error) => {
        console.error(error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <PageHeader title={`Casino Report: ${cassinoDate}`} />

      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card long-table-11">
              <div
                className="card-header bg-white"
                style={{
                  width:
                    data.length > 0
                      ? accountType === "admin" || accountType === "super_admin"
                        ? "2500px"
                        : accountType === "sub_admin"
                        ? "2000px"
                        : accountType === "master"
                        ? "1500px"
                        : "inherit"
                      : "inherit",
                }}
              >
                <h5 className="mb-0">Casino Report : {cassinoDate}</h5>
              </div>

              <div
                className="card-body bg-white"
                style={{
                  width:
                    data.length > 0
                      ? accountType === "admin" || accountType === "super_admin"
                        ? "2500px"
                        : accountType === "sub_admin"
                        ? "2000px"
                        : accountType === "master"
                        ? "1500px"
                        : "inherit"
                      : "inherit",
                }}
              >
                {data.length > 0 ? (
                  accountType === "admin" ? (
                    <AdminCardCassino
                      data={data}
                      userCode={userCode}
                      userName={userName}
                      accountType={accountType}
                      adminTotal={adminTotal}
                    />
                  ) : accountType === "super_admin" ? (
                    <SuperAdminCardCassino data={data} />
                  ) : accountType === "sub_admin" ? (
                    <SubAdminCardCassino data={data} />
                  ) : accountType === "master" ? (
                    <MasterCardCassino data={data} />
                  ) : accountType === "super_agent" ? (
                    <SuperAgentCardCassino data={data} />
                  ) : accountType === "agent" ? (
                    <AgentCardCassino data={data} />
                  ) : null
                ) : (
                  <div className="alert alert-danger">No Data Found</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
