/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./Casino.css";
import PageHeader from "../../Components/PageHeader";
import useGetT20 from "../../hooks/Games/Casino/useGetT20";
import useGetAB from "../../hooks/Games/Casino/useGetAB";
import useGetCassinoBets from "../../hooks/Games/Casino/useGetCassinoBets";
import { dateTimeFormatter } from "../../utils/dateFormatter";

export default function LiveAndarBahar() {
  const BASE_URL = process.env.REACT_APP_CLOUDINARY_URL;

  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: getT20 } = useGetT20();
  const { mutate: getAB } = useGetAB();
  const { mutate: getCassinoBets } = useGetCassinoBets();

  const [betsData, setBetsData] = useState([]);
  const [mid, setMid] = useState("");
  const [playerAData, setPlayerAData] = useState([]);
  const [playerBData, setPlayerBData] = useState([]);
  const [playerAPosition, setPlayerAPosition] = useState(0);
  const [playerBPosition, setPlayerBPosition] = useState(0);

  const [timer, setTimer] = useState("");
  const [cardMain, setCardMain] = useState("1");
  const [card1, setCard1] = useState("1");
  const [card2, setCard2] = useState("1");
  const [card3, setCard3] = useState("1");
  const [card4, setCard4] = useState("1");
  const [card5, setCard5] = useState("1");
  const [card6, setCard6] = useState("1");

  const handleGetT20 = () => {
    getAB(undefined, {
      onSuccess: (response) => {
        setMid(response.data.data.t1[0].mid);
        setTimer(response.data.data.t1[0].autotime);

        handleGetCassinoBets(response.data.data.t1[0].mid);

        let cards = response.data.data.t1[0].cards.split(",");

        setCardMain(cards[0]);

        const nonOneCards = cards.filter((card) => card !== "1");
        const last6NonOneCards = nonOneCards.slice(-6);

        if (nonOneCards.length < 8) {
          setCard1(nonOneCards[1] || "1");
          setCard2(nonOneCards[2] || "1");
          setCard3(nonOneCards[3] || "1");
          setCard4(nonOneCards[4] || "1");
          setCard5(nonOneCards[5] || "1");
          setCard6(nonOneCards[6] || "1");
        } else {
          //check if the lenght of nonOneCards is even
          if (nonOneCards.length % 2 === 0) {
            setCard2(last6NonOneCards[0]);
            setCard4(last6NonOneCards[2]);
            setCard6(last6NonOneCards[4]);
            setCard1(last6NonOneCards[1]);
            setCard3(last6NonOneCards[3]);
            setCard5(last6NonOneCards[5]);
          } else {
            setCard1(last6NonOneCards[0]);
            setCard2(last6NonOneCards[1]);
            setCard3(last6NonOneCards[2]);
            setCard4(last6NonOneCards[3]);
            setCard5(last6NonOneCards[4]);
            setCard6(last6NonOneCards[5]);
          }
        }

        setPlayerAData(response.data.data.t2[0]);
        setPlayerBData(response.data.data.t2[1]);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  const handleGetCassinoBets = (match_id) => {
    if (
      match_id === 0 ||
      match_id === undefined ||
      match_id === null ||
      match_id === "0"
    ) {
      return;
    } else {
      const payload = {
        logged_acc_type: accountType,
        logged_user_id: uuid,
        match_id: "cassino_game_6a8c50064540",
        mid: match_id,
        result: "pending",
      };

      getCassinoBets(payload, {
        onSuccess: (data) => {
          setBetsData(data.data.data);

          let below_share = 0;
          let logged_in_share = 0;
          let aPos = 0;
          let bPos = 0;

          data.data.data.forEach((item) => {
            if (accountType === "admin") {
              logged_in_share = 100;
              below_share =
                item.user_account.user_agent.user_super_agent.user_master
                  .user_sub_admin.user_super_admin.cassino_share;
            }
            if (accountType === "super_admin") {
              logged_in_share =
                item.user_account.user_agent.user_super_agent.user_master
                  .user_sub_admin.user_super_admin.cassino_share;
              below_share =
                item.user_account.user_agent.user_super_agent.user_master
                  .user_sub_admin.cassino_share;
            }
            if (accountType === "sub_admin") {
              logged_in_share =
                item.user_account.user_agent.user_super_agent.user_master
                  .user_sub_admin.cassino_share;
              below_share =
                item.user_account.user_agent.user_super_agent.user_master
                  .cassino_share;
            }
            if (accountType === "master") {
              logged_in_share =
                item.user_account.user_agent.user_super_agent.user_master
                  .cassino_share;
              below_share =
                item.user_account.user_agent.user_super_agent.cassino_share;
            }
            if (accountType === "super_agent") {
              logged_in_share =
                item.user_account.user_agent.user_super_agent.cassino_share;
              below_share = item.user_account.user_agent.cassino_share;
            }
            if (accountType === "agent") {
              logged_in_share = item.user_account.user_agent.cassino_share;
              below_share = item.user_account.user_agent.cassino_share;
            }

            let share = logged_in_share - below_share;
            if (item.nation === "Player A") {
              aPos += (item.amount * item.rate * share) / 100;
              bPos -= (item.amount * share) / 100;
            } else {
              bPos += (item.amount * item.rate * share) / 100;
              aPos -= (item.amount * share) / 100;
            }
          });

          setPlayerAPosition(aPos);
          setPlayerBPosition(bPos);
        },
        onError: (error) => {
          console.log(error);
        },
      });
    }
  };

  useEffect(() => {
    handleGetT20();

    const intervalId = setInterval(() => {
      handleGetT20();
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <PageHeader title={"Live Andar Bahar"} />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-6 virtual-teen-pati">
            <table
              className="table table-bordered table-dark"
              id="casinoOdds"
              height="200px"
            >
              <thead>
                <tr>
                  <th colSpan="2" className="text-center">
                    Virtual Ander Bahar
                  </th>
                  <th className="text-center" id="roundId">
                    Round ID: {mid}
                  </th>
                </tr>

                <tr>
                  <th>Player</th>
                  <th>LAGAI</th>
                  <th>Profit/Loss</th>
                </tr>
              </thead>

              <tbody className="align-middle">
                <tr>
                  <td>Andar</td>
                  <td className="text-primary fs-5 fw-semibold">0.98</td>
                  <td
                    className={`${
                      playerAPosition > 0 ? "text-primary" : "text-danger"
                    } fs-5 fw-semibold`}
                  >
                    {playerAPosition}
                  </td>
                </tr>
                <tr>
                  <td>Bahar</td>
                  <td className="text-primary fs-5 fw-semibold">0.98</td>
                  <td
                    className={`${
                      playerBPosition > 0 ? "text-primary" : "text-danger"
                    } fs-5 fw-semibold`}
                  >
                    {playerBPosition}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-md-6 virtual-teen-pati">
            <div className="score-panel">
              <div className="card-panel card-panel-ab p-4">
                <div className="d-flex flex-column card-header-container">
                  <d3v className="card-header card-header-ab">A</d3v>
                  <div className="card-header card-header-ab">B</div>
                </div>

                <img
                  className="mx-2 main-card"
                  src={`${BASE_URL}/${cardMain}.webp`}
                  alt="Card Main"
                  width="28"
                  height="35"
                />

                <div className="d-flex flex-column gap-2">
                  <div className="cards-container">
                    <img
                      className="mr-2"
                      src={`${BASE_URL}/${card2}.webp`}
                      alt="Card 2"
                      width="28"
                    />
                    <img
                      className="mr-2"
                      src={`${BASE_URL}/${card4}.webp`}
                      alt="Card 4"
                      width="28"
                    />
                    <img
                      className="mr-2"
                      src={`${BASE_URL}/${card6}.webp`}
                      alt="Card 6"
                      width="28"
                    />
                  </div>

                  <div className="cards-container">
                    <img
                      className="mr-2"
                      src={`${BASE_URL}/${card1}.webp`}
                      alt="Card 1"
                      width="28"
                    />
                    <img
                      className="mr-2"
                      src={`${BASE_URL}/${card3}.webp`}
                      alt="Card 3"
                      width="28"
                    />
                    <img
                      className="mr-2"
                      src={`${BASE_URL}/${card5}.webp`}
                      alt="Card 5"
                      width="28"
                    />
                  </div>
                </div>
              </div>
              <div className="bet-timer">{timer}</div>
              <iframe
                title="Teen Patti"
                width="100%"
                height="210"
                style={{
                  border: "1px",
                  marginLeft: "-1px",
                  marginRight: "-1px",
                  marginTop: "0px",
                  marginBottom: "-5px",
                  // backgroundColor: "gray",
                }}
                src="https://video.247sitedata.xyz/route/?id=3043"
              ></iframe>
            </div>
          </div>

          <div className="col-md-12 pt-3">
            <div className="card">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-bordered table-striped">
                    <thead className="bg-yellow">
                      <tr>
                        <th>#</th>
                        <th>Client</th>
                        <th>Round ID</th>
                        <th>Player</th>
                        <th>Rate</th>
                        <th>Amount</th>
                        <th>Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {betsData?.length !== 0 ? (
                        betsData.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>
                              {item.user_account.code} {item.user_account.name}
                            </td>
                            <td>{item.mid}</td>
                            <td>{item.nation}</td>
                            <td>{item.rate}</td>
                            <td>{item.amount}</td>
                            <td>{dateTimeFormatter(item.createdAt)}</td>
                          </tr>
                        ))
                      ) : (
                        <tr className="bg-white">
                          <td colSpan="7">
                            <h6 className="text-center">
                              There are no records to display
                            </h6>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
