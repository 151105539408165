import React from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min";

const UserModal = ({ userData, accountType }) => {
  const navigate = useNavigate();

  const handleNavigation = (path) => {
    // Close the modal
    const modalElement = document.getElementById("userModal");
    const modal = Modal.getInstance(modalElement);
    if (modal) {
      modal.hide();
    }
    // Navigate to the specified path
    navigate(path);
  };

  return (
    <div
      className="modal fade"
      id="userModal"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="userModalLabel"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            {userData ? (
              <div className="row">
                {userData.superAdmin && accountType !== "super_admin" ? (
                  <div className="col-6">
                    <div
                      className="small-box bg-primary"
                      onClick={() => handleNavigation("/supermaster")}
                      data-bs-dismiss="modal"
                    >
                      <div className="inner">
                        <h3>{userData.superAdmin?.count}</h3>
                        <p>Super Admin</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add" />
                      </div>
                      <Link className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ) : null}

                {userData.sub_admin && accountType !== "sub_admin" ? (
                  <div className="col-6">
                    <div
                      className="small-box bg-green"
                      onClick={() => handleNavigation("/sub-admin")}
                      data-bs-dismiss="modal"
                    >
                      <div className="inner">
                        <h3>{userData.sub_admin?.count}</h3>
                        <p>Sub Admin</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add" />
                      </div>
                      <Link className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ) : null}

                {userData.master && accountType !== "master" ? (
                  <div className="col-6">
                    <div
                      className="small-box bg-yellow"
                      onClick={() => handleNavigation("/masters")}
                      data-bs-dismiss="modal"
                    >
                      <div className="inner">
                        <h3>{userData.master?.count}</h3>
                        <p>Master</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add" />
                      </div>
                      <Link className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ) : null}

                {userData.super_agent && accountType !== "super_agent" ? (
                  <div className="col-6">
                    <div
                      className="small-box bg-red"
                      data-bs-dismiss="modal"
                      onClick={() => handleNavigation("/superagents")}
                    >
                      <div className="inner">
                        <h3>{userData.super_agent?.count}</h3>
                        <p>Super Agent</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add" />
                      </div>
                      <Link className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ) : null}

                {userData.agent && accountType !== "agent" ? (
                  <div className="col-6">
                    <div
                      className="small-box bg-primary"
                      data-bs-dismiss="modal"
                      onClick={() => handleNavigation("/agent")}
                    >
                      <div className="inner">
                        <h3>{userData.agent?.count}</h3>
                        <p>Agent</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add" />
                      </div>
                      <Link className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ) : null}

                {userData.user_account && accountType !== "user" ? (
                  <div className="col-6">
                    <div
                      className="small-box bg-green"
                      data-bs-dismiss="modal"
                      onClick={() => handleNavigation("/client")}
                    >
                      <div className="inner">
                        <h3>{userData.user_account?.count}</h3>
                        <p>Client</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-person-add" />
                      </div>
                      <Link className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ) : null}
              </div>
            ) : (
              <div>Loading...</div>
            )}
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
              data-bs-target="#userModal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserModal;
