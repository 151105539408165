import { useQuery } from "react-query";
import { createProtectedAxios } from "../../lib/auth";

const BASE_URL = process.env.REACT_APP_API_URL;

async function fetcher() {
  const axios = createProtectedAxios();
  const accType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");

  const type = "completed";

  const endpoint = `${BASE_URL}/game/matchesDataList/${type}/${accType}/${userId}`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetCompleteMatch() {
  const { data, isLoading } = useQuery(
    ["get-complete-match"],
    () => fetcher(),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { data, isLoading };
}
