import React from "react";
import { Link } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { useState, useEffect } from "react";
import SuperSidebar from "../Components/SiderbarAllowFeatures/SuperAgent";
import axios from "axios";

export default function AddMatchDescription() {
  const [data, setData] = React.useState([]);
  let token = localStorage.getItem("token");
  let header = { "x-access-token": `${token}` };
  let options = { headers: header };
  useEffect(() => {
    getListInplayMatch();
  }, []);
  function getListInplayMatch() {
    axios.get(`${process.env.REACT_APP_API_URL}owner/inplay-matches`, options).then(async (resp) => {
      console.log(resp.data.matches);
      setData(resp.data.matches);
    });
  }
  return (
    <>
      <SuperSidebar />
      <div className="content-wrapper">
        <div className="container-fluid content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 ml-2">
                <i class="fa fa-home"></i> All Matches Description
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active"> All Matches</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>S.No </th>
                        <th>Match  Name </th>
                        <th>Code</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data.map((e, i) => {
                        return (
                          <tr>
                            <td>{i + 1}</td>
                            <td>
                              <Link to="">
                                {e.match_name}
                              </Link>{" "}
                            </td>
                            <td>{e.game_id}</td>
                            <td>
                              <Link
                                to={{
                                  pathname: "/super-admin/add-match-description/EditMatchDesctiption",
                                }}
                                state={{
                                  item: e.game_id,
                                }}
                                class="btn btn-warning" type="button">
                                Add Description
                              </Link>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {/* ================modal code ================= */}
      <div class="modal fade" id="DiscriptionModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h1 class="modal-title fs-5" id="exampleModalLabel">New message</h1>
              <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
            </div>
            <div class="modal-body">
              <form>
                <div class="mb-3">
                  <label for="message-text" class="col-form-label">Message:</label>
                  <textarea class="form-control" id="message-text"></textarea>
                </div>
              </form>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              <button type="button" class="btn btn-primary">Add Message</button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
