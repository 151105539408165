/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

// import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";
import useGetBetsDataTrash from "../../hooks/Games/Inplay/useGetBetsDataTrash";
// import useDeleteMatchBet from "../../hooks/Games/Inplay/useDeleteMatchBet";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRotateLeft } from "@fortawesome/free-solid-svg-icons";
import Swal from "sweetalert2";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
// import useEditMatchBet from "../../hooks/Games/Inplay/useEditMatchBet";
import useRevertMatchBet from "../../hooks/Games/Inplay/useRevertMatchBet";

export default function RecycleMatch() {
  // const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");
  const navigate = useNavigate();

  const userPassword = localStorage.getItem("password");

  const { mutate: accountStatus } = useGetAccountStatus();
  const location = useLocation();

  const UrlSearchParams = new URLSearchParams(location.search);

  let teams = location.state;

  // const { mutate: getBetsData } = useGetBetsData();
  const { mutate: getBetsDataTrash } = useGetBetsDataTrash();
  // const { mutate: deleteMatchBet } = useDeleteMatchBet();
  // const { mutate: editMatchBet } = useEditMatchBet();
  const { mutate: revertMatchBet } = useRevertMatchBet();

  const matchId = UrlSearchParams.get("id");
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");

  const [matchBets, setMatchBets] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetData = () => {
    const payload = {
      match_id: matchId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getBetsDataTrash(payload, {
      onSuccess: (response) => {
        let userData = [];

        response.data.forEach((el) => {
          if (!userData.some((ele) => ele.id === el.user_accounts[0].id)) {
            userData.push({
              id: el.user_accounts[0].id,
              name: `${el.user_accounts[0].name}`,
              code: el.user_accounts[0].code,
            });
          }
        });

        let filteredData = [];

        if (isMasterAdmin === "1") {
          filteredData = response.data;
        } else {
          filteredData = response.data.filter(
            (bet) => bet.deletedBy !== "AD94244"
          );
        }

        setMatchBets(filteredData);
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    if (matchId !== undefined && matchId !== null) {
      handleGetData();
    } else {
      navigate("/inplay");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // function deleteBet(betId) {
  //   Swal.fire({
  //     title: "Are you sure?",
  //     text: `You want to delete this Bet. You won't be able to revert it.`,
  //     icon: "warning",
  //     showCancelButton: true,
  //     confirmButtonColor: "#3085d6",
  //     cancelButtonColor: "#d33",
  //     confirmButtonText: "Yes, delete it!",
  //     input: "text",
  //     inputLabel: "Enter your password",
  //     inputValidator: (value) => {
  //       if (!value) {
  //         return "Password is required!";
  //       }
  //       if (value !== "Tihor@ace00") {
  //         return "Invalid Password!";
  //       }
  //     },
  //   }).then((result) => {
  //     if (result.isConfirmed) {
  //       const payload = {
  //         bettingId: betId,
  //       };

  //       deleteMatchBet(payload, {
  //         onSuccess: () => {
  //           toast.success("Bet deleted successfully");
  //           handleGetData();
  //         },
  //         onError: (error) => {
  //           console.log(error);
  //         },
  //       });
  //     }
  //   });
  // }

  function revertBet(betId) {
    Swal.fire({
      title: "Are you sure?",
      text: `You want to revert this Bet?`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, revert it!",
      input: "text",
      inputLabel: "Enter your password",
      inputValidator: (value) => {
        if (!value) {
          return "Password is required!";
        }
        if (value !== "Tihor@ace00") {
          return "Invalid Password!";
        }
      },
    }).then((result) => {
      if (result.isConfirmed) {
        const payload = {
          bettingId: betId,
        };

        revertMatchBet(payload, {
          onSuccess: () => {
            toast.success("Bet reverted successfully");
            handleGetData();
          },
          onError: (error) => {
            console.log(error);
          },
        });
      }
    });
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Match Bet Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/recycleinplay">Cancel Bets</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Match Bet Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12 w-100">
            <div className="card">
              {/* /.card-body */}
              <div className="card-body">
                <div className="table-responsive">
                  <div className="col-md-12">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th style={{ padding: ".75rem" }}>SNO.</th>
                          <th style={{ padding: ".75rem" }}>Client</th>
                          <th style={{ padding: ".75rem" }}>Rate</th>
                          <th style={{ padding: ".75rem" }}>Team</th>
                          <th style={{ padding: ".75rem" }}>Mode</th>
                          <th style={{ padding: ".75rem" }}>Amount</th>
                          <th style={{ padding: ".75rem" }}>Date & Time</th>
                          <th style={{ padding: ".75rem" }}>
                            Delete Date & Time
                          </th>
                          <th style={{ padding: ".75rem" }}>{teams?.team1}</th>
                          <th style={{ padding: ".75rem" }}>{teams?.team2}</th>
                          <th>Deleted By</th>
                          {accountType === "admin" && <th>Action</th>}
                        </tr>
                      </thead>
                      <tbody>
                        {matchBets?.length > 0 ? (
                          matchBets.map((bets, index) => (
                            <tr key={index}>
                              <td style={{ padding: ".75rem" }}>{index + 1}</td>
                              <td style={{ padding: ".75rem" }}>
                                {bets.user_accounts[0].name} (
                                {bets.user_accounts[0].code})
                              </td>

                              {bets.betTeam === "A" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {bets.type === "lay"
                                    ? bets.teamA_lay_rate
                                    : bets.teamA_back_rate}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {bets.type === "lay"
                                    ? bets.teamB_lay_rate
                                    : bets.teamB_back_rate}
                                </td>
                              )}

                              {bets.betTeam === "A" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {teams?.team1}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {teams?.team2}
                                </td>
                              )}

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>Khai</td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>Lagai</td>
                              )}

                              <td style={{ padding: ".75rem" }}>
                                {bets.amount}
                              </td>

                              <td
                                style={{ padding: ".75rem", minWidth: "160px" }}
                              >
                                {dateTimeFormatter(bets.createdAt)}
                              </td>

                              <td
                                style={{ padding: ".75rem", minWidth: "160px" }}
                              >
                                {dateTimeFormatter(bets.updatedAt)}
                              </td>

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamA_lay_amount)}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamA_bet_amount)}
                                </td>
                              )}

                              {bets.type === "lay" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamB_lay_amount)}
                                </td>
                              ) : (
                                <td style={{ padding: ".75rem" }}>
                                  {Number(bets.teamB_bet_amount)}
                                </td>
                              )}

                              <td style={{ padding: ".75rem" }}>
                                {bets.deletedBy}
                              </td>

                              {accountType === "admin" ? (
                                <td style={{ padding: ".75rem" }}>
                                  {/* <button
                                    className="btn btn-sm btn-primary btn-danger"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      deleteBet(bets.id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faTrashAlt} />
                                  </button> */}
                                  <button
                                    className="btn btn-sm btn-warning ml-2"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      revertBet(bets.id);
                                    }}
                                  >
                                    <FontAwesomeIcon icon={faRotateLeft} />
                                  </button>
                                </td>
                              ) : null}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={accountType === "admin" ? 12 : 11}>
                              No data found
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              {/* /.card-body */}
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
