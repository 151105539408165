import React from "react";

export default function AgentCardCassino({ data }) {
  console.log(data);
  return data.map((superAdmin, index) =>
    superAdmin.sub_admins.map((sub_admin, index) =>
      sub_admin.masters.map((master, index) =>
        master.super_agents.map((super_agent, index) =>
          super_agent.agents.map((agent, index) => (
            <div className="card" key={index}>
              <div
                className="card-header bg-primary py-2 text-white mb-3"
                style={{
                  lineHeight: 1.5,
                  height: "44px",
                }}
              >
                AGENT NAME : {agent.agent}
              </div>
              <div className="card-body">
                <div className="table">
                  <table
                    className="table table-bordered table-striped mb-0 longTable"
                    style={{
                      lineHeight: 1.5,
                    }}
                  >
                    <thead>
                      <tr>
                        <th colSpan="5">Client Plus/Minus</th>
                        <th colSpan="4">Agent</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th>Client</th>
                        <th>Match Amt</th>
                        <th>Cassino Com</th>
                        <th>Net Amt</th>
                        <th>Final</th>
                        <th>Cassino Com</th>
                        <th>Net Amt</th>
                        <th>Shr Amt</th>
                        <th>Final</th>
                      </tr>

                      {agent.users.map((user, index) => (
                        <tr key={index}>
                          <td>{user.user || "Total"}</td>
                          <td>{user.match_amt}</td>
                          <td>{user.cassino_com}</td>
                          <td>{user.net_amt}</td>
                          <td>{user.final_amt}</td>
                          <td>{user.agent_cassino_com}</td>
                          <td>{user.agent_net_amt}</td>
                          <td>{user.agent_shr_amt}</td>
                          <td>{user.agent_final_amt}</td>
                        </tr>
                      ))}

                      <tr className="fw-bold">
                        <td>Total</td>
                        <td>{agent.agent_total.match_amt.toFixed(2)}</td>
                        <td>{agent.agent_total.cassino_com.toFixed(2)}</td>
                        <td>{agent.agent_total.net_amt.toFixed(2)}</td>
                        <td>{agent.agent_total.final_amt.toFixed(2)}</td>
                        <td>
                          {agent.agent_total.agent_cassino_com.toFixed(2)}
                        </td>
                        <td>{agent.agent_total.agent_net_amt.toFixed(2)}</td>
                        <td>{agent.agent_total.agent_shr_amt.toFixed(2)}</td>
                        <td>{agent.agent_total.agent_final_amt.toFixed(2)}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          ))
        )
      )
    )
  );
}
