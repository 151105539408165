import { useQuery } from "react-query";
import { createProtectedAxios } from "../../lib/auth";
import { decryptData } from "../../utils/decryptData";

const BASE_URL = process.env.REACT_APP_API_URL;

async function fetcher() {
  const axios = createProtectedAxios();

  const acc_type = "super_admin";
  const parent_user_id = decryptData(localStorage.getItem("UUID"));
  const logged_acc_type = decryptData(localStorage.getItem("account"));

  const endpoint = `${BASE_URL}/users/user_list/${acc_type}/${parent_user_id}/${logged_acc_type}`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetSuperAdminList() {
  const { data, isLoading } = useQuery(
    ["get-super-admin-list"],
    () => fetcher(),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { data, isLoading };
}
