import React from "react";
import { useNavigate } from "react-router-dom";
import "./Profile.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faIdCard,
  faCoins,
  faPencil,
  faFile,
} from "@fortawesome/free-solid-svg-icons";
import user2image from "../img/user2-160x160.jpg";
import PageHeader from "../Components/PageHeader";
import { dateFormatter } from "../utils/dateFormatter";

export default function Profile() {
  const navigate = useNavigate();

  const userData = JSON.parse(localStorage.getItem("DATA"));
  const userAccount = localStorage.getItem("account").replace("_", " ");

  const handleChangePassword = () => {
    navigate("/change-password");
  };

  return (
    <div className="content-wrapper">
      <PageHeader title="Profile" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12">
            <div className="card upperCard">
              {" "}
              <div className="container-fluid">
                <div className="px-1 py-3 d-flex flex-column justify-content-center align-items-center">
                  <img
                    src={user2image}
                    className="img-circle elevation-1 my-1"
                    alt="User Profile"
                    style={{ width: "80px", height: "80px" }}
                  />
                  <h3>
                    {userData ? userData.code : "Code"}{" "}
                    {userData ? userData.name : "Name"}
                  </h3>
                  <p style={{ textTransform: "capitalize" }}>
                    {userAccount ? userAccount : "Agent"}
                  </p>
                  <table className="table">
                    <tbody>
                      <tr>
                        <td className="py-3" colSpan={9}>
                          Share
                        </td>
                        <td
                          className="py-3"
                          colSpan={3}
                          style={{ textAlign: "right" }}
                        >
                          {userData ? userData.share : "0"}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-3" colSpan={9}>
                          Match Commission
                        </td>
                        <td
                          className="py-3"
                          colSpan={3}
                          style={{ textAlign: "right" }}
                        >
                          {userData ? userData.match_commission : "0"}
                        </td>
                      </tr>
                      <tr>
                        <td className="py-3" colSpan={9}>
                          Session Commission
                        </td>
                        <td
                          className="py-3"
                          colSpan={3}
                          style={{ textAlign: "right" }}
                        >
                          {userData ? userData.session_commission : "0"}
                        </td>
                      </tr>
                      <tr></tr>
                    </tbody>
                  </table>
                  <button
                    type="button"
                    className="btn btn-primary w-100"
                    onClick={handleChangePassword}
                  >
                    Change Password
                  </button>
                </div>
              </div>
            </div>

            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header" id="headingOne">
                  <button
                    className="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#collapseOne"
                    aria-expanded="true"
                    aria-controls="collapseOne"
                  >
                    General
                  </button>
                </h2>
                <div
                  id="collapseOne"
                  className="accordion-collapse collapse show"
                  aria-labelledby="headingOne"
                  data-bs-parent="#accordionExample"
                >
                  <div className="accordion-body px-3 py-3">
                    <div className="pb-3 w-100">
                      <FontAwesomeIcon icon={faIdCard} /> Profile
                      <br />
                      {userData ? userData.code : "Code"}{" "}
                      {userData ? userData.name : "Name"}
                    </div>
                    <hr className="my-0" />
                    <div className="py-3 w-100">
                      <FontAwesomeIcon icon={faCoins} /> Current Limit
                      <br />
                      {userData ? userData.limit : 0}
                    </div>
                    <hr className="my-0" />
                    <div className="py-3 w-100">
                      <FontAwesomeIcon icon={faPencil} /> Contact No.
                      <br />
                      {userData ? userData.mobile : "123456789"}
                    </div>
                    <hr className="my-0" />
                    <div className="py-3 w-100">
                      <FontAwesomeIcon icon={faFile} /> Date of Joining
                      <br />
                      {userData
                        ? dateFormatter(userData.createdAt)
                        : "DD/MM/YYYY"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
