/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
// import useGetMyLedger from "../../hooks/useGetMyLedger";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useGetCashData from "../../hooks/useGetCashData";
import { dateTimeFormatter } from "../../utils/dateFormatter";
import { roundToTwoDecimals } from "../../utils/roundToTwoDecimals";

export default function MyLedger() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");

  const navigate = useNavigate();

  const { mutate: accountStatus } = useGetAccountStatus();
  // const { mutate: getMyLedger } = useGetMyLedger();
  const { mutate: getCashData } = useGetCashData();

  const [data, setData] = useState([]);
  const [totalDebit, setTotalDebit] = useState(0);
  const [totalCredit, setTotalCredit] = useState(0);
  const [totalBalance, setTotalBalance] = useState(0);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
      logged_acc_type: accountType,
    };

    getCashData(payload, {
      onSuccess: (response) => {
        let updatedCashData = [];

        response.data.forEach((element) => {
          if (accountType === "super_admin") {
            element.total_final = element.super_admin_final;
          } else if (accountType === "sub_admin") {
            element.total_final = element.sub_admin_final;
          } else if (accountType === "master") {
            element.total_final = element.master_final;
          } else if (accountType === "super_agent") {
            element.total_final = element.super_agent_final;
          } else if (accountType === "agent") {
            element.total_final = element.agent_final;
          } else {
            element.total_final = 0;
          }
        });

        response.data.forEach((element) => {
          if (element.game != null) {
            const existingIndex = updatedCashData.findIndex(
              (el) => el.match_id === element.match_id
            );

            if (existingIndex !== -1) {
              if (element.total_final !== 0) {
                updatedCashData[existingIndex] = {
                  ...element,
                  balance:
                    updatedCashData[existingIndex].balance +
                    roundToTwoDecimals(element.total_final),
                  totalBalance: 0,
                };
              } else {
                updatedCashData[existingIndex] = {
                  ...element,
                  balance: updatedCashData[existingIndex].balance,
                  totalBalance: 0,
                };
              }
            } else {
              if (element.total_final !== 0) {
                updatedCashData.push({
                  ...element,
                  balance: roundToTwoDecimals(element.total_final),
                  totalBalance: 0,
                });
              } else {
                updatedCashData.push({
                  ...element,
                  balance: 0,
                  totalBalance: 0,
                });
              }
            }
          } else if (element.cassino_game != null) {
            const existingIndex = updatedCashData.findIndex(
              (el) => el.remarks === element.remarks
            );

            if (existingIndex !== -1) {
              if (element.total_final !== 0) {
                updatedCashData[existingIndex] = {
                  ...element,
                  balance:
                    updatedCashData[existingIndex].balance +
                    roundToTwoDecimals(element.total_final),
                  tableBalance: 0,
                };
              } else {
                updatedCashData[existingIndex] = {
                  ...element,
                  balance: updatedCashData[existingIndex].balance,
                  tableBalance: 0,
                };
              }
            } else {
              if (element.total_final !== 0) {
                updatedCashData.push({
                  ...element,
                  balance: roundToTwoDecimals(element.total_final),
                  tableBalance: 0,
                });
              } else {
                updatedCashData.push({
                  ...element,
                  balance: 0,
                  tableBalance: 0,
                });
              }
            }
          } else {
            updatedCashData.push({
              ...element,
              balance: roundToTwoDecimals(element.total_final),
              totalBalance: 0,
            });
          }
        });

        updatedCashData?.sort((a, b) => {
          return (
            new Date(b.game?.updatedAt || b.updatedAt) -
            new Date(a.game?.updatedAt || a.updatedAt)
          );
        });

        updatedCashData.reverse();

        for (let index = 0; index < updatedCashData.length; index++) {
          if (index === 0) {
            updatedCashData[index].tableBalance = roundToTwoDecimals(
              updatedCashData[index].balance
            );
          } else {
            updatedCashData[index].tableBalance =
              updatedCashData[index - 1].tableBalance +
              roundToTwoDecimals(updatedCashData[index].balance);
          }
        }

        updatedCashData.reverse();

        let totalAmount = 0;
        let debitAmount = 0;
        let creditAmount = 0;

        updatedCashData.forEach((element) => {
          if (element.balance > 0) {
            debitAmount += element.balance;
          } else if (element.balance < 0) {
            creditAmount += element.balance;
          } else {
            debitAmount += 0;
            creditAmount += 0;
          }
        }, 0);

        totalAmount = creditAmount + debitAmount;

        setTotalDebit(roundToTwoDecimals(debitAmount));
        setTotalCredit(roundToTwoDecimals(creditAmount));
        setTotalBalance(roundToTwoDecimals(totalAmount));

        setData(updatedCashData);
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>My Ledger</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>My Ledger</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      <section className="content">
        <div className="container-fluid">
          <div className="card p-2">
            <div className="table-responsive">
              <table
                id="datatable"
                className="table table-bordered table-striped"
              >
                <thead>
                  <tr>
                    <th style={{ minWidth: "50px", height: "57px" }}>#</th>
                    <th style={{ minWidth: "80px", height: "57px" }}>Date</th>
                    <th style={{ minWidth: "123px", height: "57px" }}>
                      Collection Name
                    </th>
                    <th style={{ minWidth: "58px", height: "57px" }}>Debit</th>
                    <th style={{ minWidth: "59px", height: "57px" }}>Credit</th>
                    <th style={{ minWidth: "69px", height: "57px" }}>
                      Balance
                    </th>
                    <th style={{ minWidth: "110px", height: "57px" }}>
                      Payment Type
                    </th>
                    <th style={{ minWidth: "363px", height: "57px" }}>
                      Remark
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data.length !== 0 ? (
                    <>
                      <tr className="fw-bold">
                        <td
                          style={{ height: "40px" }}
                          colSpan="3"
                          className={`text-center text-lg fw-bold ${
                            totalBalance > 0 ? "text-danger" : "text-primary"
                          }`}
                        >
                          Total Amount
                        </td>

                        <td className="text-black text-lg fw-bold">
                          {totalCredit}
                        </td>
                        <td className="text-black text-lg fw-bold">
                          {totalDebit}
                        </td>
                        <td
                          style={{ height: "55px" }}
                          className={
                            totalBalance > 0
                              ? "text-danger fw-bold text-lg"
                              : "text-primary fw-bold text-lg"
                          }
                        >
                          {totalBalance}
                        </td>
                        <td></td>
                        <td></td>
                      </tr>
                      {data.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td style={{ height: "60px" }}>{index + 1}</td>
                            <td>
                              {item.game
                                ? dateTimeFormatter(item.game.updatedAt)
                                : dateTimeFormatter(item.updatedAt)}
                            </td>
                            <td>
                              {item.game
                                ? item.game.name
                                : item.cassino_game
                                ? "Cassino"
                                : "Cash Pay"}
                            </td>

                            <td className="text-black">
                              {item.balance < 0 && item.balance !== 0
                                ? roundToTwoDecimals(item.balance)
                                : 0}
                            </td>
                            <td className="text-black">
                              {item.balance > 0 && item.balance !== 0
                                ? roundToTwoDecimals(item.balance)
                                : 0}
                            </td>
                            <td
                              style={{ height: "60px" }}
                              className="text-black"
                            >
                              {roundToTwoDecimals(item.tableBalance)}
                            </td>
                            <td>
                              {item.game || item.cassino_game
                                ? ""
                                : item.balance < 0
                                ? "DENA"
                                : "LENA"}
                            </td>
                            <td>
                              {item.game ? item.game.name : `${item.remarks}`}
                            </td>
                          </tr>
                        );
                      })}
                    </>
                  ) : (
                    <tr>
                      <td colSpan="8" className="text-center">
                        No User Found
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
