import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useCreateAccount from "../../hooks/useCreateAccount";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import useGetAdminAccount from "../../hooks/useGetAdminAccount";

export default function CreateNewCollection() {
  const navigate = useNavigate();

  const { data: adminData } = useGetAdminAccount();
  const { mutate: createAccount } = useCreateAccount();
  const { mutate: updateLimit } = useUpdateLimit();
  const [data, setData] = useState([]);
  const [superAdminName, setSuperAdminName] = useState("");
  const [superAdminNumber, setSuperAdminNumber] = useState("");
  const [superAdminPassword, setSuperAdminPassword] = useState("");
  const [superAdminReference, setSuperAdminReference] = useState("");
  const [superAdminLimit, setSuperAdminLimit] = useState(0);
  const [superAdminShare, setSuperAdminShare] = useState(0);
  const [superAdminCasinoShare, setSuperAdminCasinoShare] = useState(0);
  const [superAdminMobileShare, setSuperAdminMobileShare] = useState(0);
  const [superAdminCommission, setSuperAdminCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [superAdminMatchCommission, setSuperAdminMatchCommission] = useState(0);
  const [superAdminSessionCommission, setSuperAdminSessionCommission] =
    useState(0);
  const [superAdminCassinoCommission, setSuperAdminCassinoCommission] =
    useState(0);

  useEffect(() => {
    setData(adminData?.data.data[0]);
  }, [adminData]);

  useEffect(() => {
    setSuperAdminPassword(passwordGenerator());
  }, []);

  const generateNewPassword = () => {
    setSuperAdminPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    const payload = {
      acc_type: "super_admin",
      name: superAdminName,
      reference: superAdminReference,
      password: String(superAdminPassword),
      parent: data?.id,
      mobile: superAdminNumber,
      limit: superAdminLimit,
      share: superAdminShare,
      cassino_share: superAdminCasinoShare,
      mobile_share: superAdminMobileShare,
      commission: superAdminCommission,
      match_commission: superAdminMatchCommission,
      session_commission: superAdminSessionCommission,
      cassino_commission: superAdminCassinoCommission,
    };

    createAccount(payload, {
      onSuccess: (data) => {
        console.log(data);
        const superAdminLimitPayload = {
          user_id: data.data.data.id,
          acc_type: "super_admin",
          limit: superAdminLimit,
          coins: superAdminLimit,
          types: "credit",
          description: "Super Admin Limit Update",
        };
        const parentLimitPayload = {
          user_id: data.id,
          acc_type: "admin",
          limit: data.limit - superAdminLimit,
          coins: superAdminLimit,
          types: "debit",
          description: "Admin Limit Update",
        };
        updateLimit(parentLimitPayload, {
          onSuccess: () => {
            updateLimit(superAdminLimitPayload, {
              onSuccess: () => {
                navigate("/collection-list");
                toast.success("Super Admin created successfully!");
              },
              onError: (error) => {
                console.error(error);
              },
            });
          },
          onError: (error) => {
            console.error(error);
          },
        });
      },
      onError: (error) => {
        toast.error("Something went wrong! Please try again later.");
        console.error(error);
      },
    });
  }

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Collection Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard" href="#">
                    Home
                  </Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/collection-list">Collectiom</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Create New Collection</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExampleOne">
                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button"
                        id="create-button-1"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse show"
                      data-bs-parent="#accordionExampleOne"
                      aria-labelledby="headingOne"
                    >
                      <div className="accordion-body">
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAdminCode"
                          >
                            Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="superAdminCode"
                            name="superAdminCode"
                            value="Auto generated"
                            disabled
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="superAdminName"
                            name="superAdminName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              setSuperAdminName(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="superAdminReference"
                            name="superAdminReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            onChange={(e) => {
                              setSuperAdminReference(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAdminNumber"
                          >
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="superAdminNumber"
                            name="superAdminNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={10}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setSuperAdminNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAdminNumber"
                          >
                            Account Number
                          </label>
                          <input
                            type="text"
                            id="superAdminNumber"
                            name="superAdminNumber"
                            className="form-control"
                            placeholder=" Account Number"
                            minLength={10}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setSuperAdminNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2 col-md-12">
                          <label className="form-label">Collectiom Type</label>
                          <select className="form-control">
                            <option value="off">BANK</option>
                            <option value="on">COLLECTION</option>
                          </select>
                        </div>
                        <div className="d-flex w-100 mt-4 col-12 justify-content-between">
                          <button
                            type="button"
                            className="btn btn-secondary"
                            onClick={() => {
                              navigate("/collection-list");
                            }}
                          >
                            Cancel
                          </button>
                          <button type="submit" className="btn btn-success">
                            Create New Super Admin
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}

              {/* Action Buttons */}
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
