import { useMutation } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";
import { encryptData } from "../../../utils/encryptData";

const BASE_URL = process.env.REACT_APP_API_URL;

async function mutationFn(gameId, payload) {
  const axios = createProtectedAxios();
  const endpoint = `${BASE_URL}/game/setting/${gameId}`;

  let encryptedPayload = encryptData(payload);
  let payloadData = { data: encryptedPayload };

  return axios.put(endpoint, payloadData).then(({ data }) => data);
}

export default function useUpdateMatchSetting() {
  const { mutate, isLoading, data } = useMutation(
    ["update-match-setting"],
    ({ gameId, payload }) => mutationFn(gameId, payload),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
