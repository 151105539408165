// export const passwordGenerator = () => {
//   const length = 6;
//   const charset = "0123456789";
//   let retVal = "";

//   for (let i = 0, n = charset.length; i < length; ++i) {
//     retVal += charset.charAt(Math.floor(Math.random() * n));
//   }
//   return retVal;
// };

export const passwordGenerator = () => {
  let password = Math.floor(Math.random() * 899999 + 100000);

  return password;
};
