import React from "react";
import "../CompleteSubPages.css";

const AdminCard = ({ finalReport, userCode, userName }) => {
  const sumKey = (arr, key) => {
    // Check if arr is a valid array
    if (!Array.isArray(arr)) {
      console.error("Expected an array, but got:", arr);
      return 0; // Return 0 if arr is not an array
    }

    return arr
      .reduce((a, b) => {
        const value = parseFloat(b[key]) || 0; // Ensure the value is a number, default to 0
        return a + value;
      }, 0)
      .toFixed(2); // Return the sum, fixed to 2 decimal places
  };

  return (
    <div className="card">
      <div
        className="card-header bg-dark py-2 text-white"
        style={{ height: "44px" }}
      >
        ADMIN : ({userCode}) {userName}
      </div>
      <div className="card-body bg-gray-light pt-4 pb-0">
        {finalReport.map((superAdmin, index) => (
          <div className="card" key={index}>
            <div
              className="card-header bg-danger py-2 text-white"
              style={{ height: "44px" }}
            >
              SUPER ADMIN : {superAdmin.name}
            </div>
            <div className="card-body pt-4 pb-0">
              {superAdmin.sub_admin.map((sub_admin, index) => (
                <div className="card" key={index}>
                  <div
                    className="card-header bg-green py-2 text-white"
                    style={{ height: "44px" }}
                  >
                    SUBADMIN NAME : {sub_admin.name}
                  </div>
                  <div className="card-body bg-gray-light pt-4 pb-0">
                    {sub_admin.master.map((master, index) => (
                      <div className="card" key={index}>
                        <div
                          className="card-header bg-warning py-2 text-white"
                          style={{ height: "44px" }}
                        >
                          MASTER NAME : {master.name}
                        </div>
                        <div className="card-body pt-4 pb-0">
                          {master.super_agent.map((super_agent, index) => (
                            <div className="card" key={index}>
                              <div
                                className="card-header bg-purple py-2 text-white"
                                style={{ height: "44px" }}
                              >
                                SUPERAGENT NAME : {super_agent.name}
                              </div>
                              <div className="card-body bg-gray-light pt-4 pb-0">
                                {super_agent.agent.map((agent, index) => (
                                  <div className="card" key={index}>
                                    <div
                                      className="card-header bg-primary py-2 text-white mb-3"
                                      style={{
                                        lineHeight: 1.5,
                                        height: "44px",
                                      }}
                                    >
                                      AGENT NAME : {agent.name}
                                    </div>
                                    <div className="card-body">
                                      <div className="table">
                                        <table
                                          className="table table-bordered table-striped mb-0 longTable"
                                          style={{
                                            lineHeight: 1.5,
                                          }}
                                        >
                                          <thead>
                                            <tr>
                                              <th colSpan="11">Agent</th>
                                              <th colSpan="7">Super Agent</th>
                                              <th colSpan="7">Master</th>
                                              <th colSpan="7">Sub Admin</th>
                                              <th colSpan="7">Super Admin</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            <tr>
                                              <th>Client</th>
                                              <th>M Amt</th>
                                              <th>S Amt</th>
                                              <th>T Amt</th>
                                              {/* <th>
                                                                    M Comm
                                                                  </th>
                                                                  <th>
                                                                    S Comm
                                                                  </th>
                                                                  <th>
                                                                    T Comm
                                                                  </th>
                                                                  <th>
                                                                    Net Amt
                                                                  </th>
                                                                  {/* <th>C Mob</th> 
                                                                  <th>Final</th> */}
                                              <th>M Com</th>
                                              <th>S Com</th>
                                              <th>T Com</th>
                                              <th>Net Amt</th>
                                              <th>Shr Amt</th>
                                              <th>Mob App</th>
                                              <th>Final</th>
                                              <th>M Com</th>
                                              <th>S Com</th>
                                              <th>T Com</th>
                                              <th>Net Amt</th>
                                              <th>Shr Amt</th>
                                              <th>Mob App</th>
                                              <th>Final</th>
                                              <th>M Com</th>
                                              <th>S Com</th>
                                              <th>T Com</th>
                                              <th>Net Amt</th>
                                              <th>Shr Amt</th>
                                              <th>Mob App</th>
                                              <th>Final</th>
                                              <th>M Com</th>
                                              <th>S Com</th>
                                              <th>T Com</th>
                                              <th>Net Amt</th>
                                              <th>Shr Amt</th>
                                              <th>Mob App</th>
                                              <th>Final</th>
                                              <th>M Com</th>
                                              <th>S Com</th>
                                              <th>T Com</th>
                                              <th>Net Amt</th>
                                              <th>Shr Amt</th>
                                              <th>Mob App</th>
                                              <th>Final</th>
                                            </tr>

                                            {agent.client.map(
                                              (match, index) => (
                                                <tr key={index}>
                                                  <td>{match.client}</td>
                                                  <td>
                                                    {match.m_amt.toFixed(2)}
                                                  </td>
                                                  <td>
                                                    {match.s_amt.toFixed(2)}
                                                  </td>
                                                  <td>
                                                    {match.t_amt.toFixed(2)}
                                                  </td>
                                                  {/* <td>
                                                                        {match.m_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.s_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.c_com.toFixed(
                                                                          2
                                                                        )}
                                                                      </td>
                                                                      <td>
                                                                        {match.net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td> */}

                                                  {/*<td>
                                                                    {match.c_mob.toFixed(
                                                                      2
                                                                    )}
                                                                  </td>*/}
                                                  {/* <td>
                                                                        {match.net_amt.toFixed(
                                                                          2
                                                                        )}
                                                                      </td> */}

                                                  <td>
                                                    {match.agent_m_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.agent_s_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.agent_t_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.agent_net_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.agent_shr_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.agent_mob_app.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.agent_final.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_agent_m_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_agent_s_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_agent_t_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_agent_net_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_agent_shr_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_agent_mob_app.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_agent_final.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.master_m_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.master_s_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.master_t_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.master_net_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.master_shr_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.master_mob_app.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.master_final.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.sub_admin_m_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.sub_admin_s_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.sub_admin_t_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.sub_admin_net_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.sub_admin_shr_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.sub_admin_mob_app.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.sub_admin_final.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_admin_m_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_admin_s_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_admin_t_com.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_admin_net_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_admin_shr_amt.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_admin_mob_app.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                  <td>
                                                    {match.super_admin_final.toFixed(
                                                      2
                                                    )}
                                                  </td>
                                                </tr>
                                              )
                                            )}

                                            <tr>
                                              <td>Total</td>
                                              <td>
                                                {sumKey(agent.client, "m_amt")}
                                              </td>
                                              <td>
                                                {sumKey(agent.client, "s_amt")}
                                              </td>
                                              <td>
                                                {sumKey(agent.client, "t_amt")}
                                              </td>
                                              {/* <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "m_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "s_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "c_com"
                                                                    )}
                                                                  </td>
                                                                  <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "net_amt"
                                                                    )}
                                                                  </td> */}
                                              {/* <td>
                                                                {sumKey(
                                                                  agent.client,
                                                                  "c_mob"
                                                                )}
                                                              </td> */}
                                              {/* <td>
                                                                    {sumKey(
                                                                      agent.client,
                                                                      "net_amt"
                                                                    )}
                                                                  </td> */}
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "agent_m_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "agent_s_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "agent_t_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "agent_net_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "agent_shr_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "agent_mob_app"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "agent_final"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_agent_m_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_agent_s_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_agent_t_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_agent_net_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_agent_shr_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_agent_mob_app"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_agent_final"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "master_m_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "master_s_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "master_t_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "master_net_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "master_shr_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "master_mob_app"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "master_final"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "sub_admin_m_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "sub_admin_s_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "sub_admin_t_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "sub_admin_net_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "sub_admin_shr_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "sub_admin_mob_app"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "sub_admin_final"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_admin_m_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_admin_s_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_admin_t_com"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_admin_net_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_admin_shr_amt"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_admin_mob_app"
                                                )}
                                              </td>
                                              <td>
                                                {sumKey(
                                                  agent.client,
                                                  "super_admin_final"
                                                )}
                                              </td>
                                            </tr>
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                ))}
                              </div>

                              <div className="card-body bg-gray-light pt-1">
                                <table className="table table-bordered bg-white">
                                  <tbody>
                                    <tr
                                      style={{
                                        fontWeight: "bold",
                                      }}
                                    >
                                      <td>Super Agent Grand Total</td>
                                      <td>
                                        {super_agent.totals.m_amt.toFixed(2)}
                                      </td>
                                      <td>
                                        {super_agent.totals.s_amt.toFixed(2)}
                                      </td>
                                      <td>
                                        {super_agent.totals.t_amt.toFixed(2)}
                                      </td>
                                      <td>
                                        {super_agent.totals.agent_m_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.agent_s_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.agent_t_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.agent_net_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.agent_shr_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.agent_mob_app.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.agent_final.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_agent_m_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_agent_s_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_agent_t_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_agent_net_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_agent_shr_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_agent_mob_app.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_agent_final.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.master_m_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.master_s_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.master_t_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.master_net_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.master_shr_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.master_mob_app.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.master_final.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.sub_admin_m_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.sub_admin_s_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.sub_admin_t_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.sub_admin_net_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.sub_admin_shr_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.sub_admin_mob_app.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.sub_admin_final.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_admin_m_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_admin_s_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_admin_t_com.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_admin_net_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_admin_shr_amt.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_admin_mob_app.toFixed(
                                          2
                                        )}
                                      </td>
                                      <td>
                                        {super_agent.totals.super_admin_final.toFixed(
                                          2
                                        )}
                                      </td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className="card-body pt-1">
                          <table className="table table-bordered bg-white">
                            <tbody>
                              <tr
                                style={{
                                  fontWeight: "bold",
                                }}
                              >
                                <td>Master Grand Total</td>
                                <td>{master.totals.m_amt.toFixed(2)}</td>
                                <td>{master.totals.s_amt.toFixed(2)}</td>
                                <td>{master.totals.t_amt.toFixed(2)}</td>
                                <td>{master.totals.agent_m_com.toFixed(2)}</td>
                                <td>{master.totals.agent_s_com.toFixed(2)}</td>
                                <td>{master.totals.agent_t_com.toFixed(2)}</td>
                                <td>
                                  {master.totals.agent_net_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.agent_shr_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.agent_mob_app.toFixed(2)}
                                </td>
                                <td>{master.totals.agent_final.toFixed(2)}</td>
                                <td>
                                  {master.totals.super_agent_m_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_agent_s_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_agent_t_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_agent_net_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_agent_shr_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_agent_mob_app.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_agent_final.toFixed(2)}
                                </td>
                                <td>{master.totals.master_m_com.toFixed(2)}</td>
                                <td>{master.totals.master_s_com.toFixed(2)}</td>
                                <td>{master.totals.master_t_com.toFixed(2)}</td>
                                <td>
                                  {master.totals.master_net_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.master_shr_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.master_mob_app.toFixed(2)}
                                </td>
                                <td>{master.totals.master_final.toFixed(2)}</td>
                                <td>
                                  {master.totals.sub_admin_m_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.sub_admin_s_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.sub_admin_t_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.sub_admin_net_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.sub_admin_shr_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.sub_admin_mob_app.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.sub_admin_final.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_admin_m_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_admin_s_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_admin_t_com.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_admin_net_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_admin_shr_amt.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_admin_mob_app.toFixed(2)}
                                </td>
                                <td>
                                  {master.totals.super_admin_final.toFixed(2)}
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    ))}
                  </div>
                  <div className="card-body pt-1 bg-gray-light">
                    <table className="table table-bordered bg-white">
                      <tbody>
                        <tr
                          style={{
                            fontWeight: "bold",
                          }}
                        >
                          <td>Sub Admin Grand Total</td>
                          <td>{sub_admin.totals.m_amt.toFixed(2)}</td>
                          <td>{sub_admin.totals.s_amt.toFixed(2)}</td>
                          <td>{sub_admin.totals.t_amt.toFixed(2)}</td>
                          <td>{sub_admin.totals.agent_m_com.toFixed(2)}</td>
                          <td>{sub_admin.totals.agent_s_com.toFixed(2)}</td>
                          <td>{sub_admin.totals.agent_t_com.toFixed(2)}</td>
                          <td>{sub_admin.totals.agent_net_amt.toFixed(2)}</td>
                          <td>{sub_admin.totals.agent_shr_amt.toFixed(2)}</td>
                          <td>{sub_admin.totals.agent_mob_app.toFixed(2)}</td>
                          <td>{sub_admin.totals.agent_final.toFixed(2)}</td>
                          <td>
                            {sub_admin.totals.super_agent_m_com.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_agent_s_com.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_agent_t_com.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_agent_net_amt.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_agent_shr_amt.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_agent_mob_app.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_agent_final.toFixed(2)}
                          </td>
                          <td>{sub_admin.totals.master_m_com.toFixed(2)}</td>
                          <td>{sub_admin.totals.master_s_com.toFixed(2)}</td>
                          <td>{sub_admin.totals.master_t_com.toFixed(2)}</td>
                          <td>{sub_admin.totals.master_net_amt.toFixed(2)}</td>
                          <td>{sub_admin.totals.master_shr_amt.toFixed(2)}</td>
                          <td>{sub_admin.totals.master_mob_app.toFixed(2)}</td>
                          <td>{sub_admin.totals.master_final.toFixed(2)}</td>
                          <td>{sub_admin.totals.sub_admin_m_com.toFixed(2)}</td>
                          <td>{sub_admin.totals.sub_admin_s_com.toFixed(2)}</td>
                          <td>{sub_admin.totals.sub_admin_t_com.toFixed(2)}</td>
                          <td>
                            {sub_admin.totals.sub_admin_net_amt.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.sub_admin_shr_amt.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.sub_admin_mob_app.toFixed(2)}
                          </td>
                          <td>{sub_admin.totals.sub_admin_final.toFixed(2)}</td>
                          <td>
                            {sub_admin.totals.super_admin_m_com.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_admin_s_com.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_admin_t_com.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_admin_net_amt.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_admin_shr_amt.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_admin_mob_app.toFixed(2)}
                          </td>
                          <td>
                            {sub_admin.totals.super_admin_final.toFixed(2)}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              ))}
            </div>
            <div className="card-body pt-1">
              <table className="table table-bordered bg-white">
                <tbody>
                  <tr
                    style={{
                      fontWeight: "bold",
                    }}
                  >
                    <td>Mini Admin Grand Total</td>
                    <td>{superAdmin.totals.m_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.s_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.t_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.agent_m_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.agent_s_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.agent_t_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.agent_net_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.agent_shr_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.agent_mob_app.toFixed(2)}</td>
                    <td>{superAdmin.totals.agent_final.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_agent_m_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_agent_s_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_agent_t_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_agent_net_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_agent_shr_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_agent_mob_app.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_agent_final.toFixed(2)}</td>
                    <td>{superAdmin.totals.master_m_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.master_s_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.master_t_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.master_net_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.master_shr_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.master_mob_app.toFixed(2)}</td>
                    <td>{superAdmin.totals.master_final.toFixed(2)}</td>
                    <td>{superAdmin.totals.sub_admin_m_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.sub_admin_s_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.sub_admin_t_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.sub_admin_net_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.sub_admin_shr_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.sub_admin_mob_app.toFixed(2)}</td>
                    <td>{superAdmin.totals.sub_admin_final.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_admin_m_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_admin_s_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_admin_t_com.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_admin_net_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_admin_shr_amt.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_admin_mob_app.toFixed(2)}</td>
                    <td>{superAdmin.totals.super_admin_final.toFixed(2)}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        ))}
      </div>
      <div className="card-body pt-1 bg-gray-light">
        <table className="table table-bordered bg-white">
          <tbody>
            <tr
              style={{
                fontWeight: "bold",
              }}
            >
              <td>Admin Grand Total</td>
              <td>{finalReport.totals.m_amt.toFixed(2)}</td>
              <td>{finalReport.totals.s_amt.toFixed(2)}</td>
              <td>{finalReport.totals.t_amt.toFixed(2)}</td>
              <td>{finalReport.totals.agent_m_com.toFixed(2)}</td>
              <td>{finalReport.totals.agent_s_com.toFixed(2)}</td>
              <td>{finalReport.totals.agent_t_com.toFixed(2)}</td>
              <td>{finalReport.totals.agent_net_amt.toFixed(2)}</td>
              <td>{finalReport.totals.agent_shr_amt.toFixed(2)}</td>
              <td>{finalReport.totals.agent_mob_app.toFixed(2)}</td>
              <td>{finalReport.totals.agent_final.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_m_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_s_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_t_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_net_amt.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_shr_amt.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_mob_app.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_final.toFixed(2)}</td>
              <td>{finalReport.totals.master_m_com.toFixed(2)}</td>
              <td>{finalReport.totals.master_s_com.toFixed(2)}</td>
              <td>{finalReport.totals.master_t_com.toFixed(2)}</td>
              <td>{finalReport.totals.master_net_amt.toFixed(2)}</td>
              <td>{finalReport.totals.master_shr_amt.toFixed(2)}</td>
              <td>{finalReport.totals.master_mob_app.toFixed(2)}</td>
              <td>{finalReport.totals.master_final.toFixed(2)}</td>
              <td>{finalReport.totals.sub_admin_m_com.toFixed(2)}</td>
              <td>{finalReport.totals.sub_admin_s_com.toFixed(2)}</td>
              <td>{finalReport.totals.sub_admin_t_com.toFixed(2)}</td>
              <td>{finalReport.totals.sub_admin_net_amt.toFixed(2)}</td>
              <td>{finalReport.totals.sub_admin_shr_amt.toFixed(2)}</td>
              <td>{finalReport.totals.sub_admin_mob_app.toFixed(2)}</td>
              <td>{finalReport.totals.sub_admin_final.toFixed(2)}</td>
              <td>{finalReport.totals.super_admin_m_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_admin_s_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_admin_t_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_admin_net_amt.toFixed(2)}</td>
              <td>{finalReport.totals.super_admin_shr_amt.toFixed(2)}</td>
              <td>{finalReport.totals.super_admin_mob_app.toFixed(2)}</td>
              <td>{finalReport.totals.super_admin_final.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default AdminCard;
