import * as CryptoJS from "crypto-js";

const secretKey = process.env.REACT_APP_SECRET_KEY;

export const decryptData = (data) => {
  const bytes = CryptoJS.AES.decrypt(data, secretKey);

  if (bytes.toString()) {
    return bytes.toString(CryptoJS.enc.Utf8);
  }

  return data;
}