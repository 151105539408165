import React from "react";
import "../CompleteSubPages.css";

const SuperAgentCard = ({ finalReport, userCode, userName }) => {
  const sumKey = (arr, key) => {
    // Check if arr is a valid array
    if (!Array.isArray(arr)) {
      console.error("Expected an array, but got:", arr);
      return 0; // Return 0 if arr is not an array
    }

    return arr
      .reduce((a, b) => {
        const value = parseFloat(b[key]) || 0; // Ensure the value is a number, default to 0
        return a + value;
      }, 0)
      .toFixed(2); // Return the sum, fixed to 2 decimal places
  };

  return (
    <div className="card">
      <div
        className="card-header bg-purple py-2 text-white"
        style={{ height: "44px" }}
      >
        SUPER AGENT : ({userCode}) {userName}
      </div>
      <div className="card-body bg-gray-light pt-4 pb-0">
        {finalReport.map((agent, index) => (
          <div className="card" key={index}>
            <div
              className="card-header bg-primary py-2"
              style={{
                lineHeight: 1.5,
              }}
            >
              AGENT NAME : {agent.name}
            </div>
            <div className="card-body pt-4">
              <div className="table">
                <table
                  className="table table-bordered table-striped mb-0 longTable"
                  style={{
                    lineHeight: 1.5,
                  }}
                >
                  <thead>
                    <tr>
                      <th colSpan="11">Agent</th>
                      <th colSpan="7">Super Agent</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th>Client</th>
                      <th>M Amt</th>
                      <th>S Amt</th>
                      <th>T Amt</th>
                      {/* <th>T Comm</th>
                          <th>Net Amt</th>
                          {/* <th>C Mob</th>
                          <th>Final</th> */}
                      <th>M Com</th>
                      <th>S Com</th>
                      <th>T Com</th>
                      <th>Net Amt</th>
                      <th>Shr Amt</th>
                      <th>Mob App</th>
                      <th>Final</th>
                      <th>M Com</th>
                      <th>S Com</th>
                      <th>T Com</th>
                      <th>Net Amt</th>
                      <th>Shr Amt</th>
                      <th>Mob App</th>
                      <th>Final</th>
                    </tr>

                    {agent.client.map((match, index) => (
                      <tr key={index}>
                        <td>{match.client}</td>
                        <td>{match.m_amt.toFixed(2)}</td>
                        <td>{match.s_amt.toFixed(2)}</td>
                        <td>{match.t_amt.toFixed(2)}</td>
                        {/* <td>{match.c_com.toFixed(2)}</td>
                            <td>{match.net_amt.toFixed(2)}</td>
                            {/* <td>{match.c_mob.toFixed(2)}</td>
                            <td>{match.net_amt.toFixed(2)}</td> */}
                        <td>{match.agent_m_com.toFixed(2)}</td>
                        <td>{match.agent_s_com.toFixed(2)}</td>
                        <td>{match.agent_t_com.toFixed(2)}</td>
                        <td>{match.agent_net_amt.toFixed(2)}</td>
                        <td>{match.agent_shr_amt.toFixed(2)}</td>
                        <td>{match.agent_mob_app.toFixed(2)}</td>
                        <td>{match.agent_final.toFixed(2)}</td>
                        <td>{match.super_agent_m_com.toFixed(2)}</td>
                        <td>{match.super_agent_s_com.toFixed(2)}</td>
                        <td>{match.super_agent_t_com.toFixed(2)}</td>
                        <td>{match.super_agent_net_amt.toFixed(2)}</td>
                        <td>{match.super_agent_shr_amt.toFixed(2)}</td>
                        <td>{match.super_agent_mob_app.toFixed(2)}</td>
                        <td>{match.super_agent_final.toFixed(2)}</td>
                      </tr>
                    ))}

                    <tr>
                      <td>Total</td>
                      <td>{sumKey(agent.client, "m_amt")}</td>
                      <td>{sumKey(agent.client, "s_amt")}</td>
                      <td>{sumKey(agent.client, "t_amt")}</td>
                      {/* <td>{sumKey(agent.client, "c_com")}</td>
                          <td>{sumKey(agent.client, "net_amt")}</td>
                          {/* <td>{sumKey(agent.client, "c_mob")}</td>
                          <td>{sumKey(agent.client, "net_amt")}</td> */}
                      <td>{sumKey(agent.client, "agent_m_com")}</td>
                      <td>{sumKey(agent.client, "agent_s_com")}</td>
                      <td>{sumKey(agent.client, "agent_t_com")}</td>
                      <td>{sumKey(agent.client, "agent_net_amt")}</td>
                      <td>{sumKey(agent.client, "agent_shr_amt")}</td>
                      <td>{sumKey(agent.client, "agent_mob_app")}</td>
                      <td>{sumKey(agent.client, "agent_final")}</td>
                      <td>{sumKey(agent.client, "super_agent_m_com")}</td>
                      <td>{sumKey(agent.client, "super_agent_s_com")}</td>
                      <td>{sumKey(agent.client, "super_agent_t_com")}</td>
                      <td>{sumKey(agent.client, "super_agent_net_amt")}</td>
                      <td>{sumKey(agent.client, "super_agent_shr_amt")}</td>
                      <td>{sumKey(agent.client, "super_agent_mob_app")}</td>
                      <td>{sumKey(agent.client, "super_agent_final")}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="card-body pt-1 bg-gray-light">
        <table className="table table-bordered bg-white">
          <tbody>
            <tr
              style={{
                fontWeight: "bold",
              }}
            >
              <td>Super Agent Total</td>
              <td>{finalReport.totals.m_amt.toFixed(2)}</td>
              <td>{finalReport.totals.s_amt.toFixed(2)}</td>
              <td>{finalReport.totals.t_amt.toFixed(2)}</td>
              <td>{finalReport.totals.agent_m_com.toFixed(2)}</td>
              <td>{finalReport.totals.agent_s_com.toFixed(2)}</td>
              <td>{finalReport.totals.agent_t_com.toFixed(2)}</td>
              <td>{finalReport.totals.agent_net_amt.toFixed(2)}</td>
              <td>{finalReport.totals.agent_shr_amt.toFixed(2)}</td>
              <td>{finalReport.totals.agent_mob_app.toFixed(2)}</td>
              <td>{finalReport.totals.agent_final.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_m_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_s_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_t_com.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_net_amt.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_shr_amt.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_mob_app.toFixed(2)}</td>
              <td>{finalReport.totals.super_agent_final.toFixed(2)}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default SuperAgentCard;
