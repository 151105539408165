import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleRight } from "@fortawesome/free-solid-svg-icons";
import { Modal } from "bootstrap";
import "bootstrap/dist/js/bootstrap.bundle.min";

const LedgerModal = ({ cashCount, accountType }) => {
  const navigate = useNavigate();
  const [users, setUsers] = useState([]);

  useEffect(() => {
    if (accountType === "admin") {
      setUsers(adminUsers);
    } else if (accountType === "super_admin") {
      setUsers(superAdminUsers);
    } else if (accountType === "sub_admin") {
      setUsers(subAdminUsers);
    } else if (accountType === "master") {
      setUsers(masterUsers);
    } else if (accountType === "super_agent") {
      setUsers(superAgentUsers);
    } else if (accountType === "agent") {
      setUsers(agentUsers);
    }
  }, [accountType]);

  const handleNavigation = (path) => {
    // Close the modal
    const modalElement = document.getElementById("ledgerModal");
    const modal = Modal.getInstance(modalElement);
    if (modal) {
      modal.hide();
    }
    // Navigate to the specified path
    navigate(path);
  };

  const adminUsers = [
    { name: "Super Admin Ledger", path: "/admin-ledger", color: "bg-primary" },
    { name: "Sub Admin Ledger", path: "/sub-admin-ledger", color: "bg-green" },
    { name: "Master Ledger", path: "/master-ledger", color: "bg-yellow" },
    { name: "Super Agent Ledger", path: "/super-ledger", color: "bg-red" },
    { name: "Agent Ledger", path: "/agent-ledger", color: "bg-primary" },
    { name: "Client Ledger", path: "/client-ledger", color: "bg-green" },
  ];

  const superAdminUsers = [
    { name: "My Ledger", path: "/my-ledger", color: "bg-primary" },
    { name: "Sub Admin Ledger", path: "/sub-admin-ledger", color: "bg-green" },
    { name: "Master Ledger", path: "/master-ledger", color: "bg-yellow" },
    { name: "Super Agent Ledger", path: "/super-ledger", color: "bg-red" },
    { name: "Agent Ledger", path: "/agent-ledger", color: "bg-primary" },
    { name: "Client Ledger", path: "/client-ledger", color: "bg-green" },
  ];

  const subAdminUsers = [
    { name: "My Ledger", path: "/my-ledger", color: "bg-primary" },
    { name: "Master Ledger", path: "/master-ledger", color: "bg-yellow" },
    { name: "Super Agent Ledger", path: "/super-ledger", color: "bg-red" },
    { name: "Agent Ledger", path: "/agent-ledger", color: "bg-primary" },
    { name: "Client Ledger", path: "/client-ledger", color: "bg-green" },
  ];

  const masterUsers = [
    { name: "My Ledger", path: "/my-ledger", color: "bg-primary" },
    { name: "Super Agent Ledger", path: "/super-ledger", color: "bg-red" },
    { name: "Agent Ledger", path: "/agent-ledger", color: "bg-yellow" },
    { name: "Client Ledger", path: "/client-ledger", color: "bg-green" },
  ];

  const superAgentUsers = [
    { name: "My Ledger", path: "/my-ledger", color: "bg-primary" },
    { name: "Agent Ledger", path: "/agent-ledger", color: "bg-yellow" },
    { name: "Client Ledger", path: "/client-ledger", color: "bg-green" },
  ];

  const agentUsers = [
    { name: "My Ledger", path: "/my-ledger", color: "bg-primary" },
    { name: "Client Ledger", path: "/client-ledger", color: "bg-green" },
  ];

  return (
    <div
      className="modal fade"
      id="ledgerModal"
      // data-bs-backdrop="static"
      data-bs-keyboard="false"
      tabIndex="-1"
      aria-labelledby="ledgerModalLabel"
      aria-hidden="true"
      style={{ display: "none" }}
    >
      <div className="modal-dialog">
        <div className="modal-content">
          <div className="modal-body">
            <div className="row">
              {cashCount && users ? (
                users.map((user, index) => (
                  <div className="col-6" key={index}>
                    <div
                      className={`small-box ${user.color}`}
                      onClick={() => handleNavigation(user.path)}
                      data-bs-dismiss="modal"
                    >
                      <div className="inner">
                        <h3>{cashCount ? cashCount : 0}</h3>
                        <p>{user.name}</p>
                      </div>
                      <div className="icon">
                        <i className="ion ion-pie-graph" />
                      </div>
                      <Link className="small-box-footer">
                        More info <i className="fas fa-arrow-circle-right" />
                        <FontAwesomeIcon icon={faArrowAltCircleRight} />
                      </Link>
                    </div>
                  </div>
                ))
              ) : (
                <div>Loading...</div>
              )}
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-secondary"
              data-bs-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LedgerModal;
