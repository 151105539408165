import React, { Component } from "react";
import Sidebar from "../Components/Sidebar";
import Footer from "../Components/Footer";
import { Outlet } from "react-router-dom";

export default class MainLayout extends Component {
  render() {
    return (
      <>
        <Sidebar />
        <Outlet />
        <Footer />
      </>
    );
  }
}
