import { useMutation } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";

const BASE_URL = "https://appleexch.com/api/MatchOdds/GetOddslite";

async function mutationFn(gameId) {
  const axios = createProtectedAxios();
  const endpoint = `${BASE_URL}/4/${gameId}`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetAppleExch() {
  const { mutate, isLoading, data } = useMutation(
    ["get-apple-exch"],
    (gameId) => mutationFn(gameId),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
