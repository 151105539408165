import React from "react";
import "./adminsidebar.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faCircleStop } from "@fortawesome/free-regular-svg-icons";
import { faFutbol } from "@fortawesome/free-solid-svg-icons";
import { faTableTennisPaddleBall } from "@fortawesome/free-solid-svg-icons";

export default function PowerUser({ toggleSidebar }) {
  return (
    <>
      <li className="nav-header">Games</li>
      <li className="nav-item">
        <Link to="inplay" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faPlay}
          />
          <p style={{ color: "#343a40" }}>In Play</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="complete-games" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faCircleStop}
          />
          <p style={{ color: "#343a40" }}>Complete Games</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="complete-games" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faFutbol}
          />
          <p style={{ color: "#343a40" }}>Football</p>
        </Link>
      </li>
      <li className="nav-item">
        <Link to="complete-games" className="nav-link" onClick={toggleSidebar}>
          <FontAwesomeIcon
            className="nav-icon"
            style={{ color: "#343a40" }}
            icon={faTableTennisPaddleBall}
          />
          <p style={{ color: "#343a40" }}>Tennis</p>
        </Link>
      </li>
    </>
  );
}
