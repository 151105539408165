import { useMutation } from "react-query";
import { createProtectedAxios } from "../../lib/auth";
import { encryptData } from "../../utils/encryptData";

const BASE_URL = process.env.REACT_APP_API_URL;

async function mutationFn(payload) {
  const axios = createProtectedAxios();
  const userId = payload.user_id;

  const endpoint = `${BASE_URL}/users/edit_pw/${userId}`;

  let encryptedPayload = encryptData({
    name: payload.name,
    mobile: payload.mobile,
    password: payload.password,
    acc_type: payload.acc_type,
  });
  let payloadData = { data: encryptedPayload };

  return axios.put(endpoint, payloadData).then(({ data }) => data);
}

export default function useUpdatePowerUsers() {
  const { mutate, isLoading, data } = useMutation(
    ["update-power-user"],
    (payload) => mutationFn(payload),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
