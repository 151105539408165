/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React from "react";
import "./ledgermanagement.css";
import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import useGetLedgerData from "../../hooks/Ledger/useGetLedgerData";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function ClientLedger(props) {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");

  const navigate = useNavigate();

  const { mutate: getLedgerData } = useGetLedgerData();
  const { mutate: accountStatus } = useGetAccountStatus();

  const [users, setUsers] = useState([]);
  const [ledger, setLedger] = useState([]);
  const [denaHai, setDenaHai] = useState([]);
  const [denaHaiComplete, setDenaHaiComplete] = useState([]);
  const [lenaHai, setLenaHai] = useState([]);
  const [lenaHaiComplete, setLenaHaiComplete] = useState([]);
  const [denaHaiTotal, setDenaHaiTotal] = useState(0);
  const [lenaHaiTotal, setLenaHaiTotal] = useState(0);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const checkData = () => {
    const UUID = localStorage.getItem("UUID");
    const accountType = localStorage.getItem("account");

    const payload = {
      user_id: UUID,
      logged_acc_type: accountType,
      acc_type: "user",
    };

    getLedgerData(payload, {
      onSuccess: (response) => {
        // console.log(response);
        // console.log(response.data.won);
        response.data.won.forEach((element) => {
          if (
            !users.some((el) => el.client_user_id === element.client_user_id)
          ) {
            users.push({ client_user_id: element.client_user_id });
            ledger.push(element);
          } else {
            const index = ledger.findIndex(
              (img) => img.client_user_id === element.client_user_id
            );

            if (element.final !== 0 && element.net_amt !== null) {
              ledger[index].final = ledger[index].final + element.final;
            } else if (element.final !== 0 && element.net_amt === null) {
              ledger[index].final = ledger[index].final + element.final;
            } else {
              ledger[index].final = ledger[index].final + 0;
            }
          }
        });

        ledger.forEach((el) => {
          if (el.final > 0) {
            let new_el = { ...el, final: parseInt(el.final) };

            lenaHai.push(new_el);
            lenaHaiComplete.push(new_el);
            setLenaHaiTotal(
              lenaHai.reduce(
                (accumulator, currentValue) => accumulator + currentValue.final,
                0
              )
            );
          } else {
            let new_el = {
              ...el,
              final: parseInt(Math.abs(el.final)),
            };

            denaHai.push(new_el);
            denaHaiComplete.push(new_el);
            setDenaHaiTotal(
              denaHai.reduce(
                (accumulator, currentValue) => accumulator + currentValue.final,
                0
              )
            );
          }
        });
      },
      onError: (error) => {
        console.log(error);
      },
    });
  };

  useEffect(() => {
    checkData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function searchLenaHai(val) {
    const filteredData = lenaHai.filter((item) => {
      return (
        item.user_accounts[0].code.toLowerCase().includes(val.toLowerCase()) ||
        item.user_accounts[0].name.toLowerCase().includes(val.toLowerCase())
      );
    });

    setLenaHai(filteredData);

    setLenaHaiTotal(
      filteredData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.final,
        0
      )
    );
  }

  function searchDenaHai(val) {
    const filteredData = denaHai.filter((item) => {
      return (
        item.user_accounts[0].code.toLowerCase().includes(val.toLowerCase()) ||
        item.user_accounts[0].name.toLowerCase().includes(val.toLowerCase())
      );
    });

    setDenaHai(filteredData);

    setDenaHaiTotal(
      filteredData.reduce(
        (accumulator, currentValue) => accumulator + currentValue.final,
        0
      )
    );
  }

  return (
    <div className="content-wrapper">
      <section className="content">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>All Client Ledger</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>All Client Ledger</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>
      {/* <button onClick={checkData}>Check</button> */}
      <section className="content">
        <div className="container-fluid">
          <div className="card p-2">
            <div className="row">
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <span className="me-1">Search:</span>
                  <input
                    type="text"
                    className="form-control w-auto ml-1"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      if (value.length > 0) {
                        searchLenaHai(value);
                      } else {
                        setLenaHai(lenaHaiComplete);
                        setLenaHaiTotal(
                          lenaHaiComplete.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.final,
                            0
                          )
                        );
                      }
                    }}
                  />
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-striped"
                    id="datatable"
                  >
                    <thead>
                      <tr>
                        <th colSpan={6}>PAYMENT RECEIVING FROM (LENA HE)</th>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {lenaHai && lenaHai.length !== 0 ? (
                        <>
                          {lenaHai.map((el, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {el?.user_accounts[0]?.code}{" "}
                                  {el?.user_accounts[0]?.name}
                                </td>
                                <td>{el?.user_accounts[0]?.mobile}</td>
                                <td>{el?.final}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={2}>Total</td>
                            <td>{lenaHaiTotal}</td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td colSpan={3}>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6">
                <div className="d-flex align-items-center">
                  <span className="me-1">Search:</span>
                  <input
                    type="text"
                    className="form-control w-auto ml-1"
                    onChange={(e) => {
                      const value = e.target.value.trim();
                      if (value.length > 0) {
                        searchDenaHai(value);
                      } else {
                        setDenaHai(denaHaiComplete);
                        setDenaHaiTotal(
                          denaHaiComplete.reduce(
                            (accumulator, currentValue) =>
                              accumulator + currentValue.final,
                            0
                          )
                        );
                      }
                    }}
                  />
                </div>
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-striped"
                    id="datatable"
                  >
                    <thead>
                      <tr>
                        <th colSpan={6}>PAYMENT RECEIVING FROM (DENA HE)</th>
                      </tr>
                      <tr>
                        <th>Name</th>
                        <th>Contact</th>
                        <th>Balance</th>
                      </tr>
                    </thead>
                    <tbody>
                      {denaHai && denaHai.length !== 0 ? (
                        <>
                          {denaHai.map((el, index) => {
                            return (
                              <tr key={index}>
                                <td>
                                  {el?.user_accounts[0]?.code}{" "}
                                  {el?.user_accounts[0]?.name}
                                </td>
                                <td>{el?.user_accounts[0]?.mobile}</td>
                                <td>{el?.final}</td>
                              </tr>
                            );
                          })}
                          <tr>
                            <td colSpan={2}>Total</td>
                            <td>{denaHaiTotal}</td>
                          </tr>
                        </>
                      ) : (
                        <tr>
                          <td colSpan={3}>No Data Found</td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
