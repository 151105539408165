import React, { Component } from "react";
import PageHeader from "../Components/PageHeader";
import { useNavigate } from "react-router-dom";

export default function SessionPMList() {
  const navigate = useNavigate();

  const InplayLongTable = () => {
    navigate("/inplay-long-table");
  };

  return (
    <>
      <div className="content-wrapper">
        <PageHeader title="Match & Session Plus Minus Report" />

        <section className="content">
          <div className="container-fluid">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <input
                    type="hidden"
                    name="market_id"
                    defaultValue="1.202052720"
                  />
                  <input type="hidden" name="page_name" defaultValue="match" />
                  <input type="hidden" name="type" defaultValue="b" />
                  <input type="hidden" name="type" defaultValue="b" />
                  <div className="card-header text-center">
                    <div className="container">
                      <div className="row">
                        <div className="col">
                          <h5>
                            SUPERADMIN OF :- MANCHESTER ORIGINALS WOMEN VS WELSH
                            FIRE WOMEN (T-20)
                          </h5>
                        </div>
                        <div className="col">
                          <button
                            type="submit"
                            className="btn btn-primary"
                            onClick={() => {
                              console.log("decision game");
                              InplayLongTable();
                            }}
                          >
                            Show
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* /.card-header */}
                  <div className="card-body">
                    <div className="form-row">
                      <div className="col-md-8">
                        <table
                          align="left"
                          id="sessionsTable"
                          className="table table-striped  table-hover table-bordered"
                        >
                          <thead>
                            <tr>
                              <th width="15%">
                                <div align="center">
                                  <input
                                    type="checkbox"
                                    id="allSessions"
                                    defaultValue={1}
                                    defaultChecked
                                  />
                                </div>
                              </th>
                              <th>SESSION (POST DATE)</th>
                              <th>DECLARE</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div align="center">
                                  <input
                                    defaultChecked
                                    type="checkbox"
                                    className="custom-checkbox"
                                    defaultValue="97d4955e8c8894b4803485002e8aa5ca69f9f515"
                                    id="97d4955e8c8894b4803485002e8aa5ca69f9f515"
                                    name="session_array[]"
                                  />
                                </div>
                              </td>
                              <td>55 BALL RUN WF W (16-08-2022 21:32:13 )</td>
                              <td>83</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-md-4">
                        <table
                          align="left"
                          id="clientTable"
                          className="table table-striped  table-hover table-bordered"
                        >
                          <thead>
                            <tr>
                              <th width="15%">
                                <div align="center">
                                  <input
                                    onclick="click()"
                                    type="checkbox"
                                    id="allClient"
                                    defaultValue={1}
                                    defaultChecked
                                  />
                                </div>
                              </th>
                              <th>ADMIN (CODE NAME)</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <div align="center">
                                  <input
                                    defaultChecked
                                    type="checkbox"
                                    className="custom-checkbox checkbox"
                                    defaultValue={654}
                                    id="654_user_id"
                                    name="downline_array[]"
                                  />
                                </div>
                              </td>
                              <td>ROCKY(SUB854)</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
