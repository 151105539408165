/* eslint-disable array-callback-return */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import useUpdateSessionBet from "../../hooks/Games/useUpdateSessionBet";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";

export default function InplaySessionBets() {
  // const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");
  let accountType = localStorage.getItem("account");

  const userPassword = localStorage.getItem("password");

  const { mutate: accountStatus } = useGetAccountStatus();

  const location = useLocation();
  const navigate = useNavigate();

  const { mutate: updateSessionBet, isLoading } = useUpdateSessionBet();

  const data = location.state;

  const [rate, setRate] = useState("");
  const [run, setRun] = useState("");
  const [amount, setAmount] = useState("");
  const [mode, setMode] = useState("");
  const [password, setPassword] = useState("");

  function handleSubmit(e) {
    e.preventDefault();

    if (mode === "" || rate === "" || run === "" || amount === "") {
      toast.error("Please fill all the fields!");
      return;
    }

    if (password !== "Tihor@9ex") {
      toast.error("Invalid Password!");
      setPassword("");
      return;
    }

    const payload = {
      id: data.data.id,
      user_id: data.data.user_id,
      session: data.data.session,
      match_id: data.data.match_id,
      rate: Number(rate),
      amount: Number(amount),
      runs: Number(run),
      mode: mode,
      match_condn: Boolean(data.data.match_condn),
      mob_commision: data.data.mob_commision,
      cancelled: Boolean(data.data.cancelled),
      unique: Boolean(data.data.unique),
      view: Boolean(data.data.view),
    };

    updateSessionBet(payload, {
      onSuccess: () => {
        toast.success("Session Bet Updated Successfully!");
        navigate("/inplay");
      },
      onError: (err) => {
        console.log(err);
        toast.error("Session Bet Update Failed!");
      },
    });
  }

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setRate(data.data.rate);
    setRun(data.data.runs);
    setAmount(data.data.amount);
    setMode(data.data.mode);
  }, [data]);

  useEffect(() => {
    if (accountType === "admin" && isMasterAdmin === "1") {
      console.log("ok");
    } else {
      navigate("/inplay");
      toast.error("You don't have access!");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Edit Session Bet Details</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Edit Session Bet Details</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <form onSubmit={handleSubmit}>
          <div className="row mb-4">
            {/* Share and Commission Form */}
            <div className="col-md-6 w-100">
              <div className="accordion" id="accordionExample2">
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button"
                      id="create-button-1"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#collapseTwo"
                      aria-expanded="true"
                      aria-controls="collapseTwo"
                    >
                      General
                    </button>
                  </h2>
                  <div
                    id="collapseTwo"
                    className="accordion-collapse collapse show"
                    aria-labelledby="headingTwo"
                    data-bs-parent="#accordionExample2"
                  >
                    <div className="accordion-body">
                      <div className="row">
                        <div className="form-group mb-2 col-lg-6">
                          <label className="form-label">Rate</label>
                          <input
                            type="text"
                            id="rate"
                            name="rate"
                            className="form-control"
                            placeholder="Enter Rate"
                            required
                            value={rate}
                            disabled={isLoading}
                            onChange={(e) => {
                              setRate(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-lg-6">
                          <label className="form-label">Run</label>
                          <input
                            type="text"
                            id="run"
                            name="run"
                            className="form-control"
                            placeholder="Enter Run"
                            required
                            value={run}
                            disabled={isLoading}
                            onChange={(e) => {
                              setRun(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-lg-6">
                          <label className="form-label">Amount</label>
                          <input
                            type="text"
                            id="amount"
                            name="amount"
                            className="form-control"
                            placeholder="Enter Amount"
                            required
                            value={amount}
                            disabled={isLoading}
                            onChange={(e) => {
                              setAmount(e.target.value);
                            }}
                          />
                        </div>

                        <div className="form-group mb-2 col-lg-6">
                          <label className="form-label">Mode</label>
                          <select
                            className="form-select"
                            id="mode"
                            name="mode"
                            required
                            value={mode}
                            disabled={isLoading}
                            onChange={(e) => {
                              setMode(e.target.value);
                            }}
                          >
                            <option value="">Select Mode</option>
                            <option value="Y">Y</option>
                            <option value="N">N</option>
                          </select>
                        </div>

                        <div className="form-group mb-2 col-md-12">
                          <label className="form-label">Password</label>
                          <input
                            type="text"
                            id="password"
                            name="password"
                            className="form-control"
                            placeholder="Enter Password"
                            required
                            value={password}
                            disabled={isLoading}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                        </div>

                        <button
                          type="submit"
                          className="btn btn-primary mt-2"
                          disabled={isLoading}
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </section>
    </div>
  );
}
