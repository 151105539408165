/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useQueryClient } from "react-query";
import PageHeader from "../../Components/PageHeader";
import { toast } from "react-hot-toast";
import useGetCassinoMatches from "../../hooks/Games/Casino/useGetCassinoMatches";
import useUpdateCassinoGame from "../../hooks/Games/Casino/useUpdateCassinoGame";
import useAddCassinoLedger from "../../hooks/Games/Casino/useAddCassinoLedger";
// import useAddCassinoGame from "../../hooks/Games/Casino/useAddCassinoGame";
import { convertToUTC } from "../../utils/dateFormatter";

export default function CasinoInPlay() {
  const queryClient = useQueryClient();

  const uuid = localStorage.getItem("UUID");
  const accType = localStorage.getItem("account");

  const { data: cassinoMatches } = useGetCassinoMatches();
  // const { mutate: addCassinoGame, isLoading: isAddingCassinoGame } =
  //   useAddCassinoGame();
  const { mutate: updateCassinoGame, isLoading: isUpdatingCassinoGame } =
    useUpdateCassinoGame();
  const { mutate: addCassinoLedger, isLoading: isAddingCassinoLedger } =
    useAddCassinoLedger();

  const [data, setData] = useState([]);
  const [ledgerDate, setLedgerDate] = useState("");

  // const addGame = () => {
  //   const payload = {
  //     name: "Lucky 7",
  //     isLocked: true,
  //     image:
  //       "https://res.cloudinary.com/drlijdhs2/image/upload/v1733762036/lucky_7.webp",
  //   };

  //   addCassinoGame(payload, {
  //     onSuccess: (data) => {
  //       console.log(data);
  //       toast.success("Game added successfully");
  //       queryClient.invalidateQueries("get-cassino-matchlist");
  //     },
  //     onError: (error) => {
  //       console.error(error);
  //     },
  //   });
  // };

  const updateGame = (item) => {
    const payload = {
      id: item.id,
      isLocked: !item.isLocked,
    };

    updateCassinoGame(payload, {
      onSuccess: (data) => {
        console.log(data);
        toast.success(
          item.isLocked
            ? "Game Activated successfully"
            : "Game Deactivated successfully"
        );
        queryClient.invalidateQueries("get-cassino-matchlist");
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const addLedger = () => {
    if (ledgerDate === "") {
      toast.error("Please select a date");
      return;
    }

    let fromDate = String(ledgerDate + " 00:00:00");
    let toDate = String(ledgerDate + " 23:59:59");

    fromDate = convertToUTC(fromDate);
    toDate = convertToUTC(toDate);

    const payload = {
      cassino_date: ledgerDate,
      cassino_date_from: fromDate,
      cassino_date_to: toDate,
      logged_user_id: uuid,
      logged_acc_type: accType,
    };

    addCassinoLedger(payload, {
      onSuccess: (data) => {
        console.log(data);
        toast.success("Ledger created successfully");
        queryClient.invalidateQueries("get-cassino-matchlist");
        setLedgerDate("");
      },
      onError: (error) => {
        toast.error("Data already exists / No data found");
        console.error(error);
        setLedgerDate("");
      },
    });
  };

  useEffect(() => {
    if (cassinoMatches) {
      console.log(cassinoMatches.data.data);
      setData(cassinoMatches.data.data);
    }
  }, [cassinoMatches]);

  return (
    <div className="content-wrapper">
      <PageHeader title="Casino In-Play" />

      <div className="container-fluid">
        <div className="row">
          <div className="col-sm-12 table-responsive">
            <div className="card">
              <div className="card-header">
                <div className="d-flex align-items-center justify-content-between w-100">
                  <h3 className="card-title">Casino Details</h3>
                  <div className="w-50 d-flex gap-2">
                    {/* <button
                      className="btn btn-primary w-50"
                      disabled={isAddingCassinoGame}
                      onClick={(e) => {
                        e.preventDefault();
                        addGame();
                      }}
                    >
                      Create Game
                    </button> */}
                    <input
                      type="date"
                      id="ledgerDate"
                      name="ledgerDate"
                      className="form-control"
                      required
                      value={ledgerDate}
                      onChange={(e) => {
                        setLedgerDate(e.target.value);
                      }}
                    />
                    <button
                      className="btn btn-primary w-50"
                      disabled={isAddingCassinoLedger}
                      onClick={(e) => {
                        e.preventDefault();
                        addLedger();
                      }}
                    >
                      Create Ledger
                    </button>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <table className="table table-bordered">
                  <thead>
                    <tr>
                      <th style={{ padding: "0.75rem" }}>S. No.</th>
                      <th style={{ padding: "0.75rem" }}>Name</th>
                      <th style={{ padding: "0.75rem" }}>Status</th>
                      <th style={{ padding: "0.75rem" }}>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data.length > 0 ? (
                      data.map((item, index) => (
                        <tr key={index}>
                          <td style={{ padding: "0.75rem" }}>{index + 1}</td>
                          <td style={{ padding: "0.75rem" }}>{item.name}</td>
                          <td style={{ padding: "0.75rem" }}>
                            {item.isLocked ? (
                              <span className="badge badge-danger">
                                Inactive
                              </span>
                            ) : (
                              <span className="badge badge-success">
                                Active
                              </span>
                            )}
                          </td>
                          <td style={{ padding: "0.75rem" }}>
                            <button
                              className="btn btn-warning btn-sm fw-semibold"
                              disabled={isUpdatingCassinoGame}
                              onClick={(e) => {
                                e.preventDefault();
                                updateGame(item);
                              }}
                            >
                              {item.isLocked ? "Unlock" : "Lock"}
                            </button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={9} className="text-center p-5">
                          <h6 className="p-5">No records to display</h6>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
