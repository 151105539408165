/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import "./CreateNewSuperAgent.css";
import { Link, useNavigate, useLocation } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";
import { passwordGenerator } from "../../utils/passwordGenerator";
import useCreateAccount from "../../hooks/useCreateAccount";
import useUpdateLimit from "../../hooks/useUpdateLimit";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useGetWalletBalance from "../../hooks/useGetWalletBalance";

export default function CreateNewSuperAgent() {
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");
  const userPassword = localStorage.getItem("password");
  const { mutate: accountStatus } = useGetAccountStatus();
  const navigate = useNavigate();
  const location = useLocation();
  let parentData = [];
  if (location.state) {
    parentData = location.state;
  } else {
    parentData = JSON.parse(localStorage.getItem("DATA"));
  }
  const { mutate: createAccount, isLoading: isCreatingAccount } =
    useCreateAccount();
  const { mutate: updateLimit, isLoading: isUpdatingLimit } = useUpdateLimit();
  const { mutate: getWalletBalance } = useGetWalletBalance();
  const [superAgentName, setSuperAgentName] = useState("");
  const [superAgentNumber, setSuperAgentNumber] = useState("");
  const [superAgentPassword, setSuperAgentPassword] = useState("");
  const [superAgentReference, setSuperAgentReference] = useState("");
  const [superAgentLimit, setSuperAgentLimit] = useState(0);
  const [superAgentShare, setSuperAgentShare] = useState(0);
  const [superAgentCasinoShare, setSuperAgentCasinoShare] = useState(0);
  const [superAgentMobileShare, setSuperAgentMobileShare] = useState(0);
  const [superAgentCommission, setSuperAgentCommission] = useState(
    "commission_2be03622ef6c"
  );
  const [superAgentMatchCommission, setSuperAgentMatchCommission] = useState(0);
  const [superAgentSessionCommission, setSuperAgentSessionCommission] =
    useState(0);
  const [superAgentCassinoCommission, setSuperAgentCassinoCommission] =
    useState(0);
  const [parentLimit, setParentLimit] = useState(0);
  const [clientCassinoStatus, setClientCassinoStatus] = useState(false);

  const [isUserCreating, setIsUserCreating] = useState(false);

  const getParentLimit = () => {
    const payload = {
      user_id: uuid,
      acc_type: "master",
    };

    getWalletBalance(payload, {
      onSuccess: (response) => {
        setParentLimit(response.data.data.limit);
      },
      onError: (error) => {
        console.error(error);
      },
    });
  };

  const generateNewPassword = () => {
    setSuperAgentPassword(passwordGenerator());
  };

  function handleSubmit(e) {
    e.preventDefault();

    setIsUserCreating(true);

    const payload = {
      acc_type: "super_agent",
      name: superAgentName,
      reference: superAgentReference,
      password: String(superAgentPassword),
      parent: parentData.id,
      parentCode: parentData.code,
      mobile: superAgentNumber,
      limit: superAgentLimit,
      share: superAgentShare,
      cassino_share: superAgentCasinoShare,
      mobile_share: superAgentMobileShare,
      commission: superAgentCommission,
      match_commission: superAgentMatchCommission,
      session_commission: superAgentSessionCommission,
      cassino_commission: superAgentCassinoCommission,
      cassino_status: clientCassinoStatus,
    };

    toast.promise(
      new Promise((resolve, reject) => {
        createAccount(payload, {
          onSuccess: (data) => {
            console.log(data);
            const superAgentLimitPayload = {
              user_id: data.data.data.id,
              acc_type: "super_agent",
              limit: superAgentLimit,
              coins: superAgentLimit,
              types: "credit",
              description: "Super Agent Limit Update",
            };
            const parentLimitPayload = {
              user_id: parentData.id,
              acc_type: "master",
              limit:
                accountType === "master"
                  ? parentLimit - superAgentLimit
                  : parentData.limit - superAgentLimit,
              coins: superAgentLimit,
              types: "debit",
              description: "Master Limit Update",
            };
            updateLimit(parentLimitPayload, {
              onSuccess: () => {
                updateLimit(superAgentLimitPayload, {
                  onSuccess: () => {
                    resolve(); // Trigger the success toast
                    setIsUserCreating(false);
                    navigate("/superagents");
                  },
                  onError: (error) => {
                    console.error(error);
                    reject(error); // Trigger the error toast
                    setIsUserCreating(false);
                    navigate("/superagents");
                  },
                });
              },
              onError: (error) => {
                console.error(error);
                reject(error); // Trigger the error toast
                setIsUserCreating(false);
                navigate("/superagents");
              },
            });
          },
          onError: (error) => {
            console.error(error);
            reject(error); // Trigger the error toast
            setIsUserCreating(false);
            navigate("/superagents");
          },
        });
      }),
      {
        loading: "Creating super agent account...",
        success: <b>Super Agent created successfully!</b>,
        error: <b>Something went wrong! Please try again later.</b>,
      }
    );
  }

  const [isOpen, setIsOpen] = useState(true);
  const [isFirstAccordionOpen, setIsFirstAccordionOpen] = useState(true);

  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  const toggleFirstAccordion = () => {
    setIsFirstAccordionOpen(!isFirstAccordionOpen);
  };

  useEffect(() => {
    if (accountType === "master") {
      getParentLimit();
    }
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setSuperAgentPassword(passwordGenerator());
  }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Create New Super Agent </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/superagents">Super Agent</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Create Super Agent</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <div className="row mt-4 mb-4">
              {/* General Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          isOpen ? "" : "collapsed"
                        }`}
                        id="create-button-1"
                        type="button"
                        onClick={toggleAccordion}
                        aria-expanded={isOpen}
                        aria-controls="collapseOne"
                      >
                        General
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className={`accordion-collapse collapse ${
                        isOpen ? "show" : ""
                      }`}
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div class="accordion-body">
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAgentCode"
                          >
                            Code
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="superAgentCode"
                            name="superAgentCode"
                            value="Auto generated"
                            disabled
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Name</label>
                          <input
                            type="text"
                            id="superAgentName"
                            name="superAgentName"
                            className="form-control"
                            placeholder="Enter Name"
                            required
                            onChange={(e) => {
                              setSuperAgentName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAgentNumber"
                          >
                            Contact Number
                          </label>
                          <input
                            type="text"
                            id="superAgentNumber"
                            name="superAgentNumber"
                            className="form-control"
                            placeholder="Enter Mobile Number"
                            minLength={0}
                            maxLength={10}
                            required
                            onChange={(e) => {
                              setSuperAgentNumber(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label className="form-label">Reference</label>
                          <input
                            type="text"
                            id="superAgentReference"
                            name="superAgentReference"
                            className="form-control"
                            placeholder="Enter Reference"
                            required
                            onChange={(e) => {
                              setSuperAgentReference(e.target.value);
                            }}
                          />
                        </div>
                        <div className="form-group mb-2">
                          <label
                            className="form-label"
                            htmlFor="superAgentPassword"
                          >
                            Password
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              id="superAgentPassword"
                              name="superAgentPassword"
                              className="form-control"
                              required
                              value={superAgentPassword}
                              onChange={(e) => {
                                setSuperAgentPassword(e.target.value);
                              }}
                            />
                            <span
                              class="input-group-text btn btn-primary"
                              onClick={generateNewPassword}
                            >
                              Generate Password!
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Share and Commission Form */}
              <div className="col-md-6">
                <div className="accordion" id="accordionExample2">
                  <div className="accordion-item">
                    <h2 className="accordion-header">
                      <button
                        className={`accordion-button ${
                          isFirstAccordionOpen ? "" : "collapsed"
                        }`}
                        id="create-button-2"
                        type="button"
                        onClick={toggleFirstAccordion}
                        aria-expanded={isFirstAccordionOpen}
                        aria-controls="collapseTwo"
                      >
                        Share and Commission
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className={`accordion-collapse collapse ${
                        isFirstAccordionOpen ? "show" : ""
                      }`}
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample2"
                    >
                      <div class="accordion-body">
                        <div className="row">
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Limit
                            </label>
                            <input
                              type="number"
                              id="superAgentLimit"
                              name="superAgentLimit"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={
                                accountType === "master"
                                  ? parentLimit
                                  : parentData.limit
                              }
                              required
                              onChange={(e) => {
                                setSuperAgentLimit(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Limit</label>
                            <input
                              type="number"
                              className="form-control"
                              value={
                                accountType === "master"
                                  ? parentLimit
                                  : parentData.limit
                              }
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Share
                            </label>
                            <input
                              type="number"
                              id="superAgentShare"
                              name="superAgentShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.share}
                              step="0.01"
                              required
                              onChange={(e) => {
                                setSuperAgentShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">My Share</label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Casino Share
                            </label>
                            <input
                              type="number"
                              id="superAgentCasinoShare"
                              name="superAgentCasinoShare"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.cassino_share}
                              step="0.01"
                              required
                              onChange={(e) => {
                                setSuperAgentCasinoShare(e.target.value);
                              }}
                            />
                          </div>
                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Casino Share
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_share}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">Casino Status</label>
                            <select
                              className="form-select"
                              id="clientCassinoStatus"
                              name="clientCassinoStatus"
                              required
                              value={clientCassinoStatus}
                              onChange={(e) => {
                                if (e.target.value === "true") {
                                  setClientCassinoStatus(true);
                                } else {
                                  setClientCassinoStatus(false);
                                }
                              }}
                            >
                              <option value={false}>OFF</option>
                              <option
                                value={true}
                                disabled={parentData?.cassino_status === 0}
                              >
                                ON
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-12">
                            <label className="form-label">
                              Commission Type
                            </label>
                            <select
                              className="form-control"
                              id="superAgentCommission"
                              name="superAgentCommission"
                              required
                              onChange={(e) => {
                                const value = e.target.value;
                                setSuperAgentCommission(value);

                                // Automatically set match, session, and cassino commissions to 0 if 'No Com' is selected
                                if (value === "commission_6e444f0e316c") {
                                  setSuperAgentMatchCommission(0);
                                  setSuperAgentSessionCommission(0);
                                  setSuperAgentCassinoCommission(0);
                                }
                              }}
                            >
                              <option value="commission_2be03622ef6c">
                                Bet By Bet
                              </option>
                              <option value="commission_6e444f0e316c">
                                No Com
                              </option>
                            </select>
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Match Commission
                            </label>
                            <input
                              type="number"
                              id="superAgentMatchCommission"
                              name="superAgentMatchCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.match_commission}
                              value={superAgentMatchCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setSuperAgentMatchCommission(e.target.value)
                              }
                              disabled={
                                superAgentCommission ===
                                "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Match Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.match_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Session Commission
                            </label>
                            <input
                              type="number"
                              id="superAgentSessionCommission"
                              name="superAgentSessionCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.session_commission}
                              value={superAgentSessionCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setSuperAgentSessionCommission(e.target.value)
                              }
                              disabled={
                                superAgentCommission ===
                                "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Session Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.session_commission}
                              disabled
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              Super Agent Cassino Commission
                            </label>
                            <input
                              type="number"
                              id="superAgentCassinoCommission"
                              name="superAgentCassinoCommission"
                              className="form-control"
                              placeholder="0.00"
                              min="0"
                              max={parentData.cassino_commission}
                              value={superAgentCassinoCommission} // Bind value to state
                              required
                              onChange={(e) =>
                                setSuperAgentCassinoCommission(e.target.value)
                              }
                              disabled={
                                superAgentCommission ===
                                "commission_6e444f0e316c"
                              } // Disable if 'No Com' selected
                            />
                          </div>

                          <div className="form-group mb-2 col-md-6">
                            <label className="form-label">
                              My Cassino Commission
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={parentData.cassino_commission}
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Action Buttons */}
              <div className="d-flex w-100 mt-2 col-12 justify-content-between">
                <button
                  type="button"
                  className="btn btn-secondary"
                  onClick={(e) => {
                    e.preventDefault();
                    navigate("/superagents");
                  }}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="btn btn-success"
                  disabled={isUserCreating}
                >
                  Create New Super Agent
                </button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
}
