import { useMutation } from "react-query";
import { createProtectedAxios } from "../lib/auth";
import { encryptData } from "../utils/encryptData";

const BASE_URL = process.env.REACT_APP_API_URL;

async function mutationFn(userId, payload) {
  const axios = createProtectedAxios();

  const endpoint =
    payload.acc_type === "user"
      ? `${BASE_URL}/users/edit_client/${userId}`
      : `${BASE_URL}/users/edit/${userId}`;

  let encryptedPayload = encryptData(payload);
  let payloadData = { data: encryptedPayload };

  return axios.put(endpoint, payloadData).then(({ data }) => data);
}

export default function useUpdateAccount() {
  const { mutate, isLoading, data } = useMutation(
    ["update-account"],
    ({ userId, payload }) => mutationFn(userId, payload),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
