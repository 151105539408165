import { useMutation } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";

const BASE_URL = process.env.REACT_APP_API_URL;

async function mutationFn(gameId) {
  const axios = createProtectedAxios();
  const endpoint = `${BASE_URL}/game/game_session_settings/${gameId}`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetSessionSetting() {
  const { mutate, isLoading, data } = useMutation(
    ["get-session-setting"],
    (gameId) => mutationFn(gameId),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
