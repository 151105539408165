import React, { useEffect, useState } from "react";
import "./Casino.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import useGetCassinoLedgerBets from "../../hooks/Games/Casino/useGetCassinoLedgerBets";
import PageHeader from "../../Components/PageHeader";
import { convertToUTC } from "../../utils/dateFormatter";

export default function CasinoPlusMinusReport() {
  const location = useLocation();
  const navigate = useNavigate();
  const UrlSearchParams = new URLSearchParams(location.search);
  const cassinoDate = UrlSearchParams.get("date");
  const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: getCassinoLedgerBets } = useGetCassinoLedgerBets();

  const [userData, setUserData] = useState([]);
  const [gameData, setGameData] = useState([]);

  useEffect(() => {
    let fromDate = String(cassinoDate + " 00:00:00");
    let toDate = String(cassinoDate + " 23:59:59");

    fromDate = convertToUTC(fromDate);
    toDate = convertToUTC(toDate);

    const payload = {
      cassino_date_from: fromDate,
      cassino_date_to: toDate,
      logged_acc_type: accountType,
      logged_user_id: uuid,
    };

    getCassinoLedgerBets(payload, {
      onSuccess: (data) => {
        let betsData = data.data.data;

        if (betsData.length === 0) {
          alert("No data found");
          navigate("/casino-details");
          return;
        }

        let uniqueUserIds = [];
        let uniqueGameIds = [];
        let uniqueUsers = [];
        let uniqueGames = [];
        betsData.forEach((element) => {
          if (!uniqueUserIds.includes(element.user_id)) {
            uniqueUserIds.push(element.user_id);
            uniqueUsers.push(element.user_account);
          }
        });

        betsData.forEach((element) => {
          if (!uniqueGameIds.includes(element.match_id)) {
            uniqueGameIds.push(element.match_id);
            uniqueGames.push(element.cassino_game);
          }
        });

        setUserData(uniqueUsers);
        setGameData(uniqueGames);
      },
      onError: (error) => {
        console.error(error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="content-wrapper">
        <PageHeader title="Casino Plus Minus Report" />

        <section className="content">
          <div className="container-fluid">
            <div className="card">
              <div className="card-header">
                <div className="w-100 d-flex align-items-center justify-content-between">
                  <h5 className="card-title">Ledger Report : {cassinoDate}</h5>
                  <Link to={`/casino-plus-minus-table?date=${cassinoDate}`}>
                    <button className="btn btn-primary">Show</button>
                  </Link>
                </div>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-md-8">
                    <table
                      align="left"
                      id="sessionsTable"
                      className="table table-striped table-hover table-bordered"
                    >
                      <thead>
                        <tr>
                          <th width="15%">
                            <div align="center">
                              <input type="checkbox" defaultChecked />
                            </div>
                          </th>
                          <th>User Code</th>
                          <th>User Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData && userData.length > 0 ? (
                          userData.map((user, index) => (
                            <tr key={index}>
                              <td>
                                <div align="center">
                                  <input defaultChecked type="checkbox" />
                                </div>
                              </td>
                              <td>{user.code}</td>
                              <td>{user.name}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>
                              <div align="center">
                                <input type="checkbox" />
                              </div>
                            </td>
                            <td colSpan={2}>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table
                      align="left"
                      id="clientTable"
                      className="table table-striped table-hover table-bordered"
                    >
                      <thead>
                        <tr>
                          <th width="15%">
                            <div align="center">
                              <input type="checkbox" defaultChecked />
                            </div>
                          </th>
                          <th>Game Name</th>
                        </tr>
                      </thead>
                      <tbody>
                        {gameData && gameData.length > 0 ? (
                          gameData.map((game, index) => (
                            <tr key={index}>
                              <td>
                                <div align="center">
                                  <input defaultChecked type="checkbox" />
                                </div>
                              </td>
                              <td>{game.name}</td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td>
                              <div align="center">
                                <input type="checkbox" />
                              </div>
                            </td>
                            <td>No Data Found</td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
}
