/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { toast } from "react-toastify";
import { toast } from "react-hot-toast";

import useGetSessionOfMatch from "../../hooks/Games/Inplay/useGetSessionOfMatch";
// import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";
import useGetAccountStatus from "../../hooks/useGetAccountStatus";
import useGetBetsData from "../../hooks/Games/Inplay/useGetBetsData";
import useGetUserOnlySession from "../../hooks/Games/Inplay/useGetUserOnlySession";
import useGetClientMasterList from "../../hooks/ClientMaster/useGetClientMasterList";

export default function InplaySessionListPlusMinus() {
  // const accountType = localStorage.getItem("account");
  const uuid = localStorage.getItem("UUID");

  const { mutate: accountStatus } = useGetAccountStatus();

  const location = useLocation();

  let gameData = [];
  let gameId = "";
  const accountType = localStorage.getItem("account");
  const userId = localStorage.getItem("UUID");
  const isMasterAdmin = localStorage.getItem("isMasterAdmin");
  const navigate = useNavigate();

  const userPassword = localStorage.getItem("password");
  if (location.state) {
    gameData = location.state;
    gameId = location.state.id;
  }

  const { mutate: getBetsData } = useGetBetsData();

  const { mutate: getUserOnlySession } = useGetUserOnlySession();
  const { data: ClientMasterListData } = useGetClientMasterList();

  const [data, setData] = useState([]);
  const [completeData, setCompleteData] = useState([]);
  const [matchBets, setMatchBets] = useState([]);
  const [allMatches, setAllMatches] = useState([]);
  const [sessionData, setSessionData] = useState([]);
  const [allSessions, setAllSessions] = useState([]);

  // eslint-disable-next-line no-unused-vars
  const [totalMatch1, setTotalMatch1] = useState(0);
  const [totalMatch2, setTotalMatch2] = useState(0);
  const [totalMatch3, setTotalMatch3] = useState(0);

  const [totalAmount, setTotalAmount] = useState(0);
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    const payload = {
      user_id: uuid,
      acc_type: accountType,
    };

    accountStatus(payload, {
      onSuccess: (response) => {
        let userData = response.data.data;

        if (
          userData.status === false ||
          userData.status === "false" ||
          userData.status === "0" ||
          userData.status === 0
        ) {
          localStorage.clear();
          sessionStorage.clear();
        } else if (userData.password !== userPassword) {
          localStorage.clear();
          sessionStorage.clear();
          navigate("/");
        }
      },
      onError: (error) => {
        toast.error(error.message);
      },
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = (value) => {
    if (value === "All" || value === "") {
      setMatchBets(allMatches);
      setSessionData(allSessions);
    } else {
      const filteredBets = allMatches.filter(
        (item) => item.user_accounts[0].id === value
      );

      let filteredData = [];

      if (accountType !== "admin") {
        allSessions.filter((item) => {
          if (
            (item.view === true || item.view === 1) &&
            item.user_account.id === value
          ) {
            if (item.trash === false || item.trash === null) {
              filteredData.push(item);
            }
          }
        });
      } else {
        if (isMasterAdmin === "1") {
          filteredData = allSessions.filter(
            (item) => item.user_account.id === value
          );
        } else {
          filteredData = allSessions.filter(
            (item) =>
              (item.view === true || item.view === 1) &&
              item.user_account.id === value
          );
        }
      }

      let total1 = 0;
      let total2 = 0;
      let total3 = 0;
      filteredBets.forEach((el) => {
        total1 = total1 + (el.teamA_bet_amount + el.teamA_lay_amount);
        total2 = total2 + (el.teamB_bet_amount + el.teamB_lay_amount);
        total3 = total3 + (el.draw_bet_amount + el.draw_lay_amount);
      });

      let totalAmount = 0;
      filteredData.forEach((el) => {
        totalAmount += el.amount;
      });

      setTotalMatch1(Math.round(total1));
      setTotalMatch2(Math.round(total2));
      setTotalMatch3(Math.round(total3));

      setTotalAmount(totalAmount);

      setMatchBets(filteredBets);
      setSessionData(filteredData);
    }
  };

  useEffect(() => {
    const listData = ClientMasterListData?.data;

    if (listData) {
      listData?.sort((a, b) => {
        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
      setData(listData);
      setCompleteData(listData);
    }
  }, [ClientMasterListData]);

  useEffect(() => {
    let userExist = [];

    const payload = {
      match_id: gameId,
      logged_acc_type: accountType,
      user_id: userId,
    };

    getBetsData(payload, {
      onSuccess: (data) => {
        setMatchBets(data.data);
        setAllMatches(data.data);

        let total1 = 0;
        let total2 = 0;
        let total3 = 0;

        data.data.forEach((el) => {
          total1 = total1 + (el.teamA_bet_amount + el.teamA_lay_amount);
          total2 = total2 + (el.teamB_bet_amount + el.teamB_lay_amount);
          total3 = total3 + (el.draw_bet_amount + el.draw_lay_amount);
        });

        data.data.forEach((element) => {
          if (
            !userExist.some((user) => user.id === element.user_accounts[0].id)
          ) {
            userExist.push({
              id: element.user_accounts[0].id,
              name: element.user_accounts[0].name,
              code: element.user_accounts[0].code,
            });
          }
        });

        setTotalMatch1(Math.round(total1));
        setTotalMatch2(Math.round(total2));
        setTotalMatch3(Math.round(total3));
      },
      onError: (error) => {
        console.log(error);
      },
    });

    getUserOnlySession(payload, {
      onSuccess: (data) => {
        let filteredData = [];

        if (accountType !== "admin") {
          data.data.filter((item) => {
            if (item.view === true || item.view === 1) {
              if (item.trash === false || item.trash === null) {
                filteredData.push(item);
              }
            }
          });
        } else {
          if (isMasterAdmin === "1") {
            filteredData = data.data;
          } else {
            filteredData = data.data.filter(
              (item) => item.view === true || item.view === 1
            );
          }
        }

        data.data.forEach((element) => {
          if (!userExist.some((user) => user.id === element.user_account.id)) {
            userExist.push({
              id: element.user_account.id,
              name: element.user_account.name,
              code: element.user_account.code,
            });
          }
        });

        setUserData(userExist);
        setSessionData(filteredData);
        setAllSessions(filteredData);

        const calculateTotalAmount = (data) => {
          let totalAmount = 0;
          data.forEach((el) => {
            totalAmount += el.amount;
          });
          setTotalAmount(totalAmount);
        };

        calculateTotalAmount(filteredData); // Corrected here
      },
      onError: (error) => {
        console.log("error", error);
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // useEffect(() => {
  //   const payload = {
  //     match_id: gameData.id,
  //   };

  //   getSessionOfMatch(payload, {
  //     onSuccess: (data) => {
  //       let session_exist = [];
  //       let all_sessions = [];
  //       let userExist = [];

  //       console.log(data.data);

  //       data.data.forEach((element) => {
  //         if (!session_exist.some((el) => el === element.session)) {
  //           if (accountType !== "admin") {
  //             if (element.view === true || element.view === 1) {
  //               all_sessions.push(element);
  //               session_exist.push(element.session);
  //             }
  //           } else {
  //             if (isMasterAdmin === "1") {
  //               all_sessions.push(element);
  //               session_exist.push(element.session);
  //             } else {
  //               if (element.view === true || element.view === 1) {
  //                 all_sessions.push(element);
  //                 session_exist.push(element.session);
  //               }
  //             }
  //           }
  //         }

  //         if (!userExist.some((user) => user.id === element.user_account.id)) {
  //           userExist.push({
  //             id: element.user_account.id,
  //             name: element.user_account.name,
  //             code: element.user_account.code,
  //           });
  //           console.log(userExist);
  //         }
  //       });

  //       setUserData(userExist);

  //       console.log(all_sessions);
  //       console.log(session_exist);
  //       setSessionData(all_sessions);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   });

  //   const betsPayload = {
  //     match_id: gameData.id,
  //     logged_acc_type: accountType,
  //     user_id: userId,
  //   };

  //   getBetsData(betsPayload, {
  //     onSuccess: (data) => {
  //       let matchUser = [];

  //       data.data.forEach((el) => {
  //         if (!matchUser.some((ele) => ele.id === el.user_accounts[0].id)) {
  //           matchUser.push({
  //             code: el.user_accounts[0].code,
  //             name: `${el.user_accounts[0].name}`,
  //             id: el.user_accounts[0].id,
  //           });
  //         }
  //       });

  //       setUserData(matchUser);
  //     },
  //     onError: (error) => {
  //       console.log(error);
  //     },
  //   });
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  return (
    <div className="content-wrapper">
      <section className="content-header">
        <div className="container-fluid">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1>Session List Plus Minus</h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/dashboard">Home</Link>
                </li>
                <li className="breadcrumb-item">
                  <Link to="/inplay">In Play</Link>
                </li>
                <li className="breadcrumb-item active">
                  <a>Session List Plus Minus</a>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </section>

      <section className="content">
        <div className="row">
          <div className="col-12">
            <div className="card">
              <div className="card-header">
                <div class="d-flex align-items-center justify-content-between">
                  <div class="mb-0 card-title">{gameData.name}</div>
                  <Link
                    className="btn btn-primary"
                    to={`/complete-games/all-company-report?id=${gameData.id}&team=${gameData.won_by}`}
                  >
                    Show
                  </Link>
                </div>
              </div>
              {/* /.card-header */}
              <div className="card-body">
                <div className="row align-items-start">
                  <div className="col-md-8">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" defaultChecked={true} />
                          </th>
                          <th>SESSION (POST DATE)</th>
                          <th>DECLARE</th>
                        </tr>
                      </thead>
                      <tbody>
                        {sessionData.length !== 0 ? (
                          sessionData
                            .filter(
                              (session, index, self) =>
                                // Check if this is the first occurrence of the session
                                index ===
                                self.findIndex(
                                  (s) => s.session === session.session
                                )
                            )
                            .map((session, index) => (
                              <tr key={index}>
                                <td className="p-3">
                                  <input
                                    type="checkbox"
                                    defaultChecked={true}
                                  />
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {session.session}
                                </td>
                                <td style={{ verticalAlign: "middle" }}>
                                  {session.declare}
                                </td>
                              </tr>
                            ))
                        ) : (
                          <tr>
                            <td colSpan="3" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                  <div className="col-md-4">
                    <table className="table table-striped table-bordered">
                      <thead>
                        <tr>
                          <th>
                            <input type="checkbox" defaultChecked={true} />
                          </th>
                          <th>Name (CODE)</th>
                        </tr>
                      </thead>
                      <tbody>
                        {userData.length > 0 ? (
                          userData.map((user, index) => (
                            <tr key={`user-${index}`}>
                              <td className="p-3">
                                <input type="checkbox" defaultChecked={true} />
                              </td>
                              <td style={{ verticalAlign: "middle" }}>
                                {user.name} ({user.code})
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="2" className="text-center">
                              No data available
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
