import { useMutation } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";

const BASE_URL = process.env.REACT_APP_LUCKY_API_URL;

async function mutationFn() {
  const axios = createProtectedAxios();

  const endpoint = `${BASE_URL}/odds?casinoGames=LUCKY7`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetLucky7() {
  const { mutate, isLoading, data } = useMutation(
    ["get-lucky-7"],
    () => mutationFn(),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
