import React from "react";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import SuperSidebar from "../Components/SiderbarAllowFeatures/SuperAgent";
import axios from "axios";
import { toast } from "react-toastify";

export default function EditMatchDescription() {
  let token = localStorage.getItem("token");
  let header = { "x-access-token": `${token}` };
  let options = { headers: header };
  var empty = require('is-empty');
  const location = useLocation();
  const { item } = location.state;
  const [data, setData] = React.useState([]);
  const [changeText, setchangeText] = useState("Add")
  const [fieldData, setFieldData] = React.useState({
    description: "",
    matchId: item
  })
  useEffect(() => {
    getDescriptionById();
  }, []);
  function getDescriptionById() {
    axios.post(`${process.env.REACT_APP_API_URL}owner/get-description-byId`, { matchId: item }, options).then(async (resp) => {

      setData(resp.data.data);
    });
  }
  const inputHnadle = (e) => {
    setFieldData({ ...fieldData, [e.target.name]: e.target.value, ["matchId"]: item })
  }
  const submitData = async (e) => {
    e.preventDefault();
    if (changeText === "Add") {
      if (empty(fieldData.description)) {
        return toast.warning("Plz provide Description")
      } else {
        //  console.log("fieldData", fieldData);
        await axios.post(
          `${process.env.REACT_APP_API_URL}owner/add-description`,
          fieldData,
          options
        ).then((response) => {
          if (response.data.success) {
            getDescriptionById()
            setFieldData({
              description: ""
            })
            toast.success(response.data.message)

          } else {
            toast.warning(response.data.message)
            console.log(response.data.message)
          }
        }
        ).catch((e) => {
          console.log("error", e.message);
        }
        );
      }
    } else {
      if (empty(fieldData.description)) {
        toast.warning("Plz Provide Description")
      } else {
        await axios.post(
          `${process.env.REACT_APP_API_URL}owner/edit-description`,
          fieldData,
          options
        ).then((response) => {
          if (response.data.success) {
            setchangeText("Add")
            setFieldData({
              description: ""
            })
            getDescriptionById()
            toast.success(response.data.message)
          } else {
            toast.warning(response.data.message)
            console.log(response.data.message)
          }
        }
        ).catch((e) => {
          console.log("error", e.message);
        }
        );
      }
    }
  }
  const DeleteDescription = async (id, matchId) => {
    await axios.post(
      `${process.env.REACT_APP_API_URL}owner/delete-description`,
      { objid: id, matchId: matchId },
      options
    ).then((response) => {
      if (response.data.success) {
        toast.success(response.data.message)
        getDescriptionById()
      } else {
        toast.warning(response.data.message)
        console.log(response.data.message)
      }
    }
    ).catch((e) => {
      console.log("error", e.message);
    }
    );
  }
  return (
    <>
      <SuperSidebar />
      <div className="content-wrapper">
        <div className="container-fluid content-header">
          <div className="row mb-2">
            <div className="col-sm-6">
              <h1 className="m-0 ml-2">
                <i class="fa fa-home"></i> Edit Match Description
              </h1>
            </div>
            <div className="col-sm-6">
              <ol className="breadcrumb float-sm-right">
                <li className="breadcrumb-item">
                  <Link to="/">Home</Link>
                </li>
                <li className="breadcrumb-item active"> All Description</li>
              </ol>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <form className="d-flex align-items-center my-2">
                      <div className="col-4">
                        <label for="message-text" class="col-form-label">Message:</label>
                        <textarea class="form-control" name="description"
                          id="message-text" rows="2" value={fieldData.description} onChange={inputHnadle} required></textarea>
                      </div>
                      <div className="col-8">
                        <button class="btn btn-success mt-5" onClick={(e) => {
                          setFieldData({ matchId: item })
                          submitData(e)
                        }} type="button">
                          {changeText}
                        </button>
                      </div>
                    </form>
                  </div>
                  <table className="table table-bordered table-striped">
                    <thead>
                      <tr>
                        <th>S.No </th>
                        <th>Match  Description </th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {data && data.length > 0 ? (
                        data.map((item, i) => {
                          return (
                            <tr>
                              <td>{i + 1}</td>
                              <td>
                                {item.description}
                              </td>
                              <td>
                                <button class="btn btn-success m-2" type="button" onClick={() => {
                                  setchangeText("Edit")
                                  setFieldData({ ...fieldData, ["description"]: item.description, matchId: item.matchId })
                                }}>
                                  edit
                                </button>
                                {/* <button class="btn btn-success m-2" type="button" disabled={true}>
                                Edit
                              </button>
                              */}
                                <button class="btn btn-danger" type="button" value={item._id} onClick={(e) => {
                                  DeleteDescription(e.target.value, item.matchId)
                                }}>
                                  Delete
                                </button>
                              </td>
                            </tr>
                          );
                        })) : (<tr className="text-center">
                          <td colSpan={4}>
                            No data found
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
