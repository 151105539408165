import { useMutation } from "react-query";
import { createProtectedAxios } from "../../../lib/auth";

const BASE_URL = "https://odds.betguru.net/api/v2";

async function mutationFn(scoreId) {
  const axios = createProtectedAxios();
  const endpoint = `${BASE_URL}/get/${scoreId}`;

  return axios.get(endpoint).then(({ data }) => data);
}

export default function useGetScoreBoard5() {
  const { mutate, isLoading, data } = useMutation(
    ["get-score-board-5"],
    (scoreId) => mutationFn(scoreId),
    {
      onError: (error) => {
        console.error(error);
      },
    }
  );

  return { mutate, isLoading, data };
}
